import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"

import './index.css'
import Login from './pages/login'
import Dashboard from './pages/dashboard'
import * as serviceWorker from './serviceWorker'
import reducers from './reducers'
import { getAuthToken } from './services/local-storage'
import { LocaleProvider } from './locales'
import PrivateRoute from './components/private-route'
import { Container } from './components/bootstrap'

// redux dev tools
let enhancer = process.env.REACT_APP_ENVIRONMENT === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
  : compose
const store = createStore(reducers, enhancer(applyMiddleware(thunk)))

ReactDOM.render(
  <React.StrictMode>
    <LocaleProvider>
      <Provider store={store}>
        <Router getUserConfirmation={(message, callback) => {
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}>
          <Switch>
            <Route path="/login" render={({ children, ...rest }) => {
              const authToken = getAuthToken()
              if (authToken) {
                return <Redirect to="/dashboard" />
              }
              return <Login />
            }} />
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute>
              <Redirect to="/login" />
            </PrivateRoute>
          </Switch>
        </Router>
        <Container>
          <small className="version-text">Version: {process.env.REACT_APP_VERSION}</small>
        </Container>
      </Provider>
    </LocaleProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
