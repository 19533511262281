import React, { useContext } from 'react'
import { LocaleContext } from '../../../../locales'
import { formatCurrency, formatDate } from '../../../../services/formatters'

const headings = [
    'client.type',
    'client.description',
    'client.maturityDate',
    'client.payoff',
    'client.limit',
    'client.balance'
]

const ClientLiabilitiesTab = ({
    client
}) => {
    const { getLocale } = useContext(LocaleContext)
    let limitSum = 0
    let balanceSum = 0
    return (
        <table className="table">
            <tbody>
                <tr>
                    {
                        headings.map((heading, index) => {
                            return (
                                <th key={heading} className={index > headings.length - 3 ? 'text-right' : null}>
                                    {getLocale(heading)}
                                </th>
                            )
                        })
                    }
                </tr>
                {
                    client.liabilities.map((liability) => {
                        balanceSum += liability.value
                        limitSum += liability.liabilityLimit
                        return (
                            <tr key={liability.description}>
                                <td>{liability.type}</td>
                                <td>{liability.description}</td>
                                <td>{formatDate(liability.maturityDate)}</td>
                                <td>{liability.payoff}</td>
                                <td className="text-right">{formatCurrency(liability.liabilityLimit)}</td>
                                <td className="text-right">{formatCurrency(liability.balance)}</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td colSpan="4">
                        <b>{getLocale('client.summary')}</b>
                    </td>
                    <td className="text-right">
                        <b>{formatCurrency(limitSum)}</b>
                    </td>
                    <td className="text-right">
                        <b>{formatCurrency(balanceSum)}</b>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ClientLiabilitiesTab