import { STATUSES } from "../constants"

export const isLoading = (asyncStatus) => {
    return STATUSES.LOADING === asyncStatus
}

export const isAsyncStatusSuccess = (asyncStatus) => {
    return asyncStatus === STATUSES.SUCCESS
}

export const isAsyncStatusError = (asyncStatus) => {
    return asyncStatus === STATUSES.ERROR
}

export const listWithBlank = (list) => ([
    { value: '', label: '' },
    ...list
])

export const formatOneDimentionalListToDropdownList = (list) => {
    return list.map(item => ({ label: item, value: item }))
}