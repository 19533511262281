import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import useLocale from '../../../hooks/use-locale'
import { searchClient, linkClient } from '../../../services/api'
import { formatPhoneNumber } from '../../../services/formatters'
import Table from '../../../components/table'
import { useClientDetailContext } from '.'

const ClientPartnerLink = () => {
    const { client, fetchClient } = useClientDetailContext()
    const { getLocale } = useLocale()
    const [searchResults, setSearchResults] = useState([])
    const [selectedPartnerId, setSelectedPartnerId] = useState(null)
    const searchTimeout = useRef(null)
    const { partner } = client

    const onSearchChange = (e) => {
        e.preventDefault()
        const { target } = e
        const { value } = target
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
            searchTimeout.current = null;
        }

        searchTimeout.current = setTimeout(async () => {
            if (!value) {
                setSearchResults([])
                return;
            }

            setSelectedPartnerId(null)
            const [firstName, lastName] = value.split(' ')
            const { data } = await searchClient({
                params: {
                    firstName,
                    lastName
                }
            })
            setSearchResults(data)
            clearTimeout(searchTimeout.current);
            searchTimeout.current = null;
        }, 300);
    }

    const onLinkClick = (partnerId = '') => async (e) => {
        e.preventDefault()
        try {
            await linkClient(client.id, partnerId)
            fetchClient()
        } catch (e) {
            console.log('Error linking client.')
        }
    }

    const resultHeadings = [{ label: 'Name' }, { label: 'Home Phone' }, { label: 'Email' }]
    const resultRows = searchResults.map((result) => {
        return {
            props: {
                onClick: (e) => {
                    e.preventDefault()
                    setSelectedPartnerId(result.id)
                },
                className: classnames(result.id === selectedPartnerId && 'info')
            },
            columns: [{
                children: `${result.firstName} ${result.lastName}`
            }, {
                children: formatPhoneNumber(result.homePhoneNumber)
            }, {
                children: result.primaryEmail
            }]
        }
    })

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h4>{getLocale('client.partnerLink')}</h4>
            </div>
            <div className="panel-body">
                {
                    partner && (<div>
                        <button type="button" className="btn btn-danger" onClick={onLinkClick()}>
                            <span>{getLocale('client.unlinkPartner')}</span>
                        </button>
                    </div>)
                }
                {
                    !partner && (<div>
                        <div className="form-group">
                            <label>{getLocale('client.partnerName')}</label>
                            <input
                                type="text"
                                placeholder="Search (e.g. John Doe)"
                                className="form-control"
                                data-ng-model="detail.partnerLinkSearch"
                                onChange={onSearchChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>{getLocale('general.searchResults')}</label>
                            <div style={{ minHeight: 50 }}>
                                {
                                    searchResults.length === 0 && (
                                        <div className="text-muted" >
                                            <span>{getLocale('general.noResultsFound')}</span>
                                        </div>
                                    )
                                }
                                {
                                    searchResults.length > 0 && (
                                        <Table
                                            isCondensed
                                            isHover
                                            headings={resultHeadings}
                                            rows={resultRows}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group text-right">
                            <button
                                className={classnames('btn', 'btn-primary', selectedPartnerId === null && 'disabled')}
                                onClick={onLinkClick(selectedPartnerId)}
                            >
                                {getLocale('client.link')}
                            </button>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default ClientPartnerLink