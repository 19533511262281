import React, { useEffect, useRef, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { useProfile, usePrevious } from '../../../../hooks'
import { ROLES_MAP, STATUSES } from '../../../../constants'
import { formatDate } from '../../../../services/formatters'
import Modal from '../../../../components/modal'
import Loading from '../../../../components/loading'
import { Box, Button } from '../../../../components/bootstrap'
import { getAttachmentDetails } from '../../../../services/api'
import { useClientDetailContext } from '..'
import { useClientNotesState } from './client-notes-context'
import { fetchClientNotes, removeNote, selectedDivisionIdSelector } from '../../../../modules/client-notes'
import { selectedClientIdSelector } from '../../../../modules/client-detail-page'

const Note = ({ note, onSelectNote, onRemoveNoteClick, id }) => {
  const { profile } = useProfile()
  const { client } = useClientDetailContext()
  const divisionId = useSelector(selectedDivisionIdSelector)

  return (
    <div className="note-item" id={id}>
      <div className="note-item-note">
        <div className="row">
          <div className="col-sm-11">
            <p
              dangerouslySetInnerHTML={{ __html: note.note }}
              className={onSelectNote && 'note'}
              onClick={onSelectNote}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            {note.attachments && (
              <ul className="list-unstyled">
                {note.attachments.map(({ id, name }) => {
                  return (
                    <li key={id}>
                      <Button
                        variant="link"
                        onClick={async () => {
                          const url = await getAttachmentDetails({
                            clientId: client.id,
                            divisionId,
                            noteId: note.id,
                            attachmentId: id,
                            name
                          })
                          window.open(url)
                        }}
                      >
                        <i className="glyphicon glyphicon-download" />
                        {name}
                      </Button>
                    </li>
                  )
                })}
              </ul>)}
          </div>
          {profile.roles.some(r => r.roleID === ROLES_MAP.ManagementAdmin) && (
            <div className="col-sm-1 text-right">
              <span className="note-remove" onClick={onRemoveNoteClick}>
                <i className="glyphicon glyphicon-remove"></i>
              </span>
            </div>)}
        </div>
      </div>
      <div className="meta text-muted">
        Posted On {formatDate(note.createdDate, 'MM-dd-yyyy - h:mm a')} - {note.name.first} {note.name.last}
      </div>
    </div>
  )
}


const ClientNotesDisplaySection = () => {
  const dispatch = useDispatch()
  const notes = useSelector(state => state.clientNotes.notes)
  const notesAsyncStatus = useSelector(state => state.clientNotes.asyncStatus)
  const selectedDivisionId = useSelector(selectedDivisionIdSelector)
  const scrollContainer = useRef(null)
  const [selectedNote, setSelectedNote] = useState(null)
  const { attachments } = useClientNotesState()
  const selectedClientId = useSelector(selectedClientIdSelector)

  const prevNotesLength = usePrevious(notes.length)
  const prevNotesStatus = usePrevious(notesAsyncStatus)

  const scrollToBottom = () => {
    if (!scrollContainer.current) {
      return
    }
    scrollContainer.current.scroll(0, scrollContainer.current.scrollHeight)
  }

  useEffect(() => {
    dispatch(fetchClientNotes({ clientId: selectedClientId }))
  }, [selectedClientId, selectedDivisionId, dispatch])

  useEffect(scrollToBottom, [selectedDivisionId])

  useEffect(() => {
    if (notes.length > prevNotesLength) {
      scrollToBottom()
    }
  }, [notes.length, prevNotesLength])

  useEffect(() => {
    if (prevNotesStatus === STATUSES.LOADING && notesAsyncStatus === STATUSES.SUCCESS) {
      scrollToBottom()
    }
  }, [notesAsyncStatus, prevNotesStatus])

  const onRemoveNoteClick = async (noteId) => {
    if (!window.confirm('Are you sure you want to remove this note?')) {
      return
    }
    dispatch(removeNote(noteId))
  }

  return (
    <Fragment>
      <div
        className={classnames('note-list', attachments.length > 0 && 'sm')}
        ref={scrollContainer}
      >
        {notesAsyncStatus === STATUSES.LOADING && <Loading isLoading />}
        {notesAsyncStatus === STATUSES.SUCCESS && notes.length === 0 && (
          <Box verticalPadding="md">
            <div className="d-table text-muted">
              <div className="d-table-cell text-center v-align-mid">
                <i>No Notes</i>
              </div>
            </div>
          </Box>
        )}
        {notesAsyncStatus === STATUSES.SUCCESS && notes.length > 0 && notes.map((note, index) => {
          return <Note
            key={note.createdDate}
            note={note}
            onRemoveNoteClick={() => onRemoveNoteClick(note.id)}
            onSelectNote={() => setSelectedNote(index)}
          />
        })}
      </div>
      <Modal
        isOpen={selectedNote !== null}
        onRequestClose={() => setSelectedNote(null)}
        shouldCloseOnOverlayClick={true}
        title="Notes"
        onCancel={() => setSelectedNote(null)}
        onAfterOpen={() => {
          document.getElementById(`modal-note-${selectedNote}`).scrollIntoView()
        }}
      >
        {notes.map((note, index) => {
          return <Note
            id={`modal-note-${index}`}
            key={note.createdDate}
            note={note}
            onRemoveNoteClick={() => onRemoveNoteClick(note.id)}
          />
        })}
      </Modal>
    </Fragment>
  )
}

ClientNotesDisplaySection.propTypes = {
  lastAction: PropTypes.oneOf(['ADDED', 'REPLACED', 'REMOVED'])
}


export default ClientNotesDisplaySection