import { getNotesByClientAndDivision, saveClientNoteAndAttachments, deleteNote } from '../services/api'
import { STATUSES } from '../constants'

const FETCH_CLIENT_NOTES_START = 'FETCH_CLIENT_NOTES_START'
const FETCH_CLIENT_NOTES_SUCCESS = 'FETCH_CLIENT_NOTES_SUCCESS'
const FETCH_CLIENT_NOTES_ERROR = 'FETCH_CLIENT_NOTES_ERROR'
const DELETE_NOTE_START = 'DELETE_NOTE_START'
const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR'
const SAVE_NOTE_START = 'SAVE_NOTE_START'
const SAVE_NOTE_SUCCESS = 'SAVE_NOTE_SUCCESS'
const SAVE_NOTE_ERROR = 'SAVE_NOTE_ERROR'
const SET_NOTES_SELECTED_DIVISION = 'SET_NOTES_SELECTED_DIVISION'

export const fetchClientNotes = ({ clientId }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_CLIENT_NOTES_START
    })

    const state = getState()
    const divisionId = selectedDivisionIdSelector(state)

    if (divisionId === null || divisionId === undefined) {
        dispatch({
            type: FETCH_CLIENT_NOTES_SUCCESS,
            data: []
        })
        return
    }

    try {
        dispatch({
            type: FETCH_CLIENT_NOTES_SUCCESS,
            data: await getNotesByClientAndDivision(clientId, divisionId)
        })
    } catch (error) {
        dispatch({
            type: FETCH_CLIENT_NOTES_ERROR
        })
    }
}

export const setSelectedDivision = (clientDivision) => {
    return {
        type: SET_NOTES_SELECTED_DIVISION,
        data: {
            selectedDivision: clientDivision || null
        }
    }
}

export const setSelectedDivisionId = (clientDivisionId) => {
    return {
        type: SET_NOTES_SELECTED_DIVISION,
        data: {
            selectedDivision: {
                divisionID: clientDivisionId
            }
        }
    }
}

export const removeNote = (noteId) => async (dispatch, getState) => {
    dispatch({ type: DELETE_NOTE_START })
    try {
        await deleteNote(noteId)
        const state = getState()
        dispatch({ type: DELETE_NOTE_SUCCESS, data: { notes: state.clientNotes.notes.filter(n => n.id !== noteId) } })
    } catch (error) {
        dispatch({ type: DELETE_NOTE_ERROR })
    }
}

export const saveNote = ({
    clientId,
    note,
    attachments,
    divisionId
}) => async (dispatch) => {
    dispatch({ type: SAVE_NOTE_START })
    try {
        const newNote = await saveClientNoteAndAttachments({ clientId, note, attachments, divisionId })
        dispatch({
            type: SAVE_NOTE_SUCCESS,
            data: {
                note: newNote
            }
        })
    } catch (e) {
        dispatch({ type: SAVE_NOTE_ERROR })
    }
}

export const selectedDivisionSelector = state => state.clientNotes.selectedDivision
export const selectedDivisionIdSelector = state => state.clientNotes.selectedDivision?.divisionID

const initialState = {
    asyncStatus: STATUSES.NONE,
    deleteNoteAsyncStatus: STATUSES.NONE,
    saveNoteAsyncStatus: STATUSES.NONE,
    notes: [],
    selectedDivision: null
}

const clientNotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENT_NOTES_START: {
            return {
                ...state,
                asyncStatus: STATUSES.LOADING
            }
        }
        case FETCH_CLIENT_NOTES_SUCCESS: {
            return {
                ...state,
                notes: [...action.data],
                asyncStatus: STATUSES.SUCCESS,
            }
        }
        case FETCH_CLIENT_NOTES_ERROR: {
            return {
                ...state,
                asyncStatus: STATUSES.ERROR,
            }
        }
        case SAVE_NOTE_START: {
            return {
                ...state,
                saveNoteAsyncStatus: STATUSES.LOADING
            }
        }
        case SAVE_NOTE_SUCCESS: {
            return {
                ...state,
                notes: [...state.notes, action.data.note],
                saveNoteAsyncStatus: STATUSES.SUCCESS,
            }
        }
        case SAVE_NOTE_ERROR: {
            return {
                ...state,
                saveNoteAsyncStatus: STATUSES.ERROR,
            }
        }
        case DELETE_NOTE_START: {
            return {
                ...state,
                deleteNoteAsyncStatus: STATUSES.LOADING
            }
        }
        case DELETE_NOTE_SUCCESS: {
            return {
                ...state,
                notes: [...action.data.notes],
                deleteNoteAsyncStatus: STATUSES.SUCCESS,
            }
        }
        case DELETE_NOTE_ERROR: {
            return {
                ...state,
                deleteNoteAsyncStatus: STATUSES.ERROR,
            }
        }
        case SET_NOTES_SELECTED_DIVISION: {
            return {
                ...state,
                selectedDivision: action.data.selectedDivision
            }
        }
        default: {
            return state
        }
    }
}

// selectors
export const selectClientNotes = ({ clientNotes }) => clientNotes.notes

export default clientNotesReducer
