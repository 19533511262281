import React from 'react'

import { DATABASE_TYPES, MARKETING_CONSENT_LIST } from '../../../../constants'
import TwoColumnTabPanel from './two-column-tab-panel'
import { formatDate } from '../../../../services/formatters'

const GeneralTab = ({ client }) => {
    const marketingConsent = MARKETING_CONSENT_LIST.find(m => m.value === client.marketinConsent)?.label || ''
    const generalItems = [{
        shouldDisplay: client.type === DATABASE_TYPES.B2C,
        label: 'client.industryProfession',
        value: client.industryProfession
    }, {
        shouldDisplay: true,
        label: 'client.entryDate',
        value: formatDate(client.createdDate)
    }, {
        shouldDisplay: true,
        label: 'client.marketingConsent',
        value: marketingConsent
    }, {
        shouldDisplay: true,
        label: 'client.referredByMarketingOutlet',
        value: client.referredByMarketingOutlet
    }, {
        shouldDisplay: true,
        label: 'client.personReferredBy',
        value: client.referredByPerson
    }, {
        shouldDisplay: true,
        label: 'client.comment',
        value: client.comment
    }]

    return (
        <TwoColumnTabPanel items={generalItems} />
    )
}

export default GeneralTab
