import React, { Fragment, useEffect, useRef, useContext } from 'react'
import { useHistory, Link, Switch, Route, useLocation } from 'react-router-dom'

import Loading from '../../components/loading'

import { login, initializePasswordReset, completeActivation, validateHash } from '../../services/api'
import { setAuthToken, getRememberMe, setRememberMe, removeRememberMe } from '../../services/local-storage'

import logo from '../../images/logo.png'
import './login.css'
import { LocaleContext } from '../../locales'
import { useAsync } from '../../hooks'
import useLocale from '../../hooks/use-locale'
import FormRenderer from '../../components/form-renderer'
import { useState } from 'react'
import { SubmitButton, Button, Alert, Box } from '../../components/bootstrap'
import { useMemo } from 'react'

const LOGIN_FORM_FIELD = [{
    type: 'text',
    key: 'email',
    label: 'Email'
}, {
    type: 'password',
    key: 'password',
    label: 'Password'
}]

const Copyright = () => {
    const { getLocale } = useLocale()
    return (
        <p className="margin-top-lg text-center text-muted">
            <small>{getLocale('general.copyright')}</small>
        </p>
    )
}

const LoginTemplate = ({ title, children }) => {
    return (
        <div className="panel panel-default margin-top-lg">
            <div className="panel-body">
                <div className="text-center">
                    <h2>{title}</h2>
                </div>
                {children}
                <Copyright />
            </div>
        </div>
    )
}

const LoginCTASection = ({
    hasError,
    isLoading,
    canSubmit,
    errorMessage,
    ctaMessage
}) => {
    const { getLocale } = useLocale()
    return (
        <Fragment>
            {hasError && <Alert variant="danger">{errorMessage}</Alert>}
            <Box alignment="center">
                <div>
                    {isLoading && <Loading isLoading isInline />}
                    {!isLoading && <SubmitButton disabled={!canSubmit} value={ctaMessage} />}
                </div>
                <Button variant="link">
                    <Link to="/login">{getLocale('ReturnToLogin')}</Link>
                </Button>
            </Box>
        </Fragment>
    )
}

const LoginForm = () => {
    const rememberMeRef = useRef()
    const { getLocale } = useContext(LocaleContext)
    const history = useHistory()
    const rememberedEmail = getRememberMe()
    const [values, setValues] = useState(
        LOGIN_FORM_FIELD.reduce((acc, field) => {
            if (field.key === 'email' && rememberedEmail) {
                acc.email = rememberedEmail
                return acc
            }
            acc[field.key] = ''
            return acc
        }, {})
    )
    const {
        execute: executeLogin,
        isLoading,
        isError,
        isSucess,
        data
    } = useAsync(login)

    useEffect(() => {
        if (rememberedEmail) {
            rememberMeRef.current.checked = true
        }
    }, [rememberedEmail])

    useEffect(() => {
        if (!isSucess) {
            return
        }
        setAuthToken(data.token)
        history.push('/dashboard')
    }, [data, history, isSucess])

    const onSubmit = async (e) => {
        e.preventDefault()
        const { target } = e
        const { email, password, rememberMe } = target

        if (rememberMe.checked === true) {
            setRememberMe(email.value)
        } else {
            removeRememberMe()
        }

        executeLogin(email.value, password.value)
    }

    return (
        <LoginTemplate title={getLocale('general.appTitle')}>
            <form className="row-gutter" onSubmit={onSubmit}>
                <FormRenderer
                    fields={LOGIN_FORM_FIELD}
                    values={values}
                    onFieldChange={({ field, value }) => {
                        setValues({
                            ...values,
                            [field.key]: value
                        })
                    }}
                />
                <small>
                    <Link to="/login/forgot-password">{getLocale('ForgotPassword')}</Link>
                </small>
                {
                    isError && (
                        <div>
                            <ul className="list-unstyled text-center alert alert-warning">
                                <li>{getLocale('login.invalidLoginWarning')}</li>
                            </ul>
                        </div>
                    )
                }
                <div className="margin-top-lg alert alert-danger">
                    <span className="glyphicon glyphicon-warning-sign"></span>
                    <small>{getLocale('login.sharingLoginsWarning')}</small>
                </div>

                <div className="text-center">
                    <div className="checkbox">
                        <label>
                            <input
                                ref={rememberMeRef}
                                name="rememberMe"
                                type="checkbox"
                            /> {getLocale('login.rememberMeLabel')}
                        </label>
                    </div>
                    {
                        isLoading && <Loading isLoading />
                    }
                    {!isLoading && (
                        <input className="btn btn-primary" type="submit" value={getLocale('login.loginLabel')} />
                    )}
                </div>
            </form>
        </LoginTemplate>
    )
}

const FORGOT_PASSWORD_FORM_FIELDS = [{
    type: 'text',
    label: 'Email',
    key: 'email'
}]

const ForgotPassword = () => {
    const { getLocale } = useLocale()
    const [values, setValues] = useState({
        email: ''
    })

    const {
        execute: executePasswordReset,
        isLoading: passwordResetLoading,
        isSucess: passwordResetSuccess,
        isError: passwordResetError
    } = useAsync(initializePasswordReset)

    const onSubmit = (e) => {
        e.preventDefault()
        executePasswordReset(values.email)
    }

    return (
        <LoginTemplate title={getLocale('ForgotPassword')}>
            {
                passwordResetSuccess === true && <p>
                    {getLocale('PasswordResetSuccess')}
                    <Button variant="link">
                        <Link to="/login">{getLocale('ReturnToLogin')}</Link>
                    </Button>
                </p>
            }
            {
                passwordResetSuccess !== true && (
                    <Fragment>
                        <p>{getLocale('forgotEmailMessage')}</p>
                        <form className="row-gutter" onSubmit={onSubmit}>
                            <FormRenderer
                                fields={FORGOT_PASSWORD_FORM_FIELDS}
                                values={values}
                                onFieldChange={({ field, value }) => {
                                    setValues({
                                        ...values,
                                        [field.key]: value
                                    })
                                }}
                            />
                            <LoginCTASection
                                hasError={passwordResetError}
                                errorMessage={getLocale('resetPasswordErrorMessage')}
                                isLoading={passwordResetLoading}
                                canSubmit={values.email !== ''}
                                ctaMessage={getLocale('ResetPassword')}
                            />
                        </form>
                    </Fragment>
                )
            }
        </LoginTemplate>
    )
}

const InvalidTokenError = () => {
    const { getLocale } = useLocale()
    return (
        <LoginTemplate title={getLocale('ResetPassword')}>
            <Box alignment="center">
                <p>
                    {getLocale('ResetPasswordInvalidToken')}
                </p>
                <Link to="/login">
                    {getLocale('ReturnToLogin')}
                </Link>
            </Box>
        </LoginTemplate>
    )
}


const RESET_PASSWORD_FORM_FIELDS = [{
    type: 'password',
    label: 'NewPassword',
    key: 'newPassword'
}]

const ResetPassword = () => {
    const location = useLocation()
    const { getLocale } = useLocale()
    const [values, setValues] = useState({ newPassword: '' })
    const hash = useMemo(() => {
        const qs = new URLSearchParams(location.search)
        return qs.get('hash')
    }, [location.search])

    const {
        execute: executeHashValidate,
        isLoading: hashValidateLoading,
        isError: hashValidateError
    } = useAsync(validateHash)

    const {
        execute: executeCompleteActivation,
        isLoading: passwordResetLoading,
        isSucess: passwordResetSuccess,
        isError: passwordResetError
    } = useAsync(completeActivation)

    useEffect(() => {
        if (!hash) {
            return
        }
        executeHashValidate(hash)
    }, [executeHashValidate, hash])

    const onSubmit = (e) => {
        e.preventDefault()
        executeCompleteActivation(hash, values.newPassword)
    }

    if (hashValidateLoading === true) {
        return <Loading isLoading />
    }

    if (!hash || hashValidateError === true) {
        return <InvalidTokenError />
    }

    return (
        <LoginTemplate title={getLocale('ResetPassword')}>
            {
                passwordResetSuccess === true && <p>
                    {getLocale('PasswordResetSuccess')}
                    <Button variant="link">
                        <Link to="/login">{getLocale('ReturnToLogin')}</Link>
                    </Button>
                </p>
            }
            {passwordResetSuccess === false && (
                <Fragment>
                    <p>{getLocale('resetPasswordMessage')}</p>
                    <form className="row-gutter" onSubmit={onSubmit}>
                        <FormRenderer
                            fields={RESET_PASSWORD_FORM_FIELDS}
                            values={values}
                            onFieldChange={({ field, value }) => {
                                setValues({
                                    ...values,
                                    [field.key]: value
                                })
                            }}
                        />
                        <LoginCTASection
                            hasError={passwordResetError}
                            errorMessage={getLocale('resetPasswordErrorMessage')}
                            isLoading={passwordResetLoading}
                            canSubmit={values.newPassword !== ''}
                            ctaMessage={getLocale('ResetPassword')}
                        />
                    </form>
                </Fragment>
            )}
        </LoginTemplate>
    )
}

const Activate = () => {
    const { getLocale } = useLocale()
    const location = useLocation()
    const {
        execute: executeCompleteActivation,
        isLoading: activationLoading,
        isSucess: activationSuccess,
        isError: activationError
    } = useAsync(completeActivation)

    const hash = useMemo(() => {
        const qs = new URLSearchParams(location.search)
        return qs.get('hash')
    }, [location.search])

    useEffect(() => {
        // do not call api if ther eis no hash
        if (!hash) {
            return
        }
        executeCompleteActivation(hash)
    }, [executeCompleteActivation, hash])

    let title = getLocale('AccountActivation')

    if (activationSuccess) {
        title = getLocale('AccountActivationSuccess')
    }
    if (activationError) {
        title = getLocale('AccountActivationError')
    }

    if (!hash) {
        return <InvalidTokenError />
    }
    return (
        <LoginTemplate title={title}>
            <Box alignment="center">
                {activationLoading && <Loading isLoading />}
                {activationSuccess && (
                    <Fragment>
                        <p>{getLocale('activationSuccessMessage')}</p>
                        <Link to="/login">
                            {getLocale('ReturnToLogin')}
                        </Link>
                    </Fragment>
                )}
                {activationError && (
                    <Fragment>
                        <p>{getLocale('activationErrorMessage')}</p>
                        <Link to="/login">
                            {getLocale('ReturnToLogin')}
                        </Link>
                    </Fragment>
                )}
            </Box>
        </LoginTemplate>
    )
}

const Login = () => {
    return (
        <Fragment>
            <div className="login-bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-8 col-md-6 col-xs-offset-2 col-md-offset-3 login-form">
                        <div className="authentication-logo">
                            <img src={logo} alt="logo" className="login-image" />
                        </div>
                        <Switch>
                            <Route path="/login/activate">
                                <Activate />
                            </Route>
                            <Route path="/login/forgot-password">
                                <ForgotPassword />
                            </Route>
                            <Route path="/login/reset-password">
                                <ResetPassword />
                            </Route>
                            <Route>
                                <LoginForm />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Login