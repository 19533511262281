import React from 'react'

const chunk = (array, len) => {
    const returnArray = []
    const arrayLen = array.length
    for (let i = 0; i < arrayLen; i += len) {        
        returnArray.push(array.slice(i, i + len))
    }
    return returnArray
}

const LabelPrintQueue = ({
    clients,
    togglePrintQueue,
    clearPrintQueue
}) => {

    // function to print label
    const printClientLabels = async () => {
        var printWindow = window.open()

        const response = await fetch('/print.css')
        const styles = await response.text()

        const doc = printWindow.document
        const styleElement = doc.createElement('style')
        styleElement.innerHTML = styles
        doc.head.appendChild(styleElement)

        const chunkedClients = chunk(clients, 2)
        const chunkedPage = chunk(chunkedClients, 7)

        chunkedPage.forEach(function (page, index) {
            var pageEl = doc.createElement('div')
            pageEl.classList.add('page')
            if (index > 0) {
                pageEl.classList.add('after-first-page')
            }

            page.forEach(function (row, rowIndex) {
                var rowEl = doc.createElement('div')
                var rowIndexPlusOne = rowIndex + 1
                rowEl.classList.add('row')
                rowEl.classList.add('row-' + rowIndexPlusOne)

                row.forEach(function (client) {
                    var labelEl = doc.createElement('div')
                    labelEl.classList.add('label')

                    var content = client.firstName + ' ' + client.lastName + '<br>'
                    if (client.companyName) {
                        content += client.companyName
                        content += '<br>'
                    }

                    if (client.unitNumber) {
                        content += client.unitNumber
                        content += '-'
                    }

                    content += client.streetNumber
                    content += ' '
                    content += client.streetName
                    content += '<br>'
                    content += client.city
                    content += ', '
                    content += client.province
                    content += ', '
                    content += client.postalCode
                    content += '<br>'
                    content += client.country

                    labelEl.innerHTML = content
                    rowEl.appendChild(labelEl)
                })

                pageEl.appendChild(rowEl)
            })

            doc.body.appendChild(pageEl)
        })
        printWindow.print()
    }

    return (
        <div className="panel panel-default" data-test-id="label-queue">
            <div className="panel-heading">
                <strong>Label Queue </strong>
            </div>
            <div className="panel-body">
                {
                    clients.map((client) => {
                        return (
                            <span
                                key={client.id}
                                onClick={() => togglePrintQueue(client)}
                                style={{ marginRight: 5, display: 'inline-block' }}
                                className="label label-default pointer"
                            >
                                {client.firstName} {client.lastName}
                            </span>
                        )
                    })
                }
                <div className="text-right" style={{ marginTop: 15 }}>
                    <button
                        type="button"
                        onClick={() => clearPrintQueue()}
                        className="btn btn-sm btn-default btn-xs"
                    >
                        <span>
                            Clear Queue
                        </span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary btn-xs"
                        onClick={printClientLabels}
                    >
                        <span>
                            Print Labels
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LabelPrintQueue