import React, { useContext, useRef, useState, Fragment, useEffect } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'

import FormRenderer from '../../components/form-renderer'
import { useMount, useAsync } from '../../hooks'
import { LocaleContext } from '../../locales'
import { DATABASE_TYPES_NAME_MAP } from '../../constants'
import LabelPrintQueue from './label-print-queue'
import { formatPhoneNumber } from '../../services/formatters'
import Table from '../../components/table'
import Pagination from '../../components/pagination'
import { searchClient } from '../../services/api'


const SEARCH_FORM_CONFIG = [{
    key: 'firstName',
    label: 'FirstName',
    type: 'text',
}, {
    key: 'lastName',
    label: 'LastName',
    type: 'text',
}, {
    key: 'alsoKnownAs',
    label: 'AlsoKnownAs',
    type: 'text',
}, {
    key: 'phoneNumber',
    label: 'PhoneNumber',
    type: 'text',
}, {
    key: 'industryProfession',
    label: 'IndustryProfession',
    type: 'text',
}, {
    key: 'companyName',
    label: 'CompanyName',
    type: 'text',
}, {
    key: 'email',
    label: 'Email',
    type: 'text',
}]

const initialValues = SEARCH_FORM_CONFIG.reduce((acc, item) => {
    acc[item.key] = ''
    return acc
}, {})

const SearchPanel = () => {
    const { getLocale } = useContext(LocaleContext)
    const formRef = useRef()
    const history = useHistory()
    const { search } = useLocation()
    const [values, setValues] = useState(initialValues)

    useMount(() => {
        // update form fields if there are search queries on page load
        const params = new URLSearchParams(search)
        const updatedInitialValue = SEARCH_FORM_CONFIG.reduce((acc, item) => {
            acc[item.key] = params.has(item.key) ? params.get(item.key) : ''
            return acc
        }, {})
        setValues(updatedInitialValue)
    })

    const onSearch = (e) => {
        e.preventDefault()
        const qs = new URLSearchParams()
        Object.entries(values).forEach(([key, value]) => {
            if (value !== '') {
                qs.set(key, value)
            }
        })
        history.push({ search: qs.toString() })
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <strong>{getLocale('clientSearchPanel.search')}</strong>
            </div>
            <div className="panel-body">
                <form
                    ref={formRef}
                    onSubmit={onSearch}>
                    <FormRenderer
                        fields={SEARCH_FORM_CONFIG}
                        values={values}
                        onFieldChange={({ field, value }) => {
                            setValues({
                                ...values,
                                [field.key]: value
                            })
                        }}
                    />
                    <div className="form-group text-right">
                        <button
                            data-test-id="client-search-clear"
                            type="button"
                            onClick={() => {
                                setValues(initialValues)
                            }}
                            className="btn btn-default"
                        >
                            {getLocale('clientSearchPanel.clear')}
                        </button>
                        <input
                        data-test-id="client-search"
                            type="submit"
                            className="btn btn-primary"
                            value={getLocale('clientSearchPanel.search')}
                        />
                    </div>
                </form>
            </div>
        </div >
    )
}

const SearchResults = () => {
    const { getLocale } = useContext(LocaleContext)
    const { search } = useLocation()
    const history = useHistory()
    const [labelPrintQueue, setLabelPrintQueue] = useState({})
    const printQueueClients = Object.values(labelPrintQueue)

    const {
        execute: executeClientSearch,
        data: clientData,
        isSucess: clientSearchIsSuccess
    } = useAsync(searchClient)

    useEffect(() => {
        if (!search) {
            return
        }

        // fetch search results
        const query = new URLSearchParams(search)

        // initialize search object
        const params = SEARCH_FORM_CONFIG.reduce((acc, field) => {
            return ({ ...acc, [field.key]: query.has(field.key) ? query.get(field.key) : null })
        }, {})

        executeClientSearch({
            params,
            page: parseInt(query.get('page'))
        })
    }, [executeClientSearch, search])

    useEffect(() => {
        if (!clientData || !search) {
            return
        }

        if (
            clientSearchIsSuccess &&
            clientData.length === 0 &&
            window.confirm(getLocale('clientSearchResultPanel.createNewUserWarning'))
        ) {
            history.push('/dashboard/client/create')
        }
    }, [clientData, getLocale, history, clientSearchIsSuccess, search])

    // function to add clients to the label print queue.  This function has to exist here because 
    // it relies on the table to add and remove people.
    const togglePrintQueue = (client) => {
        if (labelPrintQueue[client.id]) {
            delete labelPrintQueue[client.id]
            setLabelPrintQueue({ ...labelPrintQueue })
        } else {
            setLabelPrintQueue({
                ...labelPrintQueue,
                [client.id]: client
            })
        }
    }

    const clearPrintQueue = () => {
        setLabelPrintQueue({})
    }

    const tableHeadings = [
        { label: getLocale('clientSearchResultPanel.name') },
        { label: getLocale('clientSearchResultPanel.homePhone') },
        { label: getLocale('clientSearchResultPanel.businessPhone') },
        { label: getLocale('clientSearchResultPanel.cellNumber') },
        { label: getLocale('clientSearchPanel.email') },
        { label: getLocale('clientSearchResultPanel.databaseType') },
        { label: '' }
    ]

    const formatRows = () => {
        return clientData.data.map((client) => ({
            columns: [{
                children: <Link to={`/dashboard/client/${client.id}/detail`}>{client.firstName}{' '}{client.lastName}</Link>
            }, {
                children: formatPhoneNumber(client.homePhoneNumber)
            }, {
                children: formatPhoneNumber(client.businessPhoneNumber)
            }, {
                children: formatPhoneNumber(client.cellPhoneNumber)
            }, {
                children: client.primaryEmail || client.secondaryEmail
            }, {
                children: DATABASE_TYPES_NAME_MAP[client.type]
            },
            {
                children: !labelPrintQueue[client.id] && (
                    <button type="button" className="btn btn-link" onClick={() => togglePrintQueue(client)}>
                        <i className="glyphicon glyphicon-print"></i>
                    </button>
                )
            }
            ]
        }))
    }

    return (
        <Fragment>
            {
                printQueueClients.length > 0 && (
                    <LabelPrintQueue
                        clients={printQueueClients}
                        togglePrintQueue={togglePrintQueue}
                        clearPrintQueue={clearPrintQueue}
                    />
                )
            }

            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-sm-6">
                            <strong>{getLocale('clientSearchResultPanel.searchResults')}</strong>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Link
                                data-test-id="client-search-add-new"
                                to="/dashboard/client/create"
                                className="btn btn-default btn-xs"
                            >
                                {getLocale('clientSearchResultPanel.addNewContact')}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    {
                        (!clientData || clientData?.data?.length === 0) && (
                            <div data-ng-show="result.results.length === 0">
                                <span>{getLocale('clientSearchResultPanel.noResults')}</span>
                            </div>
                        )
                    }
                    {
                        clientData?.data?.length > 0 && <Fragment>
                            <Table headings={tableHeadings} rows={formatRows()} name="searchUsers" />
                            <hr />
                            <Pagination totalPages={clientData.totalPages} currentPage={clientData.page} />
                        </Fragment>
                    }
                </div>
            </div>
        </Fragment >
    )
}


const ClientSearch = () => {
    const { getLocale } = useContext(LocaleContext)
    return (
        <div>
            <div className="page-header">
                <h1>{getLocale('nav.searchDatabase')}</h1>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-3">
                    <SearchPanel />
                </div>
                <div className="col-sm-12 col-md-9">
                    <SearchResults />
                </div>
            </div>
        </div>
    )
}

export default ClientSearch