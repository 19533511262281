/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedClientId } from '../../../../modules/client-detail-page'
import { formatClientName } from '../../../../services/formatters'

const PartnerToggle = ({
    client
}) => {
    const selectedClientId = useSelector(state => state.clientDetailPage.selectedClientId)
    const dispatch = useDispatch()
    return (
        <ul className="nav nav-tabs" >
            <li className={selectedClientId === client.id ? 'active' : null}>
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                    dispatch(setSelectedClientId(client.id))
                }}>
                    {formatClientName(client)}
                </a>
            </li>
            {
                client.partner && (
                    <li className={selectedClientId === client.partner.id ? 'active' : null}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault()
                            dispatch(setSelectedClientId(client.partner.id))
                        }}>
                            {formatClientName(client.partner)}
                        </a>
                    </li>
                )
            }
        </ul>
    )
}

export default PartnerToggle