import React from 'react'

import { Prompt } from "react-router-dom"
import { useTimerContext } from '../pages/dashboard'

// only show prompt if the timer has expired
export const TimerPrompt = ({
    message,
    when
}) => {
    const { hasExpired } = useTimerContext()
    let localWhen = hasExpired === false
    if (when !== undefined) {
        localWhen = localWhen && when
    }
    return (
        <Prompt when={localWhen} message={message} />
    )
}