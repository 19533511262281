import React from 'react'
import { Switch, useRouteMatch, Redirect } from 'react-router-dom'
import PrivateRoute from '../../components/private-route'
import Search from './search'
import EditClient from './edit'
import ClientDetail from './detail'

import './client.css'

const Client = () => {
    const { url } = useRouteMatch()
    return (
        <Switch>
            <PrivateRoute path={`${url}/:id/detail`}>
                <ClientDetail />
            </PrivateRoute>
            <PrivateRoute path={`${url}/:id/edit`}>
                <EditClient />
            </PrivateRoute>
            <PrivateRoute path={`${url}/create`}>
                <EditClient />
            </PrivateRoute>
            <PrivateRoute path={`${url}/search`}>
                <Search />
            </PrivateRoute>
            <PrivateRoute>
                <Redirect to={`${url}/search`} />
            </PrivateRoute>
        </Switch>
    )
}

export default Client