import React, { Fragment, createContext, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ClientDetailHeader from './header'
import Loading from '../../../components/loading'
import ClientOverviewSection from './overview-section'
import ClientPartnerLink from './partner-link'
import ClientNotesSection from './notes-section'
import { useClient } from '../../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectedClientIdSelector, setSelectedClientId } from '../../../modules/client-detail-page'

export const ClientDetailContext = createContext({
    client: null,
    fetchClient: () => { }
})

export const ClientDetailProvider = ({ children }) => {
    const { id } = useParams()
    const { client, fetchClient, asyncStatus } = useClient(id)
    const dispatch = useDispatch()

    const value = {
        client,
        fetchClient,
        asyncStatus
    }

    useEffect(() => {
        if (client !== null) {
            dispatch(setSelectedClientId(client.id))
        }
    }, [client, dispatch])

    return <ClientDetailContext.Provider value={value}>
        {children}
    </ClientDetailContext.Provider>
}

export const useClientDetailContext = () => {
    const context = useContext(ClientDetailContext)
    return context
}

const ClientDetail = () => {
    const dispatch = useDispatch()
    const { client } = useClientDetailContext()
    const selectedClientId = useSelector(selectedClientIdSelector)

    useEffect(() => {
        return () => {
            dispatch(setSelectedClientId(null))
        }
    }, [dispatch])

    if (!client || !selectedClientId) {
        return <div style={{ height: '100vh' }}>
            <Loading isLoading />
        </div>
    }

    return (
        <Fragment>
            <ClientDetailHeader />
            <ClientOverviewSection />
            <ClientPartnerLink />
            <hr />
            <ClientNotesSection />
        </Fragment>
    )
}

const ClientDetailWrapper = () => {
    return (
        <ClientDetailProvider>
            <ClientDetail />
        </ClientDetailProvider>
    )
}

export default ClientDetailWrapper