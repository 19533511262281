import React, { memo } from 'react'
import useLocale from '../hooks/use-locale'
import classnames from 'classnames'
import { Col } from './bootstrap'
import DatePicker from './date-picker'

const parseValue = (field, value) => {
    const { parse } = field
    return typeof (parse) === 'function' ? parse(value) : value
}


const FormRenderer = memo(({
    fields,
    values,
    onFieldChange,
    type,
    customRenderers
}) => {
    const { getLocale } = useLocale()
    const isHorizontal = type === 'horizontal'

    const handleFieldChange = (field) => (e) => {
        onFieldChange({
            field,
            value: parseValue(field, e.target.value)
        })
    }

    const handleCheckboxChange = (field) => (e) => {
        const value = e.target.value
        const parsedValue = field.valueType === 'number' ? parseInt(value) : value
        const checkboxValue = values[field.key]
        if (checkboxValue.has(parsedValue)) {
            checkboxValue.delete(parsedValue)
        } else {
            checkboxValue.add(parsedValue)
        }
        onFieldChange({ field, value: checkboxValue })
    }

    const getField = (field) => {
        const {
            label,
            key,
            type,
            options,
            disabled,
            placeholder
        } = field

        const optionValues = typeof (options) === 'function'
            ? options(values)
            : options

        // custom renderers have priority over regular types
        if (customRenderers) {
            const CustomRenderComponent = customRenderers[key]
            if (CustomRenderComponent) {
                return <CustomRenderComponent
                    value={values[key]}
                    onFieldChange={({
                        field: clientField,
                        value: clientValue
                    }) => {
                        onFieldChange({
                            field: clientField,
                            value: parseValue(field, clientValue)
                        })
                    }}
                    field={field}
                />
            }
        }


        switch (type) {
            case 'text': {
                return (
                    <input
                        data-test-id={key}
                        disabled={disabled === true}
                        type="text"
                        className="form-control"
                        name={key}
                        value={values[key]}
                        onChange={handleFieldChange(field)}
                        placeholder={getLocale(placeholder || label)}
                    />
                )
            }
            case 'password': {
                return (
                    <input
                        data-test-id={key}
                        type="password"
                        className="form-control"
                        name={key}
                        value={values[key]}
                        onChange={handleFieldChange(field)}
                        placeholder={getLocale(placeholder || label)}
                    />
                )
            }
            case 'checkbox': {
                return optionValues.map(({ label, value }) => {
                    const checkboxKey = `${key}-${value}`
                    return (
                        <div className="checkbox" data-test-id={checkboxKey} key={checkboxKey}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={values[key].has(value)}
                                    onChange={handleCheckboxChange(field)}
                                    value={value}
                                    name={key}
                                />
                                {label}
                            </label>
                        </div>
                    )
                })
            }
            case 'dropdown': {
                return (
                    <select
                        data-test-id={key}
                        className="form-control"
                        value={values[field.key]}
                        onChange={handleFieldChange(field)}
                    >
                        {
                            optionValues.map(({ value, label }) => {
                                return <option key={value} value={value}>{label}</option>
                            })
                        }
                    </select>

                )
            }
            case 'date': {
                return (
                    <DatePicker
                        dataTestId={key}
                        value={values[key]}
                        disabled={disabled}
                        onChange={([date]) => {
                            onFieldChange({ field, value: date })
                        }}
                    />
                )
            }
            default: {
                return null
            }
        }
    }

    return (
        <div className={classnames(isHorizontal && 'form-horizontal')}>
            {
                fields.map((field) => {
                    const {
                        label,
                        key,
                        shouldShow,
                        isValid,
                        required
                    } = field

                    if (shouldShow === false || (typeof (shouldShow) === 'function' && shouldShow(values) === false)) {
                        return null
                    }

                    const computatedIsValid = typeof (isValid) === 'function' && required === true && !isValid(values[key])
                    const validationComponent = typeof (isValid) === 'function' &&
                        required === true &&
                        !isValid(values[key]) &&
                        <div className="help-block">
                            {getLocale('Required')}
                        </div>

                    return (
                        <div className={classnames('form-group', computatedIsValid && 'has-error')} key={key}>
                            <label
                                className={classnames(
                                    'control-label',
                                    {
                                        'col-sm-3': isHorizontal
                                    }
                                )}>
                                {getLocale(label)}
                            </label>
                            {
                                isHorizontal
                                    ? (
                                        <Col sm={5}>
                                            {getField(field)}
                                            {validationComponent}
                                        </Col>
                                    )
                                    : (
                                        <div>
                                            {getField(field)}
                                            {validationComponent}
                                        </div>
                                    )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
})


export default FormRenderer