import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import useLocale from '../../hooks/use-locale';
import { useHistory, Switch, useRouteMatch, useParams, useLocation } from 'react-router-dom';
import PrivateRoute from '../../components/private-route';
import DatePicker from '../../components/date-picker';
import DropDownSelector from '../../components/drop-down-selector';
import { useAssignedTo, useProfile, useAsync, useMount } from '../../hooks';
import { LabelsReport, FromDateReportField, DataEnteredByUserReport } from './models';
import reportInstance from './models'
import Table from '../../components/table';
import { Alert } from '../../components/bootstrap';
import Pagination from '../../components/pagination';
import { queryReport, searchUsers } from '../../services/api';
import { useSelector } from 'react-redux';
import { PROFILE_ROLES, STATUSES } from '../../constants';


const ReportsList = () => {
    const { getLocale } = useLocale()
    const history = useHistory()
    const { url } = useRouteMatch()
    const profile = useSelector(state => state.profile)

    const tableHeadings = [{
        label: getLocale('SNO'),
    }, {
        label: getLocale('Name')
    }, {
        label: getLocale('ReportType')
    }]

    const tableRows = reportInstance.reports
        .filter((report) => {
            if (report.id === DataEnteredByUserReport.id && profile.role !== PROFILE_ROLES.ADMIN) {
                return false
            }

            return true
        })
        .map((report, index) => {
            return {
                props: {
                    onClick: () => history.push(`${url}/${report.id}`)
                },
                columns: [
                    { children: index + 1 },
                    { children: report.name },
                    { children: report.type }
                ]
            }
        })

    return (
        <div>
            <div className="page-header">
                <h1>Reports</h1>
            </div>
            <Table
                headings={tableHeadings}
                rows={tableRows}
            />
        </div>

    )
}

const _parseFieldsToValuesFromQuery = (fields, qs) => {
    const values = fields.reduce((acc, field) => {
        const value = qs.get(field.id)
        if (!value) {
            if (field.type === 'checkbox') {
                acc[field.id] = new Set()
            } else if (field instanceof FromDateReportField) {
                acc[field.id] = new Date(2017, 0, 1)
            } else {
                acc[field.id] = ''
            }
            return acc
        }
        switch (field.valueType) {
            case 'date': {
                acc[field.id] = value === 'all' ? 'all' : new Date(parseInt(value))
                return acc
            }
            case 'number': {
                // checkboxes needs sets to work
                if (field.type === 'checkbox') {
                    const numbers = value.split(',').map((num) => parseInt(num, 10))
                    acc[field.id] = new Set(numbers)
                    return acc
                }
                acc[field.id] = value === 'all' ? 'all' : parseInt(value)
                return acc
            }
            default: {
                acc[field.id] = value
                return acc
            }
        }
    }, {})
    return values
}

const _getReportParams = ({
    location,
    userId,
    report,
    viewOption,
    shouldShowHeaders,
    shouldBeRaw,
    isLabel,
    saveFormat
}) => {
    const qs = new URLSearchParams(location.search)
    const values = _parseFieldsToValuesFromQuery(report.orderedFields, qs)
    const params = {
        uid: userId,
        option: viewOption,
        type: report.code,
        shouldBeRaw,
        shouldShowHeaders,
        isLabel,
        saveFormat
    }

    // only add paging options if showing in browser
    if (viewOption === 'show') {
        params.itemsPerPage = 10
        params.page = qs.has('page') ? parseInt(qs.get('page')) : 1
    }

    return {
        params,
        payload: report.getApiPayload(values)
    }
}

const ReportDetail = () => {
    const { id } = useParams()
    const { profile } = useProfile()
    const location = useLocation()
    const history = useHistory()
    const queryString = new URLSearchParams(location.search)
    const report = reportInstance.getReport(id)

    const formRef = useRef()

    const { getLocale } = useLocale()
    const { assignTos, asyncStatus: assignedToAsyncStatus } = useAssignedTo()
    const [reportValues, setReportValues] = useState(report.getDefaultResponseObject())
    const { rolesDropdownList } = useProfile()
    const [areParamsValid, setAreParamsValid] = useState(false)
    const [, setUserLoadStatus] = useState(STATUSES.NONE)
    report.setDivisionOptions(rolesDropdownList)

    const {
        execute: executeGetRport,
        data: json
    } = useAsync(queryReport)

    useMount(() => {
        const values = _parseFieldsToValuesFromQuery(report.orderedFields, queryString)
        setReportValues(values)

        if (report.id === DataEnteredByUserReport.id) {
            const fetchUser = async () => {
                setUserLoadStatus(STATUSES.LOADING)
                const { data } = await searchUsers({
                    params: {},
                    pageSize: 100
                })
                report.setUserOptions(
                    data.map(u => ({
                        value: u.id,
                        label: `${u.firstName} ${u.lastName}`
                    }))
                )
                setUserLoadStatus(STATUSES.SUCCESS)
            }
            fetchUser()
        }
    })

    useEffect(() => {
        report.setDivisionOptions(rolesDropdownList)
    }, [report, rolesDropdownList])

    useEffect(() => {
        report.setAssignToOptions(assignTos)
    }, [report, assignTos, assignedToAsyncStatus])

    // checks whether or not to disable the submit button.  Using
    // form fields directly here to determine which fields are currently displayed 
    // on the page
    useEffect(() => {
        setAreParamsValid(report.areInputsValid(reportValues))
    }, [report, reportValues])

    useEffect(() => {
        if (!location.search) {
            return
        }
        executeGetRport(_getReportParams({
            viewOption: 'show',
            report,
            location,
            userId: profile.id
        }))
    }, [location.search, report, location, profile.id, executeGetRport])

    const jsonReportTableValues = useMemo(() => {
        if (!json) {
            return null
        }
        return {
            headings: json.Report.Headers.map(heading => ({ label: heading })),
            rows: json.Report.Data.map((data, index) => {
                return {
                    props: {
                        onClick: () => {
                            window.open(`/dashboard/client/${json.Report.Attributes[index]}/detail/general`, `_blank`)
                        }
                    },
                    columns: data.map((colData) => {
                        return {
                            children: colData
                        }
                    })
                }
            })
        }
    }, [json])

    const generateSaveReportFn = (format) => async () => {
        const reportWindow = window.open('', 'reportWindow')
        const params = _getReportParams({
            report,
            location,
            userId: profile.id,
            viewOption: 'save',
            saveFormat: format
        })
        const data = await queryReport(params)
        reportWindow.location.replace(`${process.env.REACT_APP_API_URL}/report?file=${data.file}`)
    }

    const generatePrintReportFn = ({ isLabel = false } = {}) => async () => {
        const html = await queryReport(_getReportParams({
            report,
            location,
            userId: profile.id,
            viewOption: 'print',
            shouldBeRaw: 1,
            shouldShowHeaders: true,
            isLabel
        }))
        const printWindow = window.open('', 'printWindow');
        printWindow.document.write(html)
        setTimeout(() => {
            printWindow.print()
        }, 300)
    }

    const genOnChangeFn = (field) => (e) => {
        const value = e.target.value
        let parsedValue
        switch (field.valueType) {
            case 'number': {
                parsedValue = !['all', ''].includes(value) ? parseInt(value) : value
                break
            }
            case 'date': {
                parsedValue = value === 'all' ? 'all' : new Date(value)
                break
            }
            default: {
                parsedValue = value
            }
        }

        // modifyFields returns a modified state to be set in the state.
        const modifiedReportValues = report.modifyFields({
            ...reportValues,
            [field.id]: parsedValue
        })
        setReportValues(modifiedReportValues)
    }

    const genOnDateChangeFn = (field) => ([date]) => {
        setReportValues({
            ...reportValues,
            [field.id]: date.getTime()
        })
    }

    const genCheckboxSelectAll = (field) => () => {
        const set = new Set(field.options.map(a => a.id))
        setReportValues({
            ...reportValues,
            [field.id]: set
        })
    }

    const genCheckboxChange = (field) => (e) => {
        const set = reportValues[field.id]
        const parsedValue = field.valueType === 'number' ? parseInt(e.target.value) : e.target.value
        set[set.has(parsedValue) ? 'delete' : 'add'](parsedValue)
        setReportValues({
            ...reportValues,
            [field.id]: set
        })
    }

    const getReportOptions = () => {
        const options = [{
            onClick: generatePrintReportFn(),
            children: getLocale('print')
        }, {
            onClick: generateSaveReportFn('pdf'),
            children: getLocale('PDF')
        }, {
            onClick: generateSaveReportFn('excel'),
            children: getLocale('Excel')
        }]

        if (report.id === LabelsReport.id) {
            options.push({
                onClick: generatePrintReportFn({ isLabel: true }),
                children: getLocale('Labels')
            })
        }

        return options
    }

    return (
        <div>
            <div className="page-header">
                <h1 className="text-capitalize">{report.name}</h1>
            </div>

            <form ref={formRef}
                onSubmit={(e) => {
                    e.preventDefault()
                    const qs = new URLSearchParams()
                    Object.entries(reportValues).forEach(([key, value]) => {
                        if (value instanceof Date) {
                            qs.set(key, value.getTime())
                        } else if (value instanceof Set) {
                            qs.set(key, Array.from(value.values()).join(','))
                        } else {
                            qs.set(key, value)
                        }
                    })
                    history.push({ search: qs.toString() })
                }}>
                {
                    report.orderedFields.map((field) => {
                        if (field.shouldShow === false) {
                            return null
                        }

                        return (
                            <div className="form-group" key={field.label}>
                                {field.type === 'date' && (
                                    <div>
                                        <label>{getLocale(field.label)}</label>
                                        <DatePicker
                                            name={field.id}
                                            value={reportValues[field.id]}
                                            onChange={genOnDateChangeFn(field)}
                                        />
                                    </div>
                                )}
                                {field.type === 'dropdown' && (
                                    <div>
                                        <label>{getLocale(field.label)}</label>
                                        <DropDownSelector
                                            options={field.options}
                                            value={reportValues[field.id]}
                                            onChange={genOnChangeFn(field)}
                                            hasAllOption={field.hasAllOption}
                                        />
                                    </div>
                                )}
                                {/* Very specific use case for assign tos */}
                                {field.type === 'checkbox' && (
                                    <div>
                                        <label>{getLocale(field.label)}</label>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-default btn-sm"
                                                onClick={genCheckboxSelectAll(field)}
                                            >
                                                {getLocale('selectAll')}
                                            </button>
                                        </div>
                                        <div className="row">
                                            {assignTos.map((assignTo) => {
                                                return (
                                                    <div key={assignTo.id} className="col-md-3 col-xs-12">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input
                                                                    value={assignTo.id}
                                                                    type="checkbox"
                                                                    checked={reportValues[field.id].has(assignTo.id)}
                                                                    onChange={genCheckboxChange(field)}
                                                                />
                                                                <span>{assignTo.name}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })
                }

                <div className="form-group">
                    <input
                        type="submit"
                        disabled={!areParamsValid}
                        className="btn btn-primary"
                        value="Search"
                    />
                </div>
            </form>

            {json && jsonReportTableValues.rows.length === 0 && (
                <Alert variant="warning">{getLocale('NoRecordsFound')}</Alert>
            )}
            {
                json && jsonReportTableValues.rows.length > 0 && (
                    <Fragment>
                        <ul className="list-inline">
                            {
                                getReportOptions().map((props, index) => {
                                    return (
                                        <li key={`report-options-${index}`}>
                                            <button
                                                type="button"
                                                className="btn btn-default btn-sm"
                                                {...props}

                                            />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <Table
                            {...jsonReportTableValues}
                        />
                        {json.Paginator?.Pages.length > 1 && (
                            <Pagination
                                currentPage={queryString.get('page') || 1}
                                totalPages={json.Paginator.Pages.length}
                                offset={1}
                            />
                        )}
                    </Fragment>
                )
            }
        </div>
    )
}

const Reports = () => {
    const { url } = useRouteMatch()
    return (
        <Switch>
            <PrivateRoute path={`${url}/:id`}>
                <ReportDetail />
            </PrivateRoute>
            <PrivateRoute>
                <ReportsList />
            </PrivateRoute>
        </Switch>
    )
}

export default Reports