import { getAuthToken, removeAuthToken } from './local-storage'
import { HttpError } from '../errors'
import { ROLES_MAP, PROFILE_ROLES } from '../constants'

const _baseFetch = async ({
    path,
    method = 'GET',
    version,
    payload,
    auth = false,
    payloadType = 'json',
    responseType = 'json',
    query,
    redirect,
    bypassParsing
}) => {
    try {
        const options = {
            method,
            headers: {}
        }

        if (auth === true) {
            options.headers['Authorization'] = getAuthToken()
        }

        if (redirect) {
            options.redirect = redirect
        }

        switch (payloadType) {
            case 'file': {
                options.body = payload
                break
            }
            default: {
                if (payload) {
                    options.body = JSON.stringify(payload)
                    options.headers['Content-Type'] = 'application/json'
                }
            }
        }

        let queryString
        if (query) {
            queryString = new URLSearchParams(Object.entries(query)).toString()
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}${version ? `/v${version}` : ''}${path}${queryString ? `?${queryString}` : ''}`,
            options
        )

        // this is for handling redirects (i.e. getting attachment urls)
        if (bypassParsing === true) {
            return response
        }

        if (response.status === 401 && auth === true) {
            removeAuthToken()
            throw new HttpError(response)
        }

        if (!response.ok) {
            throw new HttpError(response)
        }

        if (response.headers.get('content-length') === '0' || response.status === 204 || responseType === 'text') {
            return response.text()
        }

        return await response.json()
    } catch (error) {
        throw error
    }
}

export const login = async (email, password) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({
            email,
            password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/authentication`, options)
    if (!response.ok) {
        throw new HttpError(response)
    }

    return {
        token: response.headers.get('authorization')
    }
}

export const getProfile = async () => {
    const profile = await _baseFetch({
        path: '/user',
        auth: true
    })
    const isAdmin = profile.roles.some(r => r.roleID === ROLES_MAP.ManagementAdmin)
    profile.role = isAdmin ? PROFILE_ROLES.ADMIN : PROFILE_ROLES.USER
    return profile
}

export const searchClient = async ({
    params,
    page = 0,
    pageSize = 10
}) => {
    const body = {
        andFilters: [],
        orFilters: [],
        orderBy: [],
        filterOperand: 'and',
        page,
        pageSize
    }

    // go through each of the search parameters to build the search query for the API
    for (let param in params) {
        if (!params[param]) {
            continue
        }
        const value = params[param]
        switch (param) {
            case 'firstName':
            case 'lastName':
            case 'alsoKnownAs': {
                body.andFilters.push({ field: param, filterType: "like", value })
                break
            }
            case 'email': {
                body.orFilters.push({ field: 'primaryEmail', filterType: "like", value })
                body.orFilters.push({ field: 'secondaryEmail', filterType: "like", value })
                break;
            }
            case 'industryProfession': {
                body.orFilters.push({ field: 'title', filterType: "like", value })
                body.orFilters.push({ field: 'industryProfession', filterType: "like", value })
                break
            }
            case 'companyName': {
                body.orFilters.push({ field: param, filterType: "like", value })
                break
            }
            case 'phoneNumber': {
                const phoneInt = parseInt(value)
                body.orFilters.push({ field: 'homePhoneNumber', filterType: "equal", value: phoneInt })
                body.orFilters.push({ field: 'businessPhoneNumber', filterType: "equal", value: phoneInt })
                body.orFilters.push({ field: 'cellPhoneNumber', filterType: "equal", value: phoneInt })
                break
            }
            default: {
                break
            }
        }
    }

    return _baseFetch({
        path: '/client/search',
        auth: true,
        method: 'POST',
        payload: body
    })
}

export const getClient = async (clientId) => {
    const client = await _baseFetch({
        path: `/client/${clientId}`,
        auth: true
    })

    // transform client
    client.divisions.forEach((division) => {
        Object.keys(division).forEach((key) => {
            switch (key) {
                case 'followUpDate': {
                    // This should be handled on the backend, but the client api is in go and we don't want to make changes to the legacy API
                    division[key] = (division.followUpDate && division.followUpDate !== '1970-01-01T01:00:00Z') ? new Date(division.followUpDate) : null
                    break
                }
                case 'status': {
                    // need to map division statuses because we had to hack 
                    // how we delete statuses for the follow up section.
                    // If status === 1000 then we have to set it as empty string
                    division.status = division.status === 1000 ? undefined : division.status
                    break
                }
                default: {
                    division[key] = division[key] !== undefined ? division[key] : ''
                }
            }
        })
    })

    return client
}

export const deleteClient = async (clientId) => {
    return _baseFetch({
        path: `/client/${clientId}`,
        auth: true,
        method: 'DELETE'
    })
}

export const linkClient = async (clientId, partnerId = '') => {
    return _baseFetch({
        method: 'PUT',
        path: `/client/${clientId}/partner/${partnerId}`,
        auth: true,
        responseType: 'text'
    })
}

export const getAssignToList = async () => {
    return _baseFetch({
        path: `/assignments`,
        version: 2,
        auth: true
    })
}

export const saveFollowUp = async ({
    status,
    followUpDate,
    assignmentId,
    divisionId,
    entryId,
    clientId
}) => {
    let payload = {}

    if (status !== null && status !== undefined && status !== '') {
        payload.status = parseInt(status)
    }

    // if entryId does not exist then create the relationship before we update it
    if (entryId === undefined || entryId === null || entryId === '') {
        if (divisionId !== '' && divisionId !== null && divisionId !== undefined) {
            payload.divisionId = parseInt(divisionId)
        }

        const createConfig = {
            path: `/clients/${clientId}/divisions`,
            version: 2,
            auth: true,
            method: 'POST',
            payload
        }

        // response comes back with the data model needed to update
        // the follow up date and assignment
        payload = await _baseFetch(createConfig)
    }

    // cannot check for falsey values because there are IDs that are 0 which is falsey
    if (followUpDate !== '' && followUpDate !== null && followUpDate !== undefined) {
        payload.followUpDate = followUpDate
    } else {
        payload.followUpDate = null;
    }

    if (assignmentId !== '' && assignmentId !== null && assignmentId !== undefined) {
        payload.assignmentId = parseInt(assignmentId)
    } else {
        payload.assignmentId = null;
    }

    if (payload.status !== null || payload.status !== undefined || payload.followUpDate || payload.assignmentId) {
        const patchConfig = {
            path: `/v2/clients/${clientId}/divisions/${payload.id || entryId}`,
            auth: true,
            method: 'PATCH',
            payload
        }

        return _baseFetch(patchConfig)
    }

    return payload
}

export const createFollowUp = async ({
    status,
    divisionId,
    clientId
}) => {
    let payload = {}

    if (status !== null && status !== undefined && status !== '') {
        payload.status = parseInt(status)
    }

    // if entryId does not exist then create the relationship before we update it
    if (divisionId !== '' && divisionId !== null && divisionId !== undefined) {
        payload.divisionId = parseInt(divisionId)
    }
    // response comes back with the data model needed to update
    // the follow up date and assignment
    return await _baseFetch({
        path: `/clients/${clientId}/divisions`,
        version: 2,
        auth: true,
        method: 'POST',
        payload
    })
}


export const updateFollowUp = async ({
    status,
    followUpDate,
    assignmentId,
    entryId,
    clientId
}) => {
    let payload = {}

    if (status !== undefined && status !== '') {
        payload.status = status === null ? null : parseInt(status)
    }

    // cannot check for falsey values because there are IDs that are 0 which is falsey
    if (followUpDate !== '' && followUpDate !== undefined) {
        payload.followUpDate = followUpDate
    }

    if (assignmentId !== '' && assignmentId !== undefined) {
        payload.assignmentId = parseInt(assignmentId)
    }

    return _baseFetch({
        path: `/v2/clients/${clientId}/divisions/${entryId}`,
        auth: true,
        method: 'PATCH',
        payload
    })
}

export const getNotesForClient = async (clientId) => {
    return _baseFetch({
        path: `/note/client/${clientId}`,
        auth: true
    })
}

export const getNotesByClientAndDivision = async (clientId, divisionId) => {
    const response = await _baseFetch({
        path: `/v2/clients/${clientId}/divisions/${divisionId}/notes`,
        auth: true
    })

    // if response is 204, then return empty array
    return response === '' ? [] : response
}


export const deleteNote = async (noteId) => {
    return _baseFetch({
        path: `/note/${noteId}`,
        auth: true,
        method: 'DELETE'
    })
}

export const saveClient = async (client) => {
    let configs = {
        path: client.id ? `/client/${client.id}` : '/client',
        method: client.id ? 'PUT' : 'POST',
        auth: true,
        payload: client
    }
    return _baseFetch(configs)
}

export const saveClientNoteAndAttachments = async ({
    clientId,
    note,
    attachments,
    divisionId
}) => {
    // need to save the note first to generate the note id
    let newNote = await saveClientNote(clientId, divisionId, note)

    // return early if there are no attachments
    if (!attachments || attachments.length === 0) {
        return newNote
    }

    // process attachments
    for (let i = 0; i < attachments.length; i++) {
        const file = attachments[i]
        // upload files to s3
        const { name } = await uploadFile({
            clientId,
            divisionId,
            noteId: newNote.id,
            file
        })

        // attach base64 name tonote for reference
        newNote = await linkAttachmentToNote({
            clientId: clientId,
            divisionId,
            noteId: newNote.id,
            name
        })
    }

    return newNote
}

export const saveClientNote = async (clientId, divisionId, note) => {
    return _baseFetch({
        method: 'POST',
        path: `/v2/clients/${clientId}/divisions/${divisionId}/notes`,
        auth: true,
        payload: {
            note
        }
    })
}

export const uploadFile = async ({
    clientId,
    divisionId,
    noteId,
    file
}) => {
    const name = btoa(file.name)
    // get link to upload file
    const response = await _baseFetch({
        path: `/v2/clients/${clientId}/divisions/${divisionId}/notes/${noteId}/attachments:upload`,
        method: 'POST',
        auth: true,
        bypassParsing: true,
        query: {
            name
        }
    })

    // retrieve aws presigned link in header
    const uploadUrl = response.headers.get('location')

    // put to aws presigned link
    await fetch(uploadUrl, {
        method: 'PUT',
        body: file
    })

    return { name }
}

export const linkAttachmentToNote = async ({
    clientId,
    divisionId,
    noteId,
    name
}) => {
    return await _baseFetch({
        path: `/v2/clients/${clientId}/divisions/${divisionId}/notes/${noteId}/attachments`,
        method: 'POST',
        auth: true,
        query: {
            name
        }
    })
}

export const getAttachmentDetails = async ({
    clientId,
    divisionId,
    noteId,
    attachmentId,
    name
}) => {
    const base64Name = btoa(name)
    const response = await _baseFetch({
        path: `/v2/clients/${clientId}/divisions/${divisionId}/notes/${noteId}/attachments/${attachmentId}`,
        auth: true,
        bypassParsing: true,
        redirect: 'manual',
        query: {
            name: base64Name
        }
    })
    return response.headers.get('location')
}

export const queryReport = async ({
    params: {
        uid = null,
        page = null,
        itemsPerPage = null,
        option = 'show',
        type = null,
        shouldShowHeaders = false,
        shouldBeRaw = false,
        isLabel = false,
        saveFormat = null
    } = {},
    payload
}) => {
    if (uid === null || type === null) {
        throw new Error('Missing required params')
    }

    const queryString = new URLSearchParams()
    queryString.set('uid', uid)
    queryString.set('option', option)
    queryString.set('type', type)

    if (page !== null) {
        queryString.set('page', page)
    }

    if (itemsPerPage !== null) {
        queryString.set('itemsPerPage', itemsPerPage)
    }

    if (shouldShowHeaders) {
        queryString.set('showHeadings', 'true')
    }

    if (shouldBeRaw) {
        queryString.set('raw', '1')
    }

    if (isLabel) {
        queryString.set('labels', '1')
    }


    if (saveFormat !== null && option === 'save') {
        queryString.set('format', saveFormat)
    }

    const response = await _baseFetch({
        path: [
            '/report',
            option === 'show' ? '?json=1&' : '?',
            queryString.toString()
        ].join(''),
        method: 'POST',
        payload,
        auth: true,
        bypassParsing: true
    })

    switch (option) {
        case 'print': {
            return response.text()
        }
        case 'show':
        default: {
            return response.json()
        }
    }
}

export const searchUsers = async ({
    params: {
        page = 0,
        ...params
    },
    pageSize = 10
} = {}) => {
    const body = {
        andFilters: [],
        orFilters: [],
        orderBy: [],
        filterOperand: 'and',
        page: parseInt(page),
        pageSize
    }

    // go through each of the search parameters to build the search query for the API
    for (let param in params) {
        if (!params[param]) {
            continue
        }
        const value = params[param]
        switch (param) {
            case 'firstName':
            case 'lastName': {
                body.andFilters.push({ field: param, filterType: "like", value })
                break
            }
            case 'email': {
                body.orFilters.push({ field: 'email', filterType: "like", value })
                break;
            }
            default: {
                break
            }
        }
    }

    return _baseFetch({
        path: '/user/search',
        auth: true,
        method: 'POST',
        payload: body
    })
}

export const userDetail = async (id) => {
    return _baseFetch({
        path: `/user/${id}`,
        auth: true,
        method: 'GET'
    })
}

export const updateUserDetail = async (id, payload) => {
    return _baseFetch({
        path: `/user/${id}`,
        auth: true,
        method: 'PUT',
        payload
    })
}

export const createUser = async (id, payload) => {
    return _baseFetch({
        path: `/user`,
        auth: true,
        method: 'POST',
        payload
    })
}

export const resetPassword = async (email) => {
    return _baseFetch({
        path: '/user/passwordReset',
        auth: true,
        method: 'POST',
        payload: { email }
    })
}

export const deleteUser = async (id) => {
    return _baseFetch({
        path: `/user/${id}`,
        auth: true,
        method: 'DELETE'
    })
}

export const initializePasswordReset = async (email) => {
    return _baseFetch({
        path: '/user/passwordReset',
        method: 'POST',
        payload: {
            email
        }
    })
}

export const completeActivation = async (hash, password) => {
    const config = {
        path: `/validation/complete/${hash}`,
        method: 'POST'
    }

    if (password) {
        config.payload = {
            password
        }
    }
    return _baseFetch(config)
}

export const validateHash = async (hash) => {
    return _baseFetch({
        path: `/validation/start/${hash}`,
        method: 'GET'
    })
}

export const saveFollowupEmail = async ({ id, email, name }) => {
    return _baseFetch({
        path: `/v2/assignments/${id}`,
        method: 'PATCH',
        auth: true,
        payload: {
            email,
            name
        }
    })
}