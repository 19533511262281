import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Switch, useRouteMatch, Redirect, useHistory, Prompt } from 'react-router-dom'

import Client from '../client'
import { getProfileAction } from '../../modules/profile'
import Nav from './nav'
import PrivateRoute from '../../components/private-route'
import ProfileMetaData from './profile-meta-data'
import Loading from '../../components/loading'

import './dashboard.css'
import Admin from '../admin'
import Reports from '../reports'
import { STATUSES } from '../../constants'
import { useRef } from 'react'
import { removeAuthToken } from '../../services/local-storage'
import { useCallback } from 'react'
import { createContext } from 'react'
import { useContext } from 'react'
import useLocale from '../../hooks/use-locale'
import Modal from '../../components/modal'
import { differenceInMinutes } from 'date-fns'

const TimerContext = createContext({
    hasExpired: false,
    showWarning: false,
    expiryDate: null
})

export const useTimerContext = () => {
    return useContext(TimerContext)
}

const MOUSE_INACTIVITY_TIME_IN_MS = 10000
const INACTIVITY_TIME_IN_SECONDS = 14400 // 4 hours
const WARNING_BUFFER_IN_SECONDS = 300 // 5 mins

const TimerProvider = ({ children }) => {
    const expiry = useRef()
    const warningDate = useRef()
    const timeout = useRef()
    const isMounted = useRef(true)
    const warningInterval = useRef()
    const { replace } = useHistory()
    const { getLocale } = useLocale()
    const [hasExpired, setHasExpired] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const timeLeft = useRef() // use ref here instead to not trigger re-renders

    const onMouseMove = useCallback((e) => {
        timeout.current && clearTimeout(timeout.current)
        warningInterval.current && clearInterval(warningInterval.current)

        setShowWarning(false)

        const intervalFn = () => {
            // do not run if the component is unmounted
            if (isMounted.current === false) {
                return
            }
            const currentTime = new Date()
            if (currentTime >= warningDate.current) {
                setShowWarning(true)
            }
            const diff = differenceInMinutes(expiry.current, currentTime)
            timeLeft.current = diff

            // log out when difference in in minutes equals to 0
            if (diff === 0) {
                timeout.current && clearTimeout(timeout.current)
                warningInterval.current && clearInterval(warningInterval.current)
                setHasExpired(true)
                const logoutTimer = setTimeout(() => {
                    clearTimeout(logoutTimer)
                    removeAuthToken()
                    replace('/login')
                })
            }
        }

        const timeoutFn = () => {
            // do not run if the component is unmounted
            if (isMounted.current === false) {
                return
            }

            expiry.current = new Date()
            expiry.current.setSeconds(expiry.current.getSeconds() + INACTIVITY_TIME_IN_SECONDS)

            // set same date as expiry and then show the warning x seconds/minutes before expiry
            warningDate.current = new Date(expiry.current)
            warningDate.current.setSeconds(warningDate.current.getSeconds() - WARNING_BUFFER_IN_SECONDS)

            warningInterval.current = setInterval(intervalFn, 30000)
        }

        // wait x seconds before setting inactive state
        timeout.current = setTimeout(timeoutFn, MOUSE_INACTIVITY_TIME_IN_MS)
    }, [replace])


    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove)
        return () => {
            clearTimeout(timeout.current)
            clearInterval(warningInterval.current)
            isMounted.current = false
            window.removeEventListener('mousemove', onMouseMove)
        }
    }, [onMouseMove])

    const value = {
        hasExpired
    }

    return (
        <TimerContext.Provider value={value}>
            {
                showWarning && <Modal
                    title={getLocale('InactiveWarning')}
                    isOpen={showWarning === true}
                    size="sm"
                >
                    {timeLeft.current}{' '}{getLocale('minutesLeftBeforeLoggingOutMessage')}
                </Modal>
            }
            {children}
        </TimerContext.Provider>
    )
}

const Dashboard = ({
    getProfileAction,
    asyncStatus
}) => {
    const { url } = useRouteMatch()
    const { hasExpired } = useTimerContext()
    const { getLocale } = useLocale()

    // on dashboard load, fetch user information
    useEffect(
        () => {
            getProfileAction()
        },
        // this array is needed for change detection.  
        //In this case the function does not change so it will only be fired on mount
        [getProfileAction]
    )

    if ([null, STATUSES.LOADING].includes(asyncStatus)) {
        return <Loading isLoading />
    }

    return (
        <Fragment>
            <Prompt when={hasExpired === true} message={getLocale('loggedOutMessage')} />
            <Nav />
            <div className="container">
                <Switch>
                    <PrivateRoute path={`${url}/client`}>
                        <Client />
                    </PrivateRoute>
                    <PrivateRoute path={`${url}/admin`}>
                        <Admin />
                    </PrivateRoute>
                    <PrivateRoute path={`${url}/reports`}>
                        <Reports />
                    </PrivateRoute>
                    <PrivateRoute>
                        <Redirect to={`${url}/client`} />
                    </PrivateRoute>
                </Switch>
                <ProfileMetaData />
            </div>
        </Fragment>
    )
}

const stateToProps = (state) => {
    return {
        asyncStatus: state.profile.asyncStatus
    }
}

// bind functions to component using react-redux
const dispatchToProps = {
    getProfileAction
}

const DashboardWithState = connect(stateToProps, dispatchToProps)(Dashboard)

const DefaultTimerProvider = () => {
    return (
        <TimerProvider>
            <DashboardWithState />
        </TimerProvider>
    )
}

export default DefaultTimerProvider