import React from 'react'
import ReactModal from 'react-modal'

import './modal.css'
import Loading from './loading'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        borderRadius: 10
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,.4)',
        zIndex: 100
    }
}

ReactModal.setAppElement('#root')

const Modal = ({
    children,
    title,
    onConfirm,
    onCancel,
    isOpen,
    size = 'large',
    isLoading = false,
    ...props
}) => {
    if (size === 'large') {
        customStyles.content.width = '80%'
        customStyles.content.height = '80%'
    } else {
        customStyles.content.width = 600
    }
    return (
        <ReactModal
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            isOpen={isOpen}
            {...props}
            style={customStyles}
        >
            <div className="itinto-modal__content-container fullscreen">
                <div className="itinto-modal__header">{title}</div>
                <div className="itinto-modal__content">
                    {children}
                </div>
                <div className="itinto-modal__footer text-right">
                    {isLoading && <Loading isLoading />}
                    {
                        !isLoading && <ul className="list-inline">
                            {
                                typeof (onCancel) === 'function' && (
                                    <li>
                                        <button type="button" className="btn btn-sm btn-danger" onClick={onCancel}>
                                            <span>Cancel</span>
                                        </button>
                                    </li>
                                )
                            }
                            {
                                typeof (onConfirm) === 'function' && (<li>
                                    {/* todo: add back data-ng-disabled="!isValid" */}
                                    <button type="button" onClick={onConfirm} className="btn btn-sm btn-primary">
                                        <span>Save</span>
                                    </button>
                                </li>)
                            }
                        </ul>
                    }
                </div>
            </div>
        </ReactModal >
    )
}

export default Modal