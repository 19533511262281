import React, { useMemo, memo } from 'react'
import classnames from 'classnames'

import useLocale from '../hooks/use-locale'
import { NOTE_STATUS_LIST, MARKETING_CONSENT_LIST } from '../constants'
import { useAssignedTo } from '../hooks'

const DropDownSelector = ({
    size,
    options = [],
    showEmptyOption = true,
    hasAllOption = false,
    ...rest
}) => {
    const { getLocale } = useLocale()
    return (
        <select
            {...rest}
            className={
                classnames({
                    'form-control': true,
                    sm: size === 'sm'
                })
            }
        >
            {showEmptyOption && <option value=""></option>}
            {
                options.map((option) => {
                    return (
                        <option
                            value={option.value}
                            key={option.value}
                        >
                            {option.label}
                        </option>
                    )
                })
            }
            {hasAllOption && <option value="all">{getLocale('all')}</option>}
        </select>
    )
}

export default DropDownSelector

export const DivisionStatusSelector = (props) => {
    const { getLocale } = useLocale()
    const filteredStatusList = useMemo(() => {
        return NOTE_STATUS_LIST
            .filter(n => n.active === true)
            .map(({ id, resource }) => ({ value: id, label: getLocale(`client.status${resource}`) }))
    }, [getLocale])
    return (
        <DropDownSelector {...props} options={filteredStatusList} />
    )
}

export const DivisionAssignToSelector = (props) => {
    const { assignToList } = useAssignedTo()
    return (
        <DropDownSelector {...props} options={assignToList} />
    )
}

export const MarketingConsentSelector = memo(({ includeAllOption = false, ...props }) => {
    const { getLocale } = useLocale()
    const mappedList = useMemo(() => {
        const list = [...MARKETING_CONSENT_LIST]
        if (includeAllOption) {
            list.push({
                value: 'all',
                label: getLocale('all')
            })
        }
        return list
    }, [getLocale, includeAllOption])
    return (
        <DropDownSelector {...props} options={mappedList} />
    )
})

