import {
    DATABASE_TYPES_LIST,
    DATABASE_TYPES,
    NOTE_STATUS_LIST,
    MARKETING_CONSENT_LIST,
    INDUSTRY_PROFESSION_MAP
} from "../../constants"
import { getEndDateFromToday } from "../../services/date"


class ReportField {
    constructor({
        id,
        type,
        label,
        required = true,
        shouldShow = true,
        valueType
    }) {
        this.id = id
        this.type = type
        this.label = label
        this.required = required
        this.shouldShow = shouldShow
        this.valueType = valueType
    }

    hide() {
        this.shouldShow = false
    }

    show() {
        this.shouldShow = true
    }

    disable() {
        this.shouldShow = false
        this.required = false
    }

    enable() {
        this.shouldShow = true
        this.required = true
    }
}

class DateTypeReportField extends ReportField {
    constructor({
        id,
        label,
        required,
        shouldShow,
        valueType
    }) {
        super({
            id,
            required,
            shouldShow,
            label,
            type: 'date',
            valueType
        })
    }
}

class DropDownTypeReportField extends ReportField {
    constructor({
        id,
        required,
        shouldShow,
        label,
        options = [],
        hasAllOption = false,
        valueType
    }) {
        super({
            id,
            required,
            shouldShow,
            label,
            type: 'dropdown',
            valueType
        })
        this.options = options
        this.hasAllOption = hasAllOption
    }
}

class CheckboxReportField extends ReportField {
    constructor({
        id,
        required,
        shouldShow,
        label,
        valueType,
        options
    }) {
        super({ id, required, label, valueType, type: 'checkbox' })
        this.options = options
    }
}


export class FromDateReportField extends DateTypeReportField {
    static id = 'startDate'
    constructor() {
        super({
            id: FromDateReportField.id,
            label: 'fromDate',
            valueType: 'date'
        })
    }
}

export class ToDateReportReportField extends DateTypeReportField {
    static id = 'endDate'
    constructor() {
        super({
            id: ToDateReportReportField.id,
            label: 'toDate',
            valueType: 'date'
        })
    }
}

export class DatabaseTypeReportField extends DropDownTypeReportField {
    static id = 'databaseTypes'
    constructor() {
        super({
            id: DatabaseTypeReportField.id,
            label: 'databaseType',
            hasAllOption: true,
            valueType: 'number',
            options: DATABASE_TYPES_LIST
        })
    }
}

export class IndustryProfessionsField extends DropDownTypeReportField {
    static id = 'industryProfessions'
    constructor() {
        super({
            id: IndustryProfessionsField.id,
            label: 'industryProfession',
            required: true,
            shouldShow: false,
            hasAllOption: true,
            valueType: 'string'
        })
    }

    toggleModifier(inputs) {
        const databaseInputValue = inputs[DatabaseTypeReportField.id]
        const shouldShowDatabaseValues = [DATABASE_TYPES.B2B, DATABASE_TYPES.B2P, DATABASE_TYPES.CORP]
        const action = databaseInputValue !== undefined && shouldShowDatabaseValues.includes(databaseInputValue) ? 'show' : 'hide'
        this[action]()
        if (action === 'show') {
            this.options = industryProfessionMapping.get(databaseInputValue)
        }
        if (action === 'hide') {
            inputs[this.id] = undefined
        }
        return inputs
    }
}

export class DivisionField extends DropDownTypeReportField {
    static id = 'divisionID'
    constructor() {
        super({
            id: DivisionField.id,
            label: 'division',
            required: true,
            shouldShow: false,
            valueType: 'number',
            hasAllOption: true
        })
    }

    toggleModifier(inputs) {
        const databaseInputValue = inputs[DatabaseTypeReportField.id]
        const shouldShowDatabaseValues = [DATABASE_TYPES.B2C]
        const action = databaseInputValue !== undefined && shouldShowDatabaseValues.includes(databaseInputValue) ? 'show' : 'hide'
        this[action]()
        if (action === 'hide') {
            inputs[this.id] = undefined
        }
        return inputs
    }
}

export class ClientStatusField extends DropDownTypeReportField {
    static id = 'statuses'
    constructor() {
        super({
            id: ClientStatusField.id,
            label: 'clientStatus',
            hasAllOption: true,
            valueType: 'number',
            options: NOTE_STATUS_LIST
                .filter(status => status.active === true)
                .map((status) => {
                    return {
                        value: status.id,
                        label: status.resource
                    }
                })
        })
    }
}

export class MarketingConsentField extends DropDownTypeReportField {
    static id = 'marketingConsent'
    constructor() {
        super({
            id: MarketingConsentField.id,
            label: 'marketingConsent',
            options: MARKETING_CONSENT_LIST,
            hasAllOption: true,
            valueType: 'number'
        })
    }
}

export class UserReportField extends DropDownTypeReportField {
    static id = 'users'
    constructor() {
        super({
            id: UserReportField.id,
            label: 'user',
            valueType: 'number',
            hasAllOption: true
        })
    }
}

export class FollowUpDateReportField extends DropDownTypeReportField {
    static id = 'endDate'
    constructor() {
        super({
            id: FollowUpDateReportField.id,
            label: 'followUp',
            hasAllOption: true,
            options: [{
                label: 'Today',
                value: getEndDateFromToday()
            }, {
                label: 'Next 3 days (inclusive of today)',
                value: getEndDateFromToday(3)
            }, {
                label: 'Next 7 days (inclusive of today)',
                value: getEndDateFromToday(7)
            }],
            valueType: 'date'
        })
    }
}

export class AssignToReportField extends CheckboxReportField {
    static id = 'assignedTo'
    constructor() {
        super({
            id: AssignToReportField.id,
            label: 'assignedTo',
            required: false,
            valueType: 'number',
        })
    }
}

const ipToDropdownModel = (ip) => ({ value: ip, label: ip })
const b2bIndustryProfessions = INDUSTRY_PROFESSION_MAP[DATABASE_TYPES.B2B].map(ipToDropdownModel)
const b2pIndustryProfessions = INDUSTRY_PROFESSION_MAP[DATABASE_TYPES.B2P].map(ipToDropdownModel)
const corpIndustryProfessions = INDUSTRY_PROFESSION_MAP[DATABASE_TYPES.CORP].map(ipToDropdownModel)

const industryProfessionMapping = new Map([
    [DATABASE_TYPES.B2B, b2bIndustryProfessions],
    [DATABASE_TYPES.B2P, b2pIndustryProfessions],
    [DATABASE_TYPES.CORP, corpIndustryProfessions]
])


class Report {
    constructor({
        id,
        code,
        type,
        name,
    }) {
        this.id = id
        this.name = name
        this.type = type
        this.code = code
        this.orderedFields = []
        this.fieldsMap = new Map()
        this.fieldModifiers = []
    }

    _registerField(field) {
        this.orderedFields.push(field)
        this.fieldsMap.set(field.id, field)
    }

    _setFieldOptions(fieldId, options) {
        const field = this.fieldsMap.get(fieldId)
        if (!field) {
            return false
        }
        field.options = options
    }

    addFieldModifier(modifier) {
        if (typeof (modifier) !== 'function') {
            return
        }
        this.fieldModifiers.push(modifier)
    }

    /**
     * runs modifiers for the report (i.e. show and hide fields on field change)
     * it returns an updated input that can be used to set the state in the component.
     * 
     * I did not want to have the component know about the internals of this reports
     * module.  So the only way the component can interact with the report is through
     * the interface in this module.
     * 
     * @param {Object} inputs report input values from the user
     */
    modifyFields(inputs) {
        return this.fieldModifiers.reduce((acc, modifier) => {
            return modifier(acc)
        }, { ...inputs })
    }

    setDivisionOptions(options) {
        this._setFieldOptions(DivisionField.id, options)
    }

    setIndustryProfessionOptions(options) {
        this._setFieldOptions(IndustryProfessionsField.id, options)
    }

    setUserOptions(options) {
        this._setFieldOptions(UserReportField.id, options)
    }

    setAssignToOptions(options) {
        this._setFieldOptions(AssignToReportField.id, options)
    }

    areInputsValid(inputs) {
        return this.orderedFields
            .filter((f) => f.shouldShow && f.required === true)
            .every((f) => {
                return inputs[f.id] !== undefined && inputs[f.id] !== ''
            })
    }

    getDefaultResponseObject() {
        return this.orderedFields.reduce((acc, f) => {
            if (f instanceof CheckboxReportField) {
                acc[f.id] = new Set()
                return acc
            }

            if (f instanceof FromDateReportField) {
                acc[f.id] = new Date(2017,1, 1)
                return acc
            }

            if (f instanceof DateTypeReportField) {
                acc[f.id] = new Date()
                return acc
            }

            acc[f.id] = undefined
            return acc
        }, {})
    }

    getApiPayload(inputs) {
        return this.orderedFields
            // only get fields that are showing
            .filter(f => f.shouldShow === true)
            .reduce((acc, field) => {
                const value = inputs[field.id]

                // most specific goes first
                if (field instanceof IndustryProfessionsField) {
                    if (value === 'all') {
                        acc[field.id] = field.options.map(o => o.value)
                        return acc
                    }

                    if (value === 'Other') {
                        acc[field.id] = 'industryProfessionOther' // hard coded mapping
                        return acc
                    }

                    if (value) {
                        acc[field.id] = [value]
                        return acc
                    }

                    acc[field.id] = []
                    return acc
                }

                if (field instanceof AssignToReportField) {
                    acc[field.id] = Array.from(value)
                    return acc
                }

                if (field.hasAllOption === true && field instanceof FollowUpDateReportField) {
                    acc[field.id] = value === 'all'
                        ? null
                        : value
                    return acc
                }

                if (field instanceof DivisionField) {
                    acc[field.id] = value === 'all'
                        ? null
                        : value
                    return acc
                }

                if (field.hasAllOption === true) {
                    acc[field.id] = value === 'all'
                        ? field.options.map(o => o.value)
                        : [parseInt(value)]
                    return acc
                }

                if (value) {
                    acc[field.id] = value
                }
                return acc
            }, {})
    }
}

export class MarketingConsentReport extends Report {
    static id = 'MarketingConsent'
    constructor() {
        super({
            id: MarketingConsentReport.id,
            name: 'Email Marketing Consent Report',
            type: 'Marketing',
            code: 'mc',
        })

        const fromDateReportField = new FromDateReportField()
        const toDateReportReportField = new ToDateReportReportField()
        const databaseTypeReportField = new DatabaseTypeReportField()
        const industryProfessionsField = new IndustryProfessionsField()
        const divisionField = new DivisionField()
        const clientStatusField = new ClientStatusField()
        const marketingConsentField = new MarketingConsentField()

        this.addFieldModifier(industryProfessionsField.toggleModifier.bind(industryProfessionsField))
        this.addFieldModifier(divisionField.toggleModifier.bind(divisionField))

        this._registerField(fromDateReportField)
        this._registerField(toDateReportReportField)
        this._registerField(databaseTypeReportField)
        this._registerField(industryProfessionsField)
        this._registerField(divisionField)
        this._registerField(clientStatusField)
        this._registerField(marketingConsentField)
    }
}

export class SalesStatisticsReport extends Report {
    static id = 'SalesStatistics'
    constructor() {
        super({
            id: SalesStatisticsReport.id,
            name: 'Sales Statistics Report',
            type: 'Sales',
            code: 'ss',
        })

        this._registerField(new FromDateReportField())
        this._registerField(new ToDateReportReportField())
    }
}

export class BirthdayReport extends Report {
    static id = 'Birthday'
    constructor() {
        super({
            id: BirthdayReport.id,
            name: 'Birthday report',
            type: 'Marketing',
            code: 'b',
        })

        const fromDateReportField = new FromDateReportField()
        const toDateReportReportField = new ToDateReportReportField()
        const databaseTypeReportField = new DatabaseTypeReportField()
        const industryProfessionsField = new IndustryProfessionsField()
        const divisionField = new DivisionField()
        const clientStatusField = new ClientStatusField()

        this.addFieldModifier(industryProfessionsField.toggleModifier.bind(industryProfessionsField))
        this.addFieldModifier(divisionField.toggleModifier.bind(divisionField))

        this._registerField(fromDateReportField)
        this._registerField(toDateReportReportField)
        this._registerField(databaseTypeReportField)
        this._registerField(industryProfessionsField)
        this._registerField(divisionField)
        this._registerField(clientStatusField)
    }
}

export class HOBusinessContactReport extends Report {
    static id = 'HOBusinessContact'
    constructor() {
        super({
            id: HOBusinessContactReport.id,
            name: 'Head Office Business Contact Report',
            type: 'Sales',
            code: 'hobc',
        })

        const fromDateReport = new FromDateReportField()
        const toDateReportReport = new ToDateReportReportField()
        const industryProfessions = new IndustryProfessionsField()
        const clientStatus = new ClientStatusField()

        industryProfessions.required = true
        industryProfessions.show()
        industryProfessions.options = industryProfessionMapping.get(DATABASE_TYPES.CORP)

        this._registerField(fromDateReport)
        this._registerField(toDateReportReport)
        this._registerField(industryProfessions)
        this._registerField(clientStatus)
    }
}

export class B2PSalesContactReport extends Report {
    static id = 'B2PSalesContact'
    constructor() {
        super({
            id: B2PSalesContactReport.id,
            name: 'Business To Professional Sales Contact report',
            type: 'Sales',
            code: 'b2psc',
        })

        const fromDateReport = new FromDateReportField()
        const toDateReportReport = new ToDateReportReportField()
        const industryProfessions = new IndustryProfessionsField()
        const clientStatus = new ClientStatusField()

        industryProfessions.required = true
        industryProfessions.show()
        industryProfessions.options = industryProfessionMapping.get(DATABASE_TYPES.B2P)

        this._registerField(fromDateReport)
        this._registerField(toDateReportReport)
        this._registerField(industryProfessions)
        this._registerField(clientStatus)
    }
}

export class B2BSalesContactReport extends Report {
    static id = 'B2BSalesContact'
    constructor() {
        super({
            id: B2BSalesContactReport.id,
            name: 'Business To Business Sales Contact report',
            type: 'Sales',
            code: 'b2bsc',
        })

        const fromDateReport = new FromDateReportField()
        const toDateReportReport = new ToDateReportReportField()
        const industryProfessions = new IndustryProfessionsField()
        const clientStatus = new ClientStatusField()

        industryProfessions.required = true
        industryProfessions.show()
        industryProfessions.options = industryProfessionMapping.get(DATABASE_TYPES.B2B)

        this._registerField(fromDateReport)
        this._registerField(toDateReportReport)
        this._registerField(industryProfessions)
        this._registerField(clientStatus)
    }
}

export class ClientsStatusReport extends Report {
    static id = 'ClientsStatus'
    constructor() {
        super({
            id: ClientsStatusReport.id,
            name: 'Clients Status Report',
            type: 'Sales',
            code: 'cs'
        })

        const fromDateReportField = new FromDateReportField()
        const toDateReportReportField = new ToDateReportReportField()
        const divisionField = new DivisionField()
        const clientStatusField = new ClientStatusField()

        divisionField.required = true
        divisionField.show()

        this._registerField(fromDateReportField)
        this._registerField(toDateReportReportField)
        this._registerField(divisionField)
        this._registerField(clientStatusField)
    }
}

export class DataEnteredByUserReport extends Report {
    static id = 'DataEnteredByUser'
    constructor() {
        super({
            id: DataEnteredByUserReport.id,
            name: 'Data Entered by User report',
            type: 'Sales',
            code: 'dbu'
        })

        const fromDateReportField = new FromDateReportField()
        const toDateReportReportField = new ToDateReportReportField()
        const userReportField = new UserReportField()
        const databaseTypeReportField = new DatabaseTypeReportField()
        const industryProfessionsField = new IndustryProfessionsField()

        industryProfessionsField.hide()
        industryProfessionsField.required = true

        this.addFieldModifier(industryProfessionsField.toggleModifier.bind(industryProfessionsField))

        this._registerField(fromDateReportField)
        this._registerField(toDateReportReportField)
        this._registerField(userReportField)
        this._registerField(databaseTypeReportField)
        this._registerField(industryProfessionsField)
    }
}

export class FollowUpReport extends Report {
    static id = 'follow-up'
    constructor() {
        super({
            id: FollowUpReport.id,
            name: 'Follow Up',
            type: 'Marketing',
            code: 'fup',
        })

        const divisionField = new DivisionField()
        divisionField.shouldShow = true
        divisionField.required = true

        this._registerField(new FollowUpDateReportField())
        this._registerField(divisionField)
        this._registerField(new ClientStatusField())
        this._registerField(new AssignToReportField())
    }
}

export class LabelsReport extends Report {
    static id = 'Labels'
    constructor() {
        super({
            id: LabelsReport.id,
            name: 'Labels Report',
            type: 'Marketing',
            code: 'l',
        })

        const fromDateReportField = new FromDateReportField()
        const toDateReportReportField = new ToDateReportReportField()
        const databaseTypeReportField = new DatabaseTypeReportField()
        const industryProfessionsField = new IndustryProfessionsField()
        const divisionField = new DivisionField()
        const clientStatusField = new ClientStatusField()

        industryProfessionsField.required = true
        industryProfessionsField.hide()
        divisionField.required = true
        divisionField.hide()

        this._registerField(fromDateReportField)
        this._registerField(toDateReportReportField)
        this._registerField(databaseTypeReportField)
        this._registerField(industryProfessionsField)
        this._registerField(divisionField)
        this._registerField(clientStatusField)

        this.fieldModifiers.push(industryProfessionsField.toggleModifier.bind(industryProfessionsField))
        this.fieldModifiers.push(divisionField.toggleModifier.bind(divisionField))
    }
}

class Reports {
    constructor() {
        this.reports = []
        this.reportsMap = new Map()

        this.registerReport(new B2BSalesContactReport())
        this.registerReport(new B2PSalesContactReport())
        this.registerReport(new HOBusinessContactReport())
        this.registerReport(new SalesStatisticsReport())
        this.registerReport(new ClientsStatusReport())
        this.registerReport(new DataEnteredByUserReport())
        this.registerReport(new BirthdayReport())
        this.registerReport(new MarketingConsentReport())
        this.registerReport(new LabelsReport())
        this.registerReport(new FollowUpReport())
    }

    registerReport(report) {
        this.reports.push(report)
        this.reportsMap.set(report.id, report)
    }

    /**
     * 
     * @param {Number} id report id
     * @returns {Report}
     */
    getReport(id) {
        return this.reportsMap.get(id)
    }
}

export default new Reports()