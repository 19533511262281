import React, { createContext, useState } from 'react'
import EN_CA from './en-CA.json'
import FR_CA from './fr-CA.json'

const localeMapping = {
    'en-CA': EN_CA,
    'fr-CA': FR_CA
}

export const LocaleContext = createContext('en-CA')

export const LocaleProvider = ({ children }) => {
    const [language, setLanguage] = useState('en-CA')
    const getLocale = (key) => {
        return localeMapping[language][key] !== undefined ? localeMapping[language][key] : `__${key}`
    }
    const value = {
        language,
        setLanguage,
        locale: localeMapping[language],
        getLocale
    }
    return (
        <LocaleContext.Provider value={value}>
            {children}
        </LocaleContext.Provider>
    )
}
