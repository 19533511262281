import { STATUSES } from "../constants"
import { getClient } from "../services/api"

const FETCH_CLIENT_DETAIL_START = 'FETCH_CLIENT_DETAIL_START'
const FETCH_CLIENT_DETAIL_SUCCESS = 'FETCH_CLIENT_DETAIL_SUCCESS'
const FETCH_CLIENT_DETAIL_ERROR = 'FETCH_CLIENT_DETAIL_ERROR'

export const getClientDetailAction = (clientId) => async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_DETAIL_START })
    try {
        dispatch({
            type: FETCH_CLIENT_DETAIL_SUCCESS,
            data: await getClient(clientId)
        })
    } catch (error) {
        dispatch({ type: FETCH_CLIENT_DETAIL_ERROR })
    }
}

const initialState = {
    asyncStatus: null,
    id: 0,
    type: 0,
    status: 0,
    marketinConsent: 0,
    salutation: null,
    firstName: null,
    middleInitital: null,
    lastName: null,
    suffix: null,
    alsoKnownAs: null,
    dob: null,
    sex: 0,
    sin: 0,
    primaryEmail: null,
    secondaryEmail: null,
    streetNumber: null,
    unitNumber: null,
    streetName: null,
    city: null,
    province: null,
    country: null,
    postalCode: null,
    businessPhoneNumber: 0,
    businessPhoneExtension: 0,
    referredByPerson: null,
    comment: null,
    referredByMarketingOutlet: null,
    homePhoneNumber: 0,
    cellPhoneNumber: 0,
    faxNumber: 0,
    preferredContactMethod: null,
    bestTimeToCall: null,
    employerName: null,
    employerAddressStreetNumber: null,
    employerAddressUnitNumber: null,
    employerAddressStreetName: null,
    employerAddressCity: null,
    employerAddressProvince: null,
    employerAddressCountry: null,
    employerAddressPostalCode: null,
    employmentStartDate: null,
    occupancy: null,
    title: null,
    industry: null,
    industryProfession: null,
    maritalStatus: null,
    numberOfDependants: 0,
    childrenNames: null,
    driverLicenseNumber: null,
    otherIdentification: null,
    otherIdentificationNumber: null,
    spouseFirstName: null,
    spouseLastName: null,
    companyName: null,
    employmentType: null,
    createdDate: null,
    liabilities: [],
    assets: [],
    divisions: []
}

const clientDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENT_DETAIL_SUCCESS: {
            return {
                ...state,
                ...action.data,
                asyncStatus: STATUSES.SUCCESS
            }
        }
        case FETCH_CLIENT_DETAIL_ERROR: {
            return {
                ...state,
                asyncStatus: STATUSES.ERROR
            }
        }
        case FETCH_CLIENT_DETAIL_START: {
            return {
                ...state,
                ...initialState,
                asyncStatus: STATUSES.LOADING
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default clientDetailReducer
