import React, { Fragment } from 'react'
import { useClientNotesState, useClientNotesDispatch } from './client-notes-context'

const NotesAttachments = () => {
    const { attachments } = useClientNotesState()
    const clientNotesDispatch = useClientNotesDispatch()
    const removeAttachment = (index) => {
        clientNotesDispatch({
            type: 'removeAttachment',
            index
        })
    }
    return (
        <Fragment>
            <div className="container-fluid note-list-attachment-header">
                <b>Upload Attachments</b>
            </div>
            <div className="note-list-attachment-list">
                <table className="table table-condensed">
                    <tbody>
                        {attachments.map((attachment, index) => {
                            return (
                                <tr key={attachment.name}>
                                    <td>{attachment.name}</td>
                                    <td className="text-right">
                                        <span className="note-remove" onClick={(e) => {
                                            e.preventDefault()
                                            removeAttachment(index)
                                        }}>
                                            <i className="glyphicon glyphicon-remove"></i>
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default NotesAttachments