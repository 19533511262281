import React from 'react'

const tdStyle = {
    borderTop: 0,
    textAlign: 'center',
    padding: 0
}

const ClientLabelTab = ({
    client
}) => {
    return (
        <table className="table" style={{ position: 'absolute', top: '25%' }}>
            <tbody>
                <tr>
                    <td style={tdStyle}>{client.firstName}{' '}{client.middleInitial}{' '}{client.lastName}</td>
                </tr>
                {
                    client.companyName
                        ? (
                            <tr>
                                <td style={tdStyle}>{client.companyName}</td>
                            </tr>
                        )
                        : null
                }
                <tr>
                    <td style={tdStyle}>{client.unitNumber ? `${client.unitNumber}, ` : null}{client.streetNumber}{' '}{client.streetName}</td>
                </tr>
                <tr>
                    <td style={tdStyle}>{client.city}{', '}{client.province}{', '}{client.postalCode}</td>
                </tr>
                <tr>
                    <td style={tdStyle}>{client.country}</td>
                </tr>
            </tbody>
        </table>

    )
}

export default ClientLabelTab