import React from 'react'
import classnames from 'classnames'

const Table = ({
    name,
    headings = [],
    rows = [], // data structure = [{ columns: {children} }]
    isCondensed = false,
    isHover = true
}) => {
    return (
        <table
            className={
                classnames('table', isCondensed && 'table-condensed', isHover && 'table-hover')
            }>
            <thead>
                <tr>
                    {
                        headings.map(({ label, textAlign }) => {
                            return (
                                <th className={classnames(textAlign && `text-${textAlign}`)} key={label}>{label}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((row, rowIndex) => {
                        const rowKey = `${name}_row_${rowIndex}`
                        return (
                            <tr
                                key={rowKey}
                                className={classnames(row.props?.onClick && 'pointer')}
                                {...row.props}
                            >
                                {
                                    row.columns.map((col, colIndex) => {
                                        const colKey = `${rowKey}_col_${colIndex}`
                                        return <td key={colKey} {...col} />
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default Table