import { combineReducers } from 'redux'

import profile from './modules/profile'
import clientSearch from './modules/client-search'
import clientDetail from './modules/client-detail'
import clientDetailPage from './modules/client-detail-page'
import clientNotes from './modules/client-notes'

export default combineReducers({
    profile,
    clientSearch,
    clientDetail,
    clientDetailPage,
    clientNotes
})