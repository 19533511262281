import { searchClient } from '../services/api'
import { STATUSES } from '../constants'

const SEARCH_CLIENTS_START = 'SEARCH_CLIENTS_START'
const SEARCH_CLIENTS_SUCCESS = 'SEARCH_CLIENTS_SUCCESS'
const SEARCH_CLIENTS_ERROR = 'SEARCH_CLIENTS_ERROR'


export const searchAction = ({ params, page = 0, pageSize = 10 }) => async (dispatch) => {
    dispatch({
        type: SEARCH_CLIENTS_START
    })

    try {
        dispatch({
            type: SEARCH_CLIENTS_SUCCESS,
            data: await searchClient({ params, page, pageSize })
        })
    } catch (error) {
        dispatch({
            type: SEARCH_CLIENTS_ERROR
        })
    }
}

const initialState = {
    asyncStatus: STATUSES.NONE,
    data: [],
    page: 0,
    pageSize: 0,
    totalPages: 0
}

const clientSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CLIENTS_START: {
            return {
                ...state,
                asyncStatus: STATUSES.LOADING
            }
        }
        case SEARCH_CLIENTS_SUCCESS: {
            return {
                ...state,
                ...action.data,
                asyncStatus: STATUSES.SUCCESS,
            }
        }
        case SEARCH_CLIENTS_ERROR: {
            return {
                ...state,
                asyncStatus: STATUSES.ERROR,
            }
        }
        default: {
            return state
        }
    }
}

// selectors
export const selectClientList = ({ clientSearch }) => {
    return clientSearch.data
}


export default clientSearchReducer