const AUTH_TOKEN_KEY = 'token'
const REMEMBER_ME_KEY = 'loginEmail'

const del = (key) => {
    localStorage.removeItem(key)
}

const get = (key) => {
    return localStorage.getItem(key)
}

const set = (key, value) => {
    localStorage.setItem(key, value)
}

export const getAuthToken = () => {
    return get(AUTH_TOKEN_KEY)
}

export const setAuthToken = (token) => {
    set(AUTH_TOKEN_KEY, token)
}

export const removeAuthToken = () => {
    del(AUTH_TOKEN_KEY)
}

export const getRememberMe = () => {
    return get(REMEMBER_ME_KEY)
}

export const setRememberMe = (rememberMe) => {
    set(REMEMBER_ME_KEY, rememberMe)
}

export const removeRememberMe = () => {
    del(REMEMBER_ME_KEY)
}