/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import useLocale from '../../../../hooks/use-locale'
import { ROLES_MAP } from '../../../../constants'
import { useClientDetailContext } from '..'
import { setSelectedDivision, selectedDivisionIdSelector, setSelectedDivisionId } from '../../../../modules/client-notes'

const Divisions = () => {
    const dispatch = useDispatch()
    const { getLocale } = useLocale()
    const { client } = useClientDetailContext()
    const profile = useSelector(state => state.profile)
    const selectedDivisionId = useSelector(selectedDivisionIdSelector)

    return (
        <Fragment>
            <h5>{getLocale('client.divisions')}</h5>
            <div className="list-group">
                {
                    profile.roles.map((role) => {
                        return (
                            <a
                                key={role.roleID}
                                className={classnames(
                                    'list-group-item',
                                    selectedDivisionId === role.roleID && 'active'
                                )}
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    const clientDivision = client.divisions.find(c => c.divisionID === role.roleID)
                                    if (!clientDivision) {
                                        dispatch(setSelectedDivisionId(role.roleID))
                                    } else {
                                        dispatch(setSelectedDivision(clientDivision))
                                    }
                                    
                                }}
                            >
                                {getLocale(`client.role${ROLES_MAP[role.roleID]}`)}
                            </a>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

export default Divisions
