import React, { useState, useEffect } from 'react'
import { usePrevious } from '../hooks'

import './drop-down-menu.css'


const checkToCloseMenu = (closeFn) => (e) => {
    let target = e.target
    const className = 'drop-down-menu'
    if (target.classList.contains(className)) {
        return
    }

    // traverse upwards in the dom to check if the mouse click element is in the menu
    while (target.parentElement) {
        if (target.parentElement.classList.contains(className)) {
            return
        }

        if (target.parentElement.classList.contains('drop-down-menu__list')) {
            closeFn()
            return
        }

        target = target.parentElement
    }

    closeFn()
}

const DropDownMenu = ({
    label,
    options
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const prevShowMenu = usePrevious(showMenu)

    // attach listeners when menu is shown 
    useEffect(() => {
        const clickListener = checkToCloseMenu(() => {
            setShowMenu(false)
            window.removeEventListener('click', checkToCloseMenu)
        })
        if (!prevShowMenu && !!showMenu) {
            console.log('attach')
            window.addEventListener('click', clickListener)
        }
        if (!!prevShowMenu && !showMenu) {
            console.log('remove')
            window.removeEventListener('click', clickListener)
        }

        return () => {
            window.removeEventListener('click', clickListener)
        }
    }, [prevShowMenu, showMenu])

    // open menu when button is clicked
    const onMenuClick = (e) => {
        e.preventDefault()
        setShowMenu(!showMenu)
    }

    return (
        <div className="drop-down-menu">
            <button type="button" className="btn btn-sm btn-default" onClick={onMenuClick}>
                <span>{label}</span>
                <span className="caret"></span>
            </button>
            {
                showMenu && (
                    <div className="drop-down-menu__list">
                        <ul className="list-unstyled text-left drop-down-menu__list-content">
                            {
                                options.map((option) => {
                                    return (
                                        <li
                                            key={option.label}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                option.onClick()
                                            }}
                                            className="drop-down-menu__list-content__element"
                                            style={{ minWidth: 120 }}
                                        >
                                            {option.label}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    )
}

export default DropDownMenu
