import { formatOneDimentionalListToDropdownList, listWithBlank } from "./services/helpers"

export const STATUSES = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    NONE: 'NONE'
}

export const DATABASE_TYPES = {
    B2C: 0,
    CORP: 1,
    B2B: 2,
    B2P: 3,
    0: 'B2C',
    1: 'CORP',
    2: 'B2B',
    3: 'B2P'
}

export const DATABASE_TYPES_LIST = [{
    value: DATABASE_TYPES.B2C,
    label: 'B2C'
}, {
    value: DATABASE_TYPES.CORP,
    label: 'CORPORATION'
}, {
    value: DATABASE_TYPES.B2B,
    label: 'B2B'
}, {
    value: DATABASE_TYPES.B2P,
    label: 'B2P'
}]

export const INDUSTRY_PROFESSION_MAP = {
    [DATABASE_TYPES.B2P]: [
        'Accountants',
        'Car Salesmen',
        'Collection Agents',
        'Doctor ',
        'Financial Consultant',
        'Insurance Agents',
        'Lawyers (Criminal)',
        'Lawyers (Family & Divorce Law)',
        'Lawyers (Litigation)',
        'Lawyers (Real Estate)',
        'Mortgage Agents (National)',
        'Mortgage Agents (ON)',
        'Mortgage Agents MI/INVIS',
        'Private Lenders Individuals',
        'Real Estate Agents',
        'Other'
    ],
    [DATABASE_TYPES.B2B]: [
        'A Lenders',
        'Accounting Firms ',
        'Appraisal Companies ',
        'B Lenders',
        'Car Dealerships',
        'Collection Companies',
        'Commercial Lending Companies ',
        'Construction Companies',
        'Day Cares',
        'Insurance Brokerages (Life)',
        'Insurance Brokerages P&C)',
        'Investment Companies',
        'Law Firms',
        'Medical Office ',
        'Mortgage Brokerages',
        'Non Profit Organizations',
        'Private Lenders',
        'Property Management Companies',
        'Real Estate Brokerages',
        'School Boards',
        'Trustee Companies',
        'Other'
    ],
    [DATABASE_TYPES.CORP]: [
        'BMIC Department',
        'Credit Department',
        'Finance Department',
        'Lending Department',
        'Marketing (Distribution)',
        'Marketing (Printing)',
        'Marketing (Signage)',
        'Marketing (Swags)',
        'Marketing (Videos)',
        'Marketing (Website/E-Marketing)',
        'Mortgage Department',
        'Mortgage Sales Team',
        'Operations (Creditors)',
        'Property Management Department',
        'Real Estate Department',
        'TBGOC Professional Memberships',
        'Other'
    ]
}

export const INDUSTRY_PROFESSION_LIST_MAP = Object.entries(INDUSTRY_PROFESSION_MAP)
    .reduce((acc, [key, value]) => {
        const list = formatOneDimentionalListToDropdownList(value)
        acc[key] = listWithBlank(list)
        return acc
    }, {})

// deprecate
export const DATABASE_TYPES_NAME_MAP = {
    0: 'B2C',
    1: 'CORP',
    2: 'B2B',
    3: 'B2P'
}

export const CLIENT_CALL_STATUSES_MAP = {
    0: 'Active',
    1: 'DoNotCall',
    DoNotCall: 1,
    Active: 0
}

export const MARKETING_CONSENT_MAP = {
    3: 'InProcess',
    0: 'Explicit',
    1: 'Implied',
    2: 'None',
    InProcess: 3,
    Explicit: 0,
    Implied: 1,
    None: 2
}

export const MARKETING_CONSENT_LIST = [{
    value: MARKETING_CONSENT_MAP.InProcess,
    label: 'In Progress'
}, {
    value: MARKETING_CONSENT_MAP.Explicit,
    label: 'Explicit (Written, Email Consent, Website Form)'
}, {
    value: MARKETING_CONSENT_MAP.Implied,
    label: 'Implied (Verbal, Business Card, Marketing Material)'
}, {
    value: MARKETING_CONSENT_MAP.None,
    label: 'None'
}]

export const REFERRED_BY_MAP = {
    NatashaBridgmohan: 'Natasha Bridgmohan',
    Employee: 'Employee',
    RealEstateAgent: 'Real Estate Agent',
    TBGOCExistingClient: 'TBGOC Existing Client',
    MortgageAgent: 'Mortgage Agent',
    Other: 'Other'
}

export const PROFILE_ROLES = {
    ADMIN: 'ADMIN',
    USER: 'USER'
}

export const ROLES_MAP = {
    1: "ManagementAdmin",
    2: "Mortgages",
    3: "InvestmentsBMIC",
    4: "RealEstateAcquisitions",
    5: "LendingBMIC",
    6: "PropertyManagementInHouseProperties",
    7: "Consulting",
    8: "CorporateCreditors",
    9: "InvestmentsPFS",
    10: "InvestmentsPrivateLenders",
    11: "LendingPFS",
    12: "LendingPrivateLenders",
    13: "RealEstatePrivatePartners",
    14: "PropertyManagement3rdPartyServices",
    15: "CorporateReferralPartners",
    16: "CorporateEventsMarketingAndMeetings",
    17: "ManagementFinance",
    18: "ManagementHR",
    CorporateCreditors: 8,
    CorporateEventsMarketingAndMeetings: 16,
    CorporateReferralPartners: 15,
    Consulting: 7,
    InvestmentsBMIC: 3,
    InvestmentsPFS: 9,
    InvestmentsPrivateLenders: 10,
    LendingBMIC: 5,
    LendingPFS: 11,
    LendingPrivateLenders: 12,
    ManagementAdmin: 1,
    ManagementFinance: 17,
    ManagementHR: 18,
    Mortgages: 2,
    PropertyManagement3rdPartyServices: 14,
    PropertyManagementInHouseProperties: 6,
    RealEstateAcquisitions: 4,
    RealEstatePrivatePartners: 13
}

export const ROLES_ORDER_MAP = {
    [ROLES_MAP.Mortgages]: 1,
    [ROLES_MAP.InvestmentsBMIC]: 2,
    [ROLES_MAP.InvestmentsPFS]: 3,
    [ROLES_MAP.InvestmentsPrivateLenders]: 4,
    [ROLES_MAP.LendingBMIC]: 5,
    [ROLES_MAP.LendingPFS]: 6,
    [ROLES_MAP.LendingPrivateLenders]: 7,
    [ROLES_MAP.RealEstateAcquisitions]: 8,
    [ROLES_MAP.RealEstatePrivatePartners]: 9,
    [ROLES_MAP.Consulting]: 10, // consulting
    [ROLES_MAP.PropertyManagementInHouseProperties]: 11,
    [ROLES_MAP.PropertyManagement3rdPartyServices]: 12,
    [ROLES_MAP.CorporateCreditors]: 13,
    [ROLES_MAP.CorporateReferralPartners]: 14,
    [ROLES_MAP.CorporateEventsMarketingAndMeetings]: 15,
    [ROLES_MAP.ManagementFinance]: 16,
    [ROLES_MAP.ManagementHR]: 17,
    [ROLES_MAP.ManagementAdmin]: 18,
}

export const ROLES_ARRAY = [{
    name: 'Mortgages',
    id: 2,
    resource: 'Mortgages'
}, {
    name: 'Investments (BMIC)',
    id: 3,
    resource: 'InvestmentsBMIC'
}, {
    name: 'Investments (PFS)',
    id: 9,
    resource: 'InvestmentsPFS'
}, {
    name: 'Investments (Private Lenders)',
    id: 10,
    resource: 'InvestmentsPrivateLenders'
}, {
    name: 'Lending (BMIC)',
    id: 5,
    resource: 'LendingBMIC'
}, {
    name: 'Lending (PFS)',
    id: 11,
    resource: 'LendingPFS'
}, {
    name: 'Lending (Private Lenders)',
    id: 12,
    resource: 'LendingPrivateLenders'
}, {
    name: 'Real Estate (Acquisitions)',
    id: 4,
    resource: 'RealEstateAcquisitions'
}, {
    name: 'Real Estate (Private Partners)',
    id: 13,
    resource: 'RealEstatePrivatePartners'
}, {
    name: 'Consulting',
    id: 7,
    resource: 'Consulting'
}, {
    name: 'Property Management (In House Properties)',
    id: 6,
    resource: 'PropertyManagementInHouseProperties'
}, {
    name: 'Property Management (3rd Party Services)',
    id: 14,
    resource: 'PropertyManagement3rdPartyServices'
}, {
    name: 'Corporate (Creditors)',
    id: 8,
    resource: 'CorporateCreditors'
}, {
    name: 'Corporate (Referral Partners)',
    id: 15,
    resource: 'CorporateReferralPartners'
}, {
    name: 'Corporate (Events, Marketing & Meetings)',
    id: 16,
    resource: 'CorporateEventsMarketingAndMeetings'
}, {
    name: 'Management (Finance)',
    id: 17,
    resource: 'ManagementFinance'
}, {
    name: 'Management (HR)',
    id: 18,
    resource: 'ManagementHR'
}, {
    name: 'Management (Admin)',
    id: 1,
    resource: 'ManagementAdmin'
}]

export const NOTE_STATUS_LIST = [{
    id: 0,
    resource: 'Pending',
    active: true
}, {
    id: 1,
    resource: 'Lost',
    active: true
}, {
    id: 2,
    resource: 'Existing',
    active: true
}, {
    id: 3,
    resource: 'DoNotCall',
    active: false
}, {
    id: 4,
    resource: 'SoftInquiry',
    active: true
}, {
    id: 5,
    resource: 'Closing',
    active: true
}, {
    id: 6,
    resource: 'None',
    active: true
}]

export const USER_STATUSES = {
    active: {
        key: 0,
        resource: 'Active',
        resourceFallback: 'Active'
    },
    suspended: {
        key: 1,
        resource: 'Inactive',
        resourceFallback: 'Inactive'
    },
    pendingActivation: {
        key: 2,
        resource: 'PendingActivation',
        resourceFallback: 'Pending Activation'
    }
}

export const CALL_STATUSES = [{
    value: 0,
    label: 'Active'
}, {
    value: 1,
    label: 'DoNotCall'
}]

export const DATBASE_TYPE_REFERRED_BY_MARKETING_OUTLET = {
    [DATABASE_TYPES.B2C]: formatOneDimentionalListToDropdownList([
        'Another Professional',
        'B2P B2B MarketingMaterials',
        'BMIC Seminar',
        'Ballot Entry',
        'BridgGroup Networking Event',
        'Business Card',
        'Email Software',
        'Existing Referral Source',
        'Facebook',
        'Instagram',
        'LinkedIn',
        'MI Consent Forms',
        'Marketing Consent Forms',
        'Marketing Department',
        'Outside Networking Event',
        'Sign In Sheets',
        'TBGOC Commercial',
        'TBGOC Radio',
        'TV Show',
        'Twitter',
        'Website',
        'YouTube',
        'Other'
    ]),
    [DATABASE_TYPES.CORP]: formatOneDimentionalListToDropdownList([
        'Another Professional',
        'B2B B2P Marketing Materials',
        'BMIC Seminar',
        'Ballot Entry',
        'Data Entry',
        'Email Software',
        'Existing Business Partner',
        'Existing Referral Source',
        'Existing Service Provider',
        'Existing Trade',
        'Facebook',
        'Instagram',
        'Linked In',
        'MI Consent Form',
        'Marketing Consent Form',
        'Marketing Department',
        'Outside Networking Event',
        'Radio Commercial',
        'Sign in Sheets',
        'TBGOC Commercial',
        'TBGOC Networking Event',
        'TV Show',
        'Twitter',
        'Website',
        'YouTube',
        'Other'
    ]),
    [DATABASE_TYPES.B2P]: formatOneDimentionalListToDropdownList([
        'Another Professional',
        'B2B B2P Marketing Materials',
        'BMIC Seminar',
        'Ballot Entry',
        'Data Entry',
        'Email Software',
        'Existing Business Partner',
        'Existing Referral Source',
        'Existing Service Provider',
        'Existing Trade',
        'Facebook',
        'Instagram',
        'Linked In',
        'MI Consent Form',
        'Marketing Consent Form',
        'Marketing Department',
        'Outside Networking Event',
        'Radio Commercial',
        'Sign in Sheets',
        'TBGOC Commercial',
        'TBGOC Networking Event',
        'TV Show',
        'Twitter',
        'Website',
        'YouTube',
        'Other'
    ]),
    [DATABASE_TYPES.B2B]: formatOneDimentionalListToDropdownList([
        'Another Professional',
        'B2B B2P Marketing Materials',
        'BMIC Seminar',
        'Ballot Entry',
        'Data Entry',
        'Email Software',
        'Existing Business Partner',
        'Existing Referral Source',
        'Existing Service Provider',
        'Existing Trade',
        'Facebook',
        'Instagram',
        'Linked In',
        'MI Consent Form',
        'Marketing Consent Form',
        'Marketing Department',
        'Outside Networking Event',
        'Radio Commercial',
        'Sign in Sheets',
        'TBGOC Commercial',
        'TBGOC Networking Event',
        'TV Show',
        'Twitter',
        'Website',
        'YouTube',
        'Other'
    ])
}

export const REFERRED_BY_PERSON_LIST = formatOneDimentionalListToDropdownList([
    '',
    'Employee',
    'Mortgage Agent',
    'Natasha Bridgmohan',
    'Real Estate Agent',
    'TBGOC Existing Client',
    'Other'
])

export const SALUTATION_LIST = formatOneDimentionalListToDropdownList([
    '', 'Mr.', 'Mrs.', 'Dr.', 'Ms.', 'Rev.', 'Miss', 'Judge', 'Hon.'
])

export const GENDER_LIST = [
    { label: '', value: '' },
    { label: 'Male', value: 0 },
    { label: 'Female', value: 1 }
]

export const SUFFIX_LIST = [
    { label: '', value: '' },
    { label: 'Sr.', value: 'Sr.' },
    { label: 'Jr.', value: 'Jr.' }
]
export const EMPLOYMENT_TYPE_LIST = [
    { label: '', value: '' },
    { label: 'Commission Based', value: 'Commission Based' },
    { label: 'Employee', value: 'Employee' },
    { label: 'Self Employed', value: 'Self Employed' },
    { label: 'Other', value: 'Other' },
]

export const PREFERRED_CONTACT_METHOD_LIST = [
    { label: '', value: '' },
    { label: 'Cell Phone', value: 'Cell Phone' },
    { label: 'Email', value: 'Email' },
    { label: 'Fax', value: 'Fax' },
    { label: 'Home Phone', value: 'Home Phone' },
    { label: 'Work Phone', value: 'Work Phone' },
    { label: 'Other', value: 'Other' }
]

export const TIMES_TO_CALL_LIST = [
    { label: '', value: '' },
    { label: 'Day', value: 'Day' },
    { label: 'Evenings', value: 'Evenings' },
    { label: 'Weekends', value: 'Weekends' },
    { label: 'Other', value: 'Other' }
]

export const COUNTRIES_LIST = [
    { label: '', value: '' },
    { label: 'Canada', value: 'Canada' },
    { label: 'United States', value: 'United States' },
    { label: 'Other', value: 'Other' }
]

export const PROVINCE_LIST = [
    { value: '', label: '' },
    { value: 'AB', label: 'Alberta', country: 'CA' },
    { value: 'BC', label: 'British Columbia', country: 'CA' },
    { value: 'MB', label: 'Manitoba', country: 'CA' },
    { value: 'NB', label: 'New Brunswick', country: 'CA' },
    {
        value: 'NL',
        label: 'Newfoundland and Labrador',
        country: 'CA',
        alt: ['Newfoundland', 'Labrador']
    },
    { value: 'NS', label: 'Nova Scotia', country: 'CA' },
    { value: 'NU', label: 'Nunavut', country: 'CA' },
    { value: 'NT', label: 'Northwest Territories', country: 'CA' },
    { value: 'ON', label: 'Ontario', country: 'CA' },
    { value: 'PE', label: 'Prince Edward Island', country: 'CA' },
    { value: 'QC', label: 'Quebec', country: 'CA' },
    { value: 'SK', label: 'Saskatchewan', country: 'CA' },
    { value: 'YT', label: 'Yukon', country: 'CA' }
]

export const STATES_LIST = [
    { value: '', label: '' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FM', label: 'Federated States Of Micronesia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PW', label: 'Palau' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
]

export const OCCUPANCY_STATUS_LIST = [
    { label: '', value: '' },
    { label: 'Live With Parents', value: 'Live With Parents' },
    { label: 'Own', value: 'Own' },
    { label: 'Rent', value: 'Rent' },
    { label: 'Other', value: 'Other' },
]

export const INDUSTRIES_LIST = [
    { label: '', value: '' },
    { label: 'Banking/Finance', value: 'Banking/Finance' },
    { label: 'Construction', value: 'Construction' },
    { label: 'Education', value: 'Education' },
    { label: 'Farming/Natural Resources', value: 'Farming/Natural Resources' },
    { label: 'Government', value: 'Government' },
    { label: 'Health', value: 'Health' },
    { label: 'High-Tech', value: 'High-Tech' },
    { label: 'Leisure/Entertainment', value: 'Leisure/Entertainment' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Real Estate', value: 'Real Estate' },
    { label: 'Retail Sales', value: 'Retail Sales' },
    { label: 'Services', value: 'Services' },
    { label: 'Transportation', value: 'Transportation' },
    { label: 'Varies', value: 'Varies' },
    { label: 'Other', value: 'Other' },
]

export const ASSET_TYPES_LIST = [
    { label: '', value: '' },
    { label: 'Deposit on Purchase', value: 'Deposit on Purchase' },
    { label: 'Gift', value: 'Gift' },
    { label: 'Household Goods', value: 'Household Goods' },
    { label: 'Life Insurance', value: 'Life Insurance' },
    { label: 'RRSP', value: 'RRSP' },
    { label: 'Savings', value: 'Savings' },
    { label: 'Stocks/Bonds/Mutual', value: 'Stocks/Bonds/Mutual' },
    { label: 'Vehicle', value: 'Vehicle' },
    { label: 'Other', value: 'Other' },
]

export const LIABILITY_TYPES_LIST = [
    { label: '', value: '' },
    { label: 'Alimony', value: 'Alimony' },
    { label: 'Auto Loan', value: 'Auto Loan' },
    { label: 'Auto lease', value: 'Auto lease' },
    { label: 'Child Support', value: 'Child Support' },
    { label: 'Credit Card', value: 'Credit Card' },
    { label: 'Income tax', value: 'Income tax' },
    { label: 'Lease', value: 'Lease' },
    { label: 'Personal Loan', value: 'Personal Loan' },
    { label: 'Student Loan', value: 'Student Loan' },
    { label: 'Unsecured line of credit', value: 'Unsecured line of credit' },
    { label: 'Wage Garnishment', value: 'Wage Garnishment' },
    { label: 'Secured line of credit', value: 'Secured line of credit' },
    { label: 'Other', value: 'Other' },
]

export const PAYOFF_LIST = [
    { label: '', value: '' },
    { label: 'From proceeds', value: 'From proceeds' },
    { label: 'None', value: 'None' },
    { label: 'Prior to Advance', value: 'Prior to Advance' },
]

export const MARITAL_STATUS_LIST = [
    { label: '', value: '' },
    { label: 'Common Law', value: 'Common Law' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Married', value: 'Married' },
    { label: 'Separated', value: 'Separated' },
    { label: 'Single', value: 'Single' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Other', value: 'Other' },
]