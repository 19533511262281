import React, { useContext } from 'react'
import { LocaleContext } from '../../../../locales'
import { formatCurrency } from '../../../../services/formatters'

const ClientAssetTab = ({
    client
}) => {
    const { getLocale } = useContext(LocaleContext)
    let sum = 0
    return (
        <table className="table">
            <tbody>
                <tr>
                    <th>
                        {getLocale('client.type')}
                    </th>
                    <th>
                        {getLocale('client.description')}
                    </th>
                    <th className="text-right">
                        {getLocale('client.value')}
                    </th>
                </tr>
                {
                    client.assets.map((asset) => {
                        sum += asset.value
                        return (
                            <tr key={asset.description}>
                                <td>{asset.type}</td>
                                <td>{asset.description}</td>
                                <td className="text-right">{formatCurrency(asset.value)}</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td colSpan="2">
                        <b>{getLocale('client.summary')}</b>
                    </td>
                    <td className="text-right">
                        <b>{formatCurrency(sum)}</b>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ClientAssetTab