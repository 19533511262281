import React, { useState, useMemo, Fragment, useEffect, useCallback } from 'react'
import useLocale from '../../hooks/use-locale'
import { Row, Col, Button, SubmitButton } from '../../components/bootstrap'
import FormRenderer from '../../components/form-renderer'
import { DATABASE_TYPES, ROLES_ARRAY } from '../../constants'
import { GENERAL_FORM_FIELDS, PERSONAL_INFO_FORM_FIELDS, CONTACT_INFORMATION_FIELDS, ASSETS, LIABILITIES, MAILING_INFORMATION_FIELDS, EMPLOYMENT_INFO_FIELDS, ASSETS_FORM_FIELDS, LIABILITIES_FORM_FIELDS } from './edit-config'
import DatePicker from '../../components/date-picker'
import { differenceInYears, differenceInMonths } from 'date-fns'
import { formatCurrency } from '../../services/formatters'
import { useParams, useHistory } from 'react-router-dom'
import { useAsync } from '../../hooks'
import { getClient, saveClient, searchClient } from '../../services/api'
import { TimerPrompt } from '../../components/common'

const isSinValid = (sin) => {
    let i
    let k
    let tmp
    const result = []
    const sinConst = [1, 2, 1, 2, 1, 2, 1, 2, 1]
    const cleanSin = sin.match(/\d/g).join('')

    // make sure it is 9 digit number
    if (cleanSin.length !== 9) {
        return false
    }

    for (i = 0, k = sin.length; i < k; i++) {

        // multiply with sin constant of same string pos
        tmp = cleanSin[i] * sinConst[i]

        // if double digits, then add the two numbers together
        if (tmp > 9) {
            tmp = tmp.toString().split('').reduce(function (previousValue, strDigit) {
                previousValue += parseInt(strDigit)
                return previousValue
            }, 0);
        }

        //add results to array
        result.push(tmp)
    }

    // sum results and make sure it is divisible by 10
    return result.reduce((prev, current) => {
        const currParsed = parseInt(current);
        prev += isNaN(currParsed) ? 0 : currParsed;
        return prev;
    }) % 10 === 0
}

const SINInput = ({
    onFieldChange,
    field,
    value
}) => {
    const { getLocale } = useLocale()
    const [validSin, setValidSin] = useState()
    return (
        <Row>
            <Col sm={9}>
                <input
                    className="form-control"
                    type="text"
                    value={value}
                    onChange={(e) => onFieldChange({ field, value: e.target.value })}
                    onBlur={(e) => {
                        e.preventDefault()
                        onFieldChange({ field, value: field.format(e.target.value) })
                    }}
                />
            </Col>
            <Col sm={3}>
                {
                    validSin === undefined && (
                        <Button
                            className="btn btn-sm btn-default"
                            onClick={() => {
                                if (!isSinValid(value)) {
                                    alert(getLocale('invalidSINMessage'))
                                } else {
                                    setValidSin(true)
                                }
                            }}
                        >
                            {getLocale('Validate')}
                        </Button>
                    )
                }
                {validSin === true && (
                    <div className="form-control-static text-success">{getLocale('ValidSIN')}</div>
                )}
            </Col>
        </Row>
    )
}

const DOBInput = ({
    field,
    onFieldChange,
    value
}) => {
    const { getLocale } = useLocale()
    const today = useMemo(() => new Date(), [])
    const [age, setAge] = useState()
    return (
        <Row>
            <Col sm={9}>
                <DatePicker
                    defaultDate={value}
                    onChange={([date]) => {
                        onFieldChange({ field, value: date })
                        setAge(differenceInYears(today, date))
                    }}
                />
            </Col>
            <Col sm={3}>
                {
                    age > 0 && (
                        <div className="help-block">
                            {age} {getLocale('years')}
                        </div>
                    )
                }
            </Col>
        </Row>
    )
}

const WorkingHereSinceInput = ({
    field,
    onFieldChange,
    value
}) => {
    const { getLocale } = useLocale()
    const today = useMemo(() => new Date(), [])
    const [years, setYears] = useState()
    const [months, setMonths] = useState()
    return (
        <Row>
            <Col sm={7}>
                <DatePicker
                    defaultDate={value}
                    onChange={([date]) => {
                        onFieldChange({ field, value: date })
                        const diffMonths = differenceInMonths(today, date)
                        setYears(Math.floor(diffMonths / 12))
                        setMonths(diffMonths % 12)
                    }}
                />
            </Col>
            <Col sm={5}>
                {
                    (years > 0 || months > 0) && (
                        <div className="help-block">
                            {years > 0 && `${years} ${getLocale(years > 1 ? 'years' : 'years')}`}
                            {' '}
                            {months > 0 && `${months} ${getLocale(months > 1 ? 'months' : 'month')}`}
                        </div>
                    )
                }
            </Col>
        </Row>
    )
}

const CUSTOM_FIELD_MAPPING = {
    'sin': SINInput,
    'dob': DOBInput
}

const AssetLiabilityWidget = ({
    onRemoveClick,
    items,
    itemTitle,
    onFieldChange,
    onAddClick,
    addDescription,
    formFields,
    noItemsMessage,
    totalDescription
}) => {
    const { getLocale } = useLocale()
    return (
        <Fragment>
            {
                items.length > 0 && (<Fragment>
                    {
                        items.map((item, index) => {
                            return (
                                <div key={`item_${index}`}>
                                    <div className="form-group">
                                        <h4 className="col-sm-9 col-sm-offset-3">
                                            {itemTitle} {index + 1}
                                            <small>
                                                <button
                                                    type="button"
                                                    className="btn btn-link"
                                                    onClick={() => onRemoveClick(index)}
                                                >
                                                    {getLocale('Remove')}
                                                </button>
                                            </small>
                                        </h4>
                                    </div>
                                    <FormRenderer
                                        type="horizontal"
                                        fields={formFields}
                                        values={item}
                                        onFieldChange={({ field, value }) => onFieldChange({ field, value, item })}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-3 col-sm-offset-3">
                                {totalDescription}
                            </div>
                        </div>
                    </div>
                </Fragment>)
            }
            {
                items.length === 0 && (
                    <div className="row">
                        <div className="text-muted col-sm-5 col-sm-offset-3">
                            {noItemsMessage}
                        </div>
                    </div>
                )
            }
            <div className="row row-gutter">
                <div className="col-sm-1 col-sm-offset-3" >
                    <button
                        type="button"
                        className="btn btn-default btn-sm"
                        onClick={onAddClick}
                    >
                        {addDescription}
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

const TotalDisplay = ({
    description,
    total
}) => {
    return (
        <Fragment>
            <strong>{description}:</strong>
            {' '}
            <span>{formatCurrency(total)}</span>
        </Fragment>
    )
}

const Assets = ({ values, setValues }) => {
    const { getLocale } = useLocale()
    const total = values.assets.reduce((acc, asset) => {
        return acc + asset.value
    }, 0)

    const totalDescription = <TotalDisplay
        total={total}
        description={getLocale('TotalAssets')}
    />

    return (
        <Fragment>
            <Row>
                <Col sm={5} smOffset={3}>
                    <h3 >
                        {getLocale('Assets')}
                    </h3>
                </Col>
            </Row>
            <hr />
            <Row>
                <AssetLiabilityWidget
                    itemTitle={getLocale('Asset')}
                    addDescription={getLocale('AddAsset')}
                    items={values.assets}
                    totalDescription={totalDescription}
                    formFields={ASSETS_FORM_FIELDS}
                    noItemsMessage={getLocale('NoAssets')}
                    onRemoveClick={(index) => {
                        const assetsCopy = [...values.assets]
                        assetsCopy.splice(index, 1)
                        setValues({
                            ...values,
                            assets: assetsCopy
                        })
                    }}
                    onFieldChange={({ field, value, item }) => {
                        item[field.key] = value
                        setValues({ ...values })
                    }}
                    onAddClick={() => {
                        setValues({
                            ...values,
                            assets: [
                                ...values.assets,
                                ASSETS_FORM_FIELDS.reduce((acc, { key }) => {
                                    acc[key] = ''
                                    return acc
                                }, {})
                            ]
                        })
                    }}
                />
            </Row>
        </Fragment>
    )
}

const Liabilities = ({ values, setValues }) => {
    const { getLocale } = useLocale()
    const total = values.liabilities.reduce((acc, liability) => {
        return acc + liability.balance
    }, 0)

    const totalDescription = <TotalDisplay
        total={total}
        description={getLocale('TotalLiabilities')}
    />
    return (
        <Fragment>
            <Row>
                <Col sm={5} smOffset={3}>
                    <h3 >
                        {getLocale('Liabilities')}
                    </h3>
                </Col>
            </Row>
            <hr />
            <Row>
                <AssetLiabilityWidget
                    itemTitle={getLocale('Liability')}
                    addDescription={getLocale('AddLiability')}
                    items={values.liabilities}
                    totalDescription={totalDescription}
                    formFields={LIABILITIES_FORM_FIELDS}
                    noItemsMessage={getLocale('NoLiabilities')}
                    onRemoveClick={(index) => {
                        const liabilityCopy = [...values.liabilities]
                        liabilityCopy.splice(index, 1)
                        setValues({
                            ...values,
                            liabilities: liabilityCopy
                        })
                    }}
                    onFieldChange={({ field, value, item }) => {
                        item[field.key] = value
                        setValues({ ...values })
                    }}
                    onAddClick={() => {
                        setValues({
                            ...values,
                            liabilities: [
                                ...values.liabilities,
                                LIABILITIES_FORM_FIELDS.reduce((acc, { key }) => {
                                    acc[key] = ''
                                    return acc
                                }, {})
                            ]
                        })
                    }}
                />
            </Row>
        </Fragment>
    )
}

const EmploymentInfo = ({ values, handleFormChange }) => {
    const { getLocale } = useLocale()
    return (
        <Fragment>
            <Row>
                <Col sm={5} smOffset={3}>
                    <h3 >
                        {getLocale('EmploymentInfo')}
                    </h3>
                </Col>
            </Row>
            <hr />
            <FormRenderer
                customRenderers={{ 'employmentStartDate': WorkingHereSinceInput }}
                type="horizontal"
                fields={EMPLOYMENT_INFO_FIELDS}
                values={values}
                onFieldChange={handleFormChange}
            />
        </Fragment>
    )
}

const GeneralInformation = ({ values, handleFormChange }) => {
    const { getLocale } = useLocale()
    return (
        <Fragment>
            <div id="General" className="client-edit-hidden-anchor"></div>
            <Row>
                <Col sm={5} smOffset={3}>
                    <h3>
                        {getLocale('GeneralInformation')}
                    </h3>
                </Col>
            </Row>
            <hr />
            <FormRenderer
                type="horizontal"
                fields={GENERAL_FORM_FIELDS}
                values={values}
                onFieldChange={handleFormChange}
            />
        </Fragment>
    )
}

const PersonalInformation = ({ values, handleFormChange, setValues }) => {
    const { getLocale } = useLocale()
    return (
        <Fragment>
            <Row>
                <Col sm={5} smOffset={3}>
                    <h3>
                        {getLocale('PersonalInformation')}
                    </h3>
                </Col>
            </Row>
            <hr />
            <FormRenderer
                customRenderers={CUSTOM_FIELD_MAPPING}
                type="horizontal"
                fields={PERSONAL_INFO_FORM_FIELDS}
                values={values}
                onFieldChange={handleFormChange}
            />
        </Fragment>
    )
}

const ContactInformation = ({ values, handleFormChange, setValues }) => {
    const { getLocale } = useLocale()
    return (
        <Fragment>
            <Row>
                <Col sm={5} smOffset={3}>
                    <h3>
                        {getLocale('ContactInformation')}
                    </h3>
                </Col>
            </Row>
            <hr />
            <FormRenderer
                type="horizontal"
                fields={CONTACT_INFORMATION_FIELDS}
                values={values}
                onFieldChange={handleFormChange}
            />
            <div className="form-group">
                <Row>
                    <Col sm={9} smOffset={3}>
                        <h4 >
                            {getLocale('MailingAddress')}
                        </h4>
                    </Col>
                </Row>
            </div>
            <FormRenderer
                type="horizontal"
                fields={MAILING_INFORMATION_FIELDS}
                values={values}
                onFieldChange={handleFormChange}
            />
        </Fragment>
    )
}

const ALL_FIELDS = [
    ...GENERAL_FORM_FIELDS,
    ...PERSONAL_INFO_FORM_FIELDS,
    ...CONTACT_INFORMATION_FIELDS,
    ...MAILING_INFORMATION_FIELDS,
    ...EMPLOYMENT_INFO_FIELDS,
    ...ASSETS,
    ...LIABILITIES
]

const getInitialValues = () => {
    const values = ALL_FIELDS
        .reduce((acc, { key, valueType }) => {
            if (key === 'createdDate') {
                acc[key] = new Date()
                return acc
            }

            if (valueType === 'array') {
                acc[key] = []
                return acc
            }

            acc[key] = ''

            return acc
        }, {})
    return values
}

const EditClient = () => {
    const { id } = useParams()
    const fieldsWithValidation = useMemo(() => ALL_FIELDS.filter((f) => typeof (f.isValid) === 'function'), [])
    const [values, setValues] = useState()
    const { execute, data } = useAsync(getClient)
    const history = useHistory()
    const { getLocale } = useLocale()
    const [duplicateUser, setDuplicateUser] = useState()
    const searchAndSaveClient = useCallback(async (client) => {
        if (!client.id) {
            setDuplicateUser(false)
            const searchResults = await searchClient({
                params: {
                    firstName: client.firstName,
                    lastName: client.lastName,
                    email: client.primaryEmail
                }
            })

            if (searchResults.data.length > 0) {
                setDuplicateUser(true)
                return
            }
        }

        const response = await saveClient(client)
        return response
    }, [])
    const {
        execute: executeSaveClient,
        isSucess: saveClientSuccess,
        data: newClient
    } = useAsync(searchAndSaveClient)

    useEffect(() => {
        if (id !== undefined) {
            execute(id)
        }

        if (id === undefined) {
            setValues({ ...getInitialValues() })
        }
    }, [id, execute])

    useEffect(() => {
        if (id === undefined || data === undefined) {
            return
        }

        // initialize the values from the response into the state
        // ensure that we parse the values to display properly on the ui
        const parsedValues = ALL_FIELDS.reduce((acc, field) => {
            const { parseFromPayload } = field
            if (typeof (parseFromPayload) === 'function') {
                acc[field.key] = parseFromPayload(acc[field.key])
            } else {
                acc[field.key] = acc[field.key] || ''
            }
            return acc
        }, { ...data })

        // second pass to assign all the drop down that have 'Other' 
        // selected and assign it to its own text field
        ALL_FIELDS.forEach((field) => {
            if (field.type === 'dropdown') {
                const options = field.options && typeof (field.options) === 'function' ? field.options(parsedValues) : field.options
                const includedInOptions = options.some(o => o.value === parsedValues[field.key])
                const otherKey = `${field.key}Other`
                if (includedInOptions === false && parsedValues[otherKey] !== undefined) {
                    parsedValues[otherKey] = parsedValues[field.key]
                    parsedValues[field.key] = 'Other'
                }
            }
        })
        setValues(parsedValues)
    }, [data, id])

    useEffect(() => {
        if (duplicateUser === true) {
            alert(getLocale('duplicateClientMessage'))
        }
    }, [duplicateUser, getLocale])

    useEffect(() => {
        if (!saveClientSuccess) {
            return
        }
        history.push(`/dashboard/client/${id || newClient.id}/detail/general`)
    }, [saveClientSuccess, id, history, newClient])

    const handleFormChange = ({ field, value }) => {
        setValues({
            ...values,
            [field.key]: value
        })
    }

    // B2C clients show different information in different orders!!
    const orderedComponents = useMemo(() => {
        if (!values) {
            return []
        }

        if (values.type === DATABASE_TYPES.B2C.toString()) {
            return [
                GeneralInformation,
                PersonalInformation,
                ContactInformation,
                EmploymentInfo,
                Assets,
                Liabilities
            ]
        }
        return [
            GeneralInformation,
            PersonalInformation,
            ContactInformation
        ]
    }, [values])

    if (!values) {
        return null
    }

    return (
        <Fragment>
            <TimerPrompt message={getLocale('editPagePrompt')} />
            <div className="row-gutter-lg">
                <form>
                    <div>
                        {
                            orderedComponents.map((Section, index) => {
                                return <Section
                                    key={`section_${index}`}
                                    values={values}
                                    handleFormChange={handleFormChange}
                                    setValues={setValues}
                                />
                            })
                        }
                    </div>
                    <div className="row row-gutter">
                        <Col sm={5} smOffset={3}>
                            <SubmitButton
                                disabled={fieldsWithValidation.some((field) => {
                                    const { shouldShow, required, isValid } = field
                                    const calcShouldShow = typeof (shouldShow) === 'function' ? shouldShow(values) : shouldShow
                                    return calcShouldShow === true && required === true && !isValid(values[field.key])
                                })}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (!window.confirm(getLocale('userUpdateSaveWarning'))) {
                                        return
                                    }
                                    const payload = ALL_FIELDS.reduce((acc, field) => {
                                        // do not need to process other fields
                                        if (field.key.endsWith('Other')) {
                                            return acc
                                        }

                                        const { parseForPayload, shouldShow } = field
                                        const calcShouldShow = typeof (shouldShow) === 'function' ? shouldShow(values) : shouldShow
                                        let value = values[field.key]

                                        // set to empty if not showing on screen
                                        if (calcShouldShow === false) {
                                            value = ''
                                        }

                                        // need to handle all the drop downs that have the 'Other' field selected
                                        if (field.type === 'dropdown' && value === 'Other') {
                                            const otherFieldKey = `${field.key}Other`
                                            value = values[otherFieldKey]
                                        }

                                        // parseForPayload can be used to convert from string to 
                                        // whatever the api expects
                                        if (parseForPayload && typeof (parseForPayload) === 'function') {
                                            acc[field.key] = parseForPayload(value)
                                            return acc
                                        }
                                        acc[field.key] = value === '' ? null : value
                                        return acc
                                    }, {})

                                    // if it is a new client, add default divisions from list
                                    if (id === undefined) {
                                        payload.divisions = ROLES_ARRAY.map((role) => {
                                            return {
                                                id: null,
                                                divisionId: role.id,
                                                status: {},
                                                followUpDate: null,
                                                assignedToId: null
                                            }
                                        })
                                    }

                                    executeSaveClient({
                                        ...payload,
                                        id: id || null
                                    })
                                }}
                            />
                        </Col>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default EditClient
