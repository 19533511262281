import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getAuthToken } from '../services/local-storage'

const PrivateRoute = ({ children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const authToken = getAuthToken()
                if (!authToken) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
                }

                return children
            }}
        />
    )
}

export default PrivateRoute