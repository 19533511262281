import React, { useReducer } from 'react'

import { createContext, useContext } from "react"

const ClientNotesStateContext = createContext()
const ClientNotesDispatchContext = createContext()

// client notes 
const clientNotesReducer = (state, action) => {
    switch (action.type) {
        case 'addFiles': {
            return {
                ...state,
                attachments: [
                    ...state.attachments,
                    ...action.attachments
                ]
            }
        }
        case 'removeAttachment': {
            const copy = [...state.attachments]
            copy.splice(action.index, 1)
            return {
                ...state,
                attachments: copy
            }
        }
        case 'clearAttachments': {
            return {
                ...state,
                attachments: []
            }
        }
        default: {
            throw new Error('Invalid action for notes reducer')
        }
    }
}



export const useClientNotesState = () => {
    const context = useContext(ClientNotesStateContext)
    if (context === undefined) {
        throw new Error('ClientNotesStateContext not available')
    }
    return context
}

export const useClientNotesDispatch = () => {
    const context = useContext(ClientNotesDispatchContext)
    if (context === undefined) {
        throw new Error('ClientNotesDispatchContext not available')
    }
    return context
}

export const ClientNotesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(clientNotesReducer, {
        selectedDivisionId: null,
        selectedClientDivision: null,
        attachments: [],
        notes: []
    })

    return (
        <ClientNotesStateContext.Provider value={state}>
            <ClientNotesDispatchContext.Provider value={dispatch}>
                {children}
            </ClientNotesDispatchContext.Provider>
        </ClientNotesStateContext.Provider>
    )
}
