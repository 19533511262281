import { useSelector } from "react-redux"

import { useEffect, useState, useRef, useCallback } from "react"
import {
    searchUsers,
    getClient,
    queryReport,
    getAssignToList,
} from "./services/api"
import { STATUSES, ROLES_MAP } from "./constants"
import useLocale from "./hooks/use-locale"

export const useProfile = () => {
    const profile = useSelector(state => state.profile)
    const { getLocale } = useLocale()
    return {
        profile,
        rolesDropdownList: profile.roles.map(r => ({
            value: r.roleID,
            label: getLocale(`client.role${ROLES_MAP[r.roleID]}`)
        }))
    }
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useClient = (id) => {
    const [asyncStatus, setAsyncStatus] = useState(null)
    const [client, setClient] = useState(null)

    const fetchClient = useCallback(async () => {
        try {
            setClient(await getClient(id))
        } catch (error) {
            console.log('error fetching client')
        }
    }, [id])

    // only set async status on inital client load. 
    // this prevents the page from jumping to the top after a note has been added 
    // and the client is refetched because of the async status
    useEffect(() => {
        const init = async () => {
            try {
                setAsyncStatus(STATUSES.LOADING)
                await fetchClient()
                setAsyncStatus(STATUSES.SUCCESS)
            } catch (e) {
                setAsyncStatus(STATUSES.ERROR)
            }
        }
        init()
    }, [fetchClient])

    return {
        fetchClient,
        client,
        asyncStatus
    }
}

export const useReport = ({
    uid,
    itemsPerPage = 10,
    type
}) => {
    const getReport = async ({
        page,
        payload
    }) => {
        const json = await queryReport({
            params: {
                uid,
                itemsPerPage,
                type,
                page
            },
            payload
        })
        return json
    }

    const getPrint = async ({ payload }) => {
        return queryReport({
            params: {
                uid,
                itemsPerPage,
                type,
                raw: 1,
                option: 'print'
            },
            payload
        })
    }

    const getFile = async ({
        format,
        payload
    }) => {
        return queryReport({
            params: {
                uid,
                itemsPerPage,
                type,
                saveFormat: format,
                option: 'save',
            },
            payload
        })
    }

    return {
        getReport,
        getPrint,
        getFile
    }
}

export const useAssignedTo = () => {
    const [assignTos, setAssignTos] = useState([])
    const [assignToList, setAssignToList] = useState([])
    const [asyncStatus, setAsyncStatus] = useState()
    useEffect(() => {
        const fetchAssignToList = async () => {
            setAsyncStatus(STATUSES.LOADING)
            const assignedTos = await getAssignToList()
            const listMap = assignedTos.map(a => {
                return {
                    value: a.id,
                    label: a.name
                }
            })
            setAssignTos(assignedTos)
            setAssignToList(listMap)
            setAsyncStatus(STATUSES.SUCCESS)
        }

        fetchAssignToList()
    }, [])

    return {
        assignTos,
        assignToList, // used for dropdrop downs
        asyncStatus
    }
}

export const useUsers = ({
    pageSize = 100,
    page = 0
} = {}) => {
    const [users, setUsers] = useState([])
    const [params, setParams] = useState({})
    const [userDropdownList, setUserDropDownList] = useState([])
    const [asyncStatus, setAsyncStatus] = useState()
    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        const fetchUsers = async () => {
            setAsyncStatus(STATUSES.LOADING)
            try {
                const {
                    data,
                    totalPages
                } = await searchUsers({
                    params,
                    pageSize,
                    page
                })
                setUsers(data)
                setTotalPages(totalPages)
                setUserDropDownList(data.map(u => ({ value: u.id, label: `${u.firstName} ${u.lastName}` })))
                setAsyncStatus(STATUSES.SUCCESS)
            } catch (e) {
                console.log(e)
                setAsyncStatus(STATUSES.ERROR)
            }
        }
        fetchUsers()
    }, [
        pageSize,
        page,
        params
    ])

    return {
        users,
        totalPages,
        userDropdownList,
        asyncStatus,
        setParams
    }
}

export const useAsync = (asyncFn) => {
    const [data, setData] = useState()
    const [asyncStatus, setAsyncStatus] = useState(null)
    const [isSucess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const execute = useCallback(async (...params) => {
        setAsyncStatus(STATUSES.LOADING)
        setIsLoading(true)
        setIsSuccess(false)
        setIsError(false)
        try {
            const data = await asyncFn(...params)
            setData(data)
            setAsyncStatus(STATUSES.SUCCESS)
            setIsError(false)
            setIsSuccess(true)
        } catch (e) {
            setAsyncStatus(STATUSES.ERROR)
            setIsSuccess(false)
            setIsError(true)
        } finally {
            setIsLoading(false)
        }
    }, [asyncFn])
    return {
        data,
        asyncStatus,
        isSucess,
        isError,
        isLoading,
        execute
    }
}

export const useMount = (fn) => {
    const ref = useRef()
    ref.current = fn
    const cachedFn = useCallback((...args) => {
        return ref.current(...args)
    }, [])
    useEffect(() => {
        cachedFn()
    }, [cachedFn])
}