import { getProfile } from '../services/api'
import { STATUSES, ROLES_ORDER_MAP, ROLES_MAP } from '../constants'

const GET_PROFILE_START = 'GET_PROFILE_START'
const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'


export const getProfileAction = () => async (dispatch) => {
    dispatch({
        type: GET_PROFILE_START
    })
    try {
        const profile = await getProfile()

        // need to sort roles
        profile.roles = profile
            .roles
            .reduce((acc, role) => {
                role.order = ROLES_ORDER_MAP[role.roleID]
                role.name = ROLES_MAP[role.roleID]
                acc.push(role)
                return acc
            }, [])
            .sort((a, b) => a.order - b.order)

        dispatch({
            type: GET_PROFILE_SUCCESS,
            data: profile
        })
    } catch (error) {
        dispatch({
            type: GET_PROFILE_ERROR
        })
    }
}

const initialState = {
    asyncStatus: null,
    attributes: [],
    email: '',
    firstName: '',
    id: -1,
    lastFailedLoginAttempt: '',
    lastLogin: '',
    lastName: '',
    roles: [],
    status: -1,
    uid: '',
    username: '',
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE_START: {
            return {
                ...state,
                asyncStatus: STATUSES.LOADING
            }
        }
        case GET_PROFILE_SUCCESS: {
            return {
                ...state,
                ...action.data,
                asyncStatus: STATUSES.SUCCESS,
            }
        }
        case GET_PROFILE_ERROR: {
            return {
                ...state,
                asyncStatus: STATUSES.ERROR,
            }
        }
        default: {
            return state
        }
    }
}

export default userReducer