import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { LocaleContext } from '../../locales'
import { ROLES_MAP } from '../../constants'

const ProfileMetaData = () => {
    const { getLocale } = useContext(LocaleContext)
    const profile = useSelector(state => state.profile)
    return (
        <div className="text-muted dashboard-user-info margin-top-lg">
            <ul className="list-unstyled">
                <li>
                    {getLocale('dashboard.user')}
                    {': '}
                    {profile.firstName} {profile.lastName}
                </li>
                <li>
                    {getLocale('dashboard.roles')}
                    {': '}
                    {profile.roles.map(r => getLocale(`client.role${ROLES_MAP[r.roleID]}`)).join(', ')}
                </li>
                <li>
                    {getLocale('dashboard.lastSignedOn')}
                    {': '}
                    {profile.lastLogin.toString()}
                </li>
                <li>
                    {getLocale('dashboard.lastFailedSignOn')}
                    {': '}
                    {profile.lastFailedLoginAttempt.toString()}
                </li>
            </ul>
        </div>
    )
}

export default ProfileMetaData
