import React, { memo, useRef, useEffect } from 'react'
import flatpickr from 'flatpickr'
import { useMount } from '../hooks'

const DatePicker = memo(({
    defaultDate,
    name,
    onChange,
    disabled,
    value,
    dataTestId
}) => {
    const element = useRef()
    const picker = useRef()
    useMount(() => {
        picker.current = flatpickr(element.current, {
            dateFormat: "m-d-Y",
            defaultDate,
            onChange: onChange
        })
    })

    // this is needed to update the onchange function when react rerenders
    useEffect(() => {
        picker.current.config.onChange[0] = onChange
    }, [onChange])

    useEffect(() => {
        picker.current.setDate(value)
    }, [value])

    return <input data-test-id={dataTestId} ref={element} type="text" disabled={disabled} className="form-control" name={name} />
})

export default DatePicker