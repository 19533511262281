import React, { useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { LocaleContext } from '../../../locales'
import { DATABASE_TYPES_NAME_MAP, CLIENT_CALL_STATUSES_MAP, ROLES_MAP } from '../../../constants'
import DropDownMenu from '../../../components/drop-down-menu'
import { deleteClient } from '../../../services/api'
import { useClientDetailContext } from '.'


const ClientDetailHeader = () => {
    const { id } = useParams()
    const { getLocale } = useContext(LocaleContext)
    const { client } = useClientDetailContext()
    const roles = useSelector(s => s.profile.roles)
    const history = useHistory()

    const getDropDownOptions = () => {
        const options = []
        options.push({
            label: getLocale('client.editProfile'),
            onClick: () => {
                history.push(`/dashboard/client/${id}/edit`)
            }
        })

        if (roles.some(r => r.roleID === ROLES_MAP.ManagementAdmin)) {
            options.push({
                label: getLocale('client.deleteProfile'),
                onClick: async () => {
                    if (!window.confirm(getLocale('client.deleteClientWarning'))) {
                        return;
                    }

                    try {
                        await deleteClient(id)
                        history.push('/dashboard/client/search')
                    } catch (error) {
                        alert(getLocale('client.deleteClientError'))
                    }
                }
            })
        }

        return options
    }
    return (
        <div className="page-header container">
            <div className="display--flex align-items--center">
                <h1
                    className="client-header"
                    style={{
                        marginTop: 10,
                        marginRight: 10
                    }}
                >
                    {client.salutation}
                    {client.firstName}
                    {' '}
                    {client.middleInitital}
                    {' '}
                    {client.lastName}
                    {client.suffix ? ` ${client.suffix}` : ''}
                </h1>
                <div className="text-right" style={{ flex: 1 }}>
                    <DropDownMenu
                        label={getLocale('general.options')}
                        options={getDropDownOptions()}
                    />
                </div>
            </div>

            {
                client.alsoKnownAs && (
                    <div style={{ border: 'none' }}>
                        <div className="display--flex align-items--center">
                            <h4 className="client-header" style={{ marginTop: 0 }}>
                                {client.alsoKnownAs}
                            </h4>
                        </div>
                    </div>
                )
            }

            <div>
                <strong>{getLocale('client.databaseType')}</strong>
                {getLocale(`client.databaseType_${DATABASE_TYPES_NAME_MAP[client.type]}`)}
            </div>
            <div>
                <strong>{getLocale('client.callStatus')}</strong>
                {getLocale(`client.status${CLIENT_CALL_STATUSES_MAP[client.status]}`)}
            </div>
        </div>
    )
}

export default ClientDetailHeader
