import React, { useContext } from 'react'

import { LocaleContext } from '../../../../locales'
import { DATABASE_TYPES } from '../../../../constants'
import TwoColumnTabPanel from './two-column-tab-panel'
import { formatDateStringFromUTCToLocal } from '../../../../services/formatters'

const PersonalTab = ({ client }) => {
    const { getLocale } = useContext(LocaleContext)

    const items = [
        {
            shouldDisplay: true,
            label: 'client.AKA',
            value: client.alsoKnownAs
        },
        {
            shouldDisplay: true,
            label: 'client.salutations',
            value: client.salutation
        },
        {
            shouldDisplay: true,
            label: 'client.gender',
            value: getLocale(`general.sex_${client.sex}`)
        },
        {
            shouldDisplay: true,
            label: 'client.DOB',
            value: formatDateStringFromUTCToLocal(client.dob)
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.maritalStatus',
            value: client.maritalStatus
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.spouseName',
            value: `${client.spouseFirstName} ${client.spouseLastName}`
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.numberOfDependants',
            value: client.numberOfDependants
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.childrenNames',
            value: client.childrenNames
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.SIN',
            value: client.sin
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.driversLicense',
            value: client.driverLicenseNumber
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.otherIdentification',
            value: client.otherIdentification
        },
        {
            shouldDisplay: client.type === DATABASE_TYPES.B2C,
            label: 'client.otherIdentificationNumber',
            value: client.otherIdentificationNumber
        },
    ]

    return (
        <TwoColumnTabPanel items={items} />
    )
}

export default PersonalTab
