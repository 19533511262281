import React, { useState, useEffect, useMemo, useRef, Fragment, useCallback, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Quill from 'quill'

import Divisions from './divisions'
import NotesStatusSection from './status-section'
import ClientNotesDisplaySection from './display-section'
import useLocale from '../../../../hooks/use-locale'
import FileDrop from '../../../../components/file-drop'
import NotesAttachments from './attachments'
import { useProfile, useMount, useReport, usePrevious } from '../../../../hooks'
import Modal from '../../../../components/modal'
import { DivisionStatusSelector, MarketingConsentSelector } from '../../../../components/drop-down-selector'
import { getValueOrDefaultToEmpty } from './helpers'
import {
    saveClient,
    updateFollowUp,
    createFollowUp
} from '../../../../services/api'
import DatePicker from '../../../../components/date-picker'
import { ClientDetailContext, useClientDetailContext } from '..'

import './notes.css'
import 'quill/dist/quill.snow.css'
import { ClientNotesProvider, useClientNotesDispatch, useClientNotesState } from './client-notes-context'
import { TimerPrompt } from '../../../../components/common'
import Table from '../../../../components/table'
import Pagination from '../../../../components/pagination'
import { setSelectedDivision, saveNote, selectedDivisionIdSelector, selectedDivisionSelector } from '../../../../modules/client-notes'
import { STATUSES } from '../../../../constants'
import { useLocation } from 'react-router-dom'

const getQuillInstance = (container) => {
    return new Quill(container.current, {
        modules: {
            toolbar: [
                [{ 'font': [] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ 'color': [] }]
            ]
        },
        placeholder: 'Enter note...',
        theme: 'snow' // or 'bubble'
    })
}

const NotesHistoryReportSection = () => {
    const { client } = useClientDetailContext()
    const selectedDivisionId = useSelector(selectedDivisionIdSelector)
    const today = useMemo(() => new Date(), [])
    const { getLocale } = useLocale()
    const { profile } = useProfile()
    const [page, setPage] = useState(1)
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [json, setJson] = useState()
    const {
        getReport,
        getPrint,
        getFile
    } = useReport({
        uid: profile.id,
        type: 'dn'
    })
    const memoSetFromDate = useCallback(([date]) => setFromDate(date), [setFromDate])
    const memoSetToDate = useCallback(([date]) => setToDate(date), [setToDate])

    const payload = {
        divisionID: parseInt(selectedDivisionId),
        clientID: parseInt(client.id),
        startDate: fromDate,
        endDate: toDate
    }

    const saveReport = (format) => async () => {
        const reportWindow = window.open('', 'reportWindow')
        const data = await getFile({ format, payload })
        reportWindow.location.replace(`${process.env.REACT_APP_API_URL}/report?file=${data.file}`)
        setTimeout(() => {
            reportWindow.close()
        }, 300)
    }

    return (
        <Fragment>
            <h5>{getLocale('client.notesHistoryReport')}</h5>
            <div className="notes-container">
                <div className="division-status">
                    <form onSubmit={async (e) => {
                        e.preventDefault()
                        const json = await getReport({
                            page,
                            payload
                        })
                        setJson(json)
                        // fetchReport({ pageInput: page })
                    }}>
                        <div className="form-group">
                            <label>{getLocale('client.fromDate')}</label>
                            <DatePicker defaultDate={today} name="fromDate" onChange={memoSetFromDate} />
                        </div>
                        <div className="form-group">
                            <label>{getLocale('client.toDate')}</label>
                            <DatePicker defaultDate={today} name="toDate" onChange={memoSetToDate} />
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-primary"
                            >
                                {getLocale('Search')}
                            </button>
                        </div>
                    </form>
                    {
                        json && <div>
                            <ul className="list-inline">
                                <li>
                                    <button
                                        type="button"
                                        className="btn btn-default btn-sm"
                                        onClick={async () => {
                                            const printWindow = window.open('', 'printWindow');
                                            const html = await getPrint({ payload })
                                            printWindow.document.write(html);
                                            setTimeout(() => {
                                                printWindow.print()
                                            }, 500);
                                        }}
                                    >
                                        {getLocale('print')}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="btn btn-default btn-sm"
                                        onClick={saveReport('pdf')}
                                    >
                                        {getLocale('PDF')}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="btn btn-default btn-sm"
                                        onClick={saveReport('excel')}
                                    >
                                        {getLocale('Excel')}
                                    </button>
                                </li>
                            </ul>
                            <Table
                                headings={json.Report.Headers.map(h => ({ label: h }))}
                                rows={json.Report.Data.map(r => ({
                                    columns: r.map(c => ({ children: c }))
                                }))}
                            />
                            {
                                json.Paginator.Pages.length > 1 && <Pagination
                                    totalPages={json.Paginator.Pages.length}
                                    currentPage={page}
                                    offset={1}
                                    pageFn={async (pageIndex) => {
                                        const json = await getReport({
                                            page: pageIndex,
                                            payload
                                        })
                                        setJson(json)
                                        setPage(pageIndex)
                                    }}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

const ClientNotesSection = () => {
    const editorContainer = useRef(null)
    const editor = useRef(null)
    const dispatch = useDispatch()

    const location = useLocation()
    const { client, fetchClient } = useContext(ClientDetailContext)
    const clientNotesDispatch = useClientNotesDispatch()
    const selectedClientDivision = useSelector(selectedDivisionSelector)
    const selectedDivisionId = useSelector(selectedDivisionIdSelector)
    const { attachments } = useClientNotesState()

    const [note, setNote] = useState('')
    const [showSaveCommentModal, setShowSaveCommentModel] = useState(false)
    const [newNoteMarketingConsent, setnewNoteMarketingConsent] = useState(client.marketinConsent)
    const [newNoteDivisionStatus, setNewNoteDivisionStatus] = useState(getValueOrDefaultToEmpty(selectedClientDivision?.status))

    const profile = useSelector(state => state.profile)
    const { getLocale } = useLocale()

    // reset note when client section changes
    useEffect(() => {
        setNote('')
        if (editor.current) {
            editor.current.setText('')
        }
    }, [location.pathname])

    useMount(() => {
        const profileDivisionId = profile.roles[0].roleID
        const clientDivision = client.divisions.find(c => c.divisionID === profileDivisionId)
        dispatch(setSelectedDivision(clientDivision))
    })

    useEffect(() => {
        setNewNoteDivisionStatus(getValueOrDefaultToEmpty(selectedClientDivision?.status))
    }, [selectedClientDivision])

    useEffect(() => {
        if (!editorContainer.current) return
        const instance = getQuillInstance(editorContainer)
        editor.current = instance
        editor.current.on('text-change', (delta, oldDelta, source) => {
            if (source === 'user') {
                setNote(editor.current.root.innerHTML)
            }
        })
    }, [editorContainer])

    /**
     * handle actions when note saves successfully
     */
    const saveNoteAsyncStatus = useSelector(state => state.clientNotes.saveNoteAsyncStatus)
    const previousSaveNoteAsyncStatus = usePrevious(saveNoteAsyncStatus)
    useEffect(() => {
        if (previousSaveNoteAsyncStatus === STATUSES.LOADING && saveNoteAsyncStatus === STATUSES.SUCCESS) {
            clientNotesDispatch({ type: 'clearAttachments' })
            setShowSaveCommentModel(false)
            editor.current.setText('')
            fetchClient()
        }
    }, [saveNoteAsyncStatus, previousSaveNoteAsyncStatus, clientNotesDispatch, fetchClient])

    const onSubmit = (e) => {
        e.preventDefault()
        setNote(editor.current.root.innerHTML)
        setShowSaveCommentModel(true)
    }

    const saveNewNote = async (e) => {
        e.preventDefault()
        let clientDivisionId = selectedClientDivision?.id
        try {
            if (selectedClientDivision && selectedClientDivision.status !== newNoteDivisionStatus) {
                if (clientDivisionId) {
                    await updateFollowUp({
                        entryId: clientDivisionId,
                        status: newNoteDivisionStatus,
                        clientId: client.id,
                        assignmentId: selectedClientDivision.assignedToID,
                        followUpDate: selectedClientDivision.followUpDate
                    })
                } else {
                    const response = await createFollowUp({
                        status: newNoteDivisionStatus,
                        clientId: client.id,
                        divisionId: selectedDivisionId,
                    })
                    clientDivisionId = response.id
                }
            }

            if (client.marketinConsent !== newNoteMarketingConsent) {
                client.marketinConsent = parseInt(newNoteMarketingConsent)
                await saveClient(client)
            }

            dispatch(saveNote({ clientId: client.id, note, attachments, divisionId: selectedDivisionId }))
        } catch (error) {
            console.log('Error saving followup.', error)
        }
    }

    const addAttachments = (files) => {
        clientNotesDispatch({
            type: 'addFiles',
            attachments: Array.from(files)
        })
    }

    return (
        <Fragment>
            <TimerPrompt
                when={!!note && note.length > 0}
                message={getLocale('editNotesPrompt')}
            />
            <div className="row">
                <div className="col-md-3">
                    <Divisions />
                </div>
                <div className="col-md-9">
                    <h5>{getLocale('client.notes')}</h5>
                    <div className="notes-container">
                        <NotesStatusSection />
                        <FileDrop onDrop={addAttachments}>
                            <ClientNotesDisplaySection hasAttachments={attachments.length > 0} />
                            {attachments.length > 0 && <NotesAttachments />}
                            <form className="note-input" onSubmit={onSubmit}>
                                <div className="form-group">
                                    <div ref={editorContainer} style={{ height: 300 }} />
                                </div>
                                <div className="text-right">
                                    <button className="btn btn-primary btn-sm" type="submit">
                                        {getLocale('Save')}
                                    </button>
                                </div>
                            </form>
                        </FileDrop>
                    </div>
                    <NotesHistoryReportSection />
                </div>
            </div>
            <Modal
                isOpen={showSaveCommentModal}
                onRequestClose={() => setShowSaveCommentModel(false)}
                shouldCloseOnOverlayClick={true}
                title="Save note"
                onCancel={() => setShowSaveCommentModel(false)}
                onConfirm={saveNewNote}
                isLoading={saveNoteAsyncStatus === STATUSES.LOADING}
            >
                <Fragment>
                    <div className="form-group">
                        <label>Division Status</label>
                        <DivisionStatusSelector
                            value={newNoteDivisionStatus}
                            onChange={
                                (e) => {
                                    e.preventDefault()
                                    setNewNoteDivisionStatus(e.target.value)
                                }
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label>Marketing Consent</label>
                        <MarketingConsentSelector
                            value={newNoteMarketingConsent}
                            onChange={
                                (e) => {
                                    e.preventDefault()
                                    setnewNoteMarketingConsent(e.target.value)
                                }
                            }
                        />
                    </div>
                    <label>Note</label>
                    <p dangerouslySetInnerHTML={{ __html: note }}></p>
                </Fragment>
            </Modal>
        </Fragment>
    )
}

const WrappedClientNotesSection = () => {
    return <ClientNotesProvider>
        <ClientNotesSection />
    </ClientNotesProvider>
}


export default WrappedClientNotesSection