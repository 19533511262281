import React, { useContext } from 'react'
import { LocaleContext } from '../../../../locales'

const TwoColumnTabPanel = ({
    items
}) => {
    const { getLocale } = useContext(LocaleContext)
    return (
        <table className="table two-column-table">
            <tbody>
                {
                    items
                        .filter(item => item.shouldDisplay === true)
                        .map(({ label, value }) => {
                            return (
                                <tr key={label}>
                                    <th>{getLocale(label)}</th>
                                    <td>{value}</td>
                                </tr>
                            )
                        })
                }
            </tbody>
        </table>
    )
}

export default TwoColumnTabPanel