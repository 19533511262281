import { format, parse } from 'date-fns'

const currencyFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format

export const formatPhoneNumber = (number) => {
    const numberStr = number.toString()

    if (numberStr.length !== 10) {
        return ''
    }

    const clean = numberStr.replace(/[^0-9]+/g, '')
    const groups = /(\d{3})(\d{3})(\d{4})/.exec(clean)
    return `(${groups[1]}) ${groups[2]}-${groups[3]}`
}

export const formatClientName = (client) => {
    return client.firstName + (client.middleInitital ? ' ' + client.middleInitital : '') + ' ' + client.lastName;
}

// server responds with UTC date but when you create a new date in JS, it converts it to local time.
// we need to use the UTC date that the server provided so when we create the date object, we need
// to add/remove the time difference back to the local time to get the UTC date.
export const formatDateStringFromUTCToLocal = (date, dateFormat = 'MM-dd-yyyy') => {
    const dateObj = new Date(date)
    dateObj.setTime(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000)
    return format(dateObj, dateFormat)
}

export const formatDate = (date, dateFormat = 'MM-dd-yyyy') => {
    const dateObj = new Date(date)
    return format(dateObj, dateFormat)
}

export const parseDate = (dateString, format = 'MM-dd-yyyy') => {
    const date = parse(dateString, format, new Date())
    return date
}

export const formatCurrency = (amount) => {
    return currencyFormatter(amount)
}