import React from 'react'

import TwoColumnTabPanel from './two-column-tab-panel'
import { formatDate } from '../../../../services/formatters'

const EmploymentTab = ({ client }) => {
    const generalItems = [
        {
            shouldDisplay: true,
            label: 'client.employerName',
            value: client.employerName
        },
        {
            shouldDisplay: true,
            label: 'client.jobTitle',
            value: client.title
        },
        {
            shouldDisplay: true,
            label: 'client.industry',
            value: client.industry
        },
        {
            shouldDisplay: true,
            label: 'client.startDate',
            value: formatDate(client.employmentStartDate)
        },
        {
            shouldDisplay: true,
            label: 'client.employerAddress',
            value: [
                client.employerAddressStreetNumber,
                client.employerAddressStreetName,
                client.employerAddressCity,
                client.employerAddressCountry,
                client.employerAddressPostalCode
            ].join(' ')
        },
    ]

    return (
        <TwoColumnTabPanel items={generalItems} />
    )
}

export default EmploymentTab
