import React from 'react'
import loader from '../images/loader.gif'
import { STATUSES } from '../constants'

import { Box } from './bootstrap'

const Loading = ({
    isLoading,
    asyncStatus,
    children,
    isInline = false
}) => {
    if (isLoading || [undefined, null, STATUSES.LOADING].includes(asyncStatus)) {
        return <Box verticalPadding="md" alignment="center" isInline={isInline}>
            <img src={loader} alt="loading" style={{ width: 20 }} />
        </Box>
    }

    return children
}

export default Loading
