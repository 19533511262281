import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const Pagination = ({
    totalPages,
    currentPage,
    offset = 0,
    pageFn
}) => {
    const history = useHistory()
    const { search } = useLocation()
    const ary = new Array(totalPages)
    ary.fill(null)

    return (
        <nav className="text-center">
            <ul className="pagination">
                {
                    ary.map((item, index) => {
                        return (
                            <li
                                key={`page_${index + 1}`}
                                className={index === currentPage - offset ? 'active' : null}
                            >
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (pageFn) {
                                            pageFn(index + 1)
                                            return
                                        }
                                        const query = new URLSearchParams(search)
                                        query[query.has('page') ? 'set' : 'append']('page', index + offset)
                                        history.push({ search: query.toString() })
                                    }}>
                                    {index + 1}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    )
}

export default Pagination