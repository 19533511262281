import React from 'react'
import classnames from 'classnames'

import './bootstrap.css'

export const Panel = ({ header, variant = 'default', children }) => {
    return (
        <div className={`panel panel-${variant}`}>
            <div className="panel-heading">
                {header}
            </div>
            <div className="panel-body">
                {children}
            </div>
        </div>
    )
}

export const SubmitButton = ({
    variant = 'primary',
    value,
    size,
    ...rest
}) => {
    return <input
        type="submit"
        className={
            classnames(
                `btn btn-${variant}`,
                size && `btn-${size}`
            )
        }
        value={value}
        {...rest}
    />
}

export const Button = ({
    variant = 'default',
    children,
    ...rest
}) => {
    return (
        <button
            type="button"
            className={`btn btn-${variant}`}
            {...rest}
        >
            {children}
        </button>
    )
}

export const Row = ({ children }) => {
    return (
        <div className="row">
            {children}
        </div>
    )
}

export const Col = ({
    children,
    xs,
    sm,
    md,
    lg,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    textAlign
}) => {
    const xsProp = `col-xs-${xs}`
    const smProp = `col-sm-${sm}`
    const mdProp = `col-md-${md}`
    const lgProp = `col-lg-${lg}`
    const xsOffsetProp = `col-xs-offset-${xsOffset}`
    const smOffsetProp = `col-sm-offset-${smOffset}`
    const mdOffsetProp = `col-md-offset-${mdOffset}`
    const lgOffsetProp = `col-lg-offset-${lgOffset}`

    return (
        <div
            className={classnames({
                [xsProp]: !!xs,
                [mdProp]: !!md,
                [smProp]: !!sm,
                [lgProp]: !!lg,
                [xsOffsetProp]: !!xsOffset,
                [smOffsetProp]: !!smOffset,
                [mdOffsetProp]: !!mdOffset,
                [lgOffsetProp]: !!lgOffset,
                'text-right': textAlign === 'right'
            })}
        >
            {children}
        </div>
    )
}

export const Container = ({ children }) => {
    return (
        <div className="container">
            {children}
        </div>
    )
}

export const Box = ({
    children,
    verticalPadding,
    alignment,
    isInline
}) => {
    const classes = classnames(
        'box',
        verticalPadding && `box--vertical-padding-${verticalPadding}`,
        alignment && `box--alignment-${alignment}`,
        isInline && 'box--inline'
    )
    return <div className={classes}>
        {children}
    </div>
}

export const Label = ({
    variant = 'default',
    children
}) => {
    const classes = classnames(
        'label',
        variant && `label-${variant}`
    )
    return (
        <span className={classes}>
            {children}
        </span>
    )
}

export const Alert = ({
    variant = 'default',
    children
}) => {
    const classes = classnames(
        'alert',
        variant && `alert-${variant}`
    )
    return (
        <div className={classes}>
            {children}
        </div>
    )
} 