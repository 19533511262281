import React, { useContext } from 'react'
import PartnerToggle from './partner-toggle'

import { LocaleContext } from '../../../../locales'
import { Link, useRouteMatch, Switch, Redirect, useLocation } from 'react-router-dom'
import PrivateRoute from '../../../../components/private-route'
import GeneralTab from './general-tab'
import ClientContactTab from './contact-tab'
import PersonalTab from './personal-tab'
import EmploymentTab from './employment-tab'
import ClientLabelTab from './label-tab'
import ClientAssetTab from './assets-tab'
import { DATABASE_TYPES } from '../../../../constants'
import ClientLiabilitiesTab from './liabilities-tab'
import { useClientDetailContext } from '..'
import { useSelector } from 'react-redux'
import { selectedClientIdSelector } from '../../../../modules/client-detail-page'

const ClientOverViewSection = () => {
    const { client } = useClientDetailContext()
    const selectedClientId = useSelector(selectedClientIdSelector)
    const { getLocale } = useContext(LocaleContext)
    const { url } = useRouteMatch()
    const { pathname } = useLocation()
    const selectedClient = selectedClientId === client.id ? client : client.partner

    const navItems = [{
        show: true,
        labelKey: 'client.info.generalLabel',
        path: `${url}/general`
    }, {
        show: true,
        labelKey: 'client.info.contactLabel',
        path: `${url}/contact`
    }, {
        show: true,
        labelKey: 'client.info.personalLabel',
        path: `${url}/personal`
    }, {
        show: selectedClient.type === DATABASE_TYPES.B2C,
        labelKey: 'client.info.employmentLabel',
        path: `${url}/employment`
    }, {
        show: selectedClient.type === DATABASE_TYPES.B2C,
        labelKey: 'client.info.assetsLabel',
        path: `${url}/assets`
    }, {
        show: selectedClient.type === DATABASE_TYPES.B2C,
        labelKey: 'client.info.liabilitiesLabel',
        path: `${url}/liabilities`
    }, {
        show: true,
        labelKey: 'client.info.labelLabel',
        path: `${url}/label`
    }]

    return (
        <div>
            <PartnerToggle client={client} />
            <div className="row margin-top-lg">
                <div className="col-sm-3">
                    <div className="list-group">
                        {
                            navItems.map(item => {
                                if (!item.show) {
                                    return null
                                }
                                return (
                                    <Link
                                        key={item.labelKey}
                                        className={`list-group-item ${item.path === pathname ? 'active' : null}`}
                                        href="#"
                                        to={item.path}
                                    >
                                        {getLocale(item.labelKey)}
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="panel panel-default client-detail-info-display">
                        <Switch>
                            <PrivateRoute path={`${url}/general`}>
                                <GeneralTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute path={`${url}/contact`}>
                                <ClientContactTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute path={`${url}/personal`}>
                                <PersonalTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute path={`${url}/employment`}>
                                <EmploymentTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute path={`${url}/assets`}>
                                <ClientAssetTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute path={`${url}/liabilities`}>
                                <ClientLiabilitiesTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute path={`${url}/label`}>
                                <ClientLabelTab client={selectedClient} />
                            </PrivateRoute>
                            <PrivateRoute>
                                <Redirect to={`${url}/general`} />
                            </PrivateRoute>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientOverViewSection