import React, { useEffect, useState, useCallback } from 'react'

import { saveFollowUp, getAssignToList, updateFollowUp } from '../../../../services/api'
import DropDownSelector, { DivisionStatusSelector } from '../../../../components/drop-down-selector'
import DatePicker from '../../../../components/date-picker'
import { useAsync } from '../../../../hooks'
import useLocale from '../../../../hooks/use-locale'
import { SubmitButton } from '../../../../components/bootstrap'
import { useClientDetailContext } from '..'
import { getValueOrDefaultToEmpty } from './helpers'
import { STATUSES } from '../../../../constants'
import { useSelector, useDispatch } from 'react-redux'
import { selectedDivisionIdSelector, selectedDivisionSelector, setSelectedDivision, setSelectedDivisionId } from '../../../../modules/client-notes'

const NotesStatusSection = () => {
    const dispatch = useDispatch()
    const selectedDivisionId = useSelector(selectedDivisionIdSelector)
    const selectedClientDivision = useSelector(selectedDivisionSelector)
    const { client, fetchClient, asyncStatus } = useClientDetailContext()
    const [status, setStatus] = useState(selectedClientDivision?.status)
    const [assignedTo, setAssignedTo] = useState(selectedClientDivision?.assignedToID)
    const [followupDate, setFollowupDate] = useState(selectedClientDivision?.followUpDate)
    const { getLocale } = useLocale()
    const fetchAssignToList = useCallback(async () => {
        const response = await getAssignToList()
        return response.map(a => {
            return {
                value: a.id,
                label: a.name
            }
        })
    }, [])
    const {
        execute: executeGetAssignToList,
        data: assignToList
    } = useAsync(fetchAssignToList)
    const {
        execute: executeSaveFollowUp,
        isLoading: saveFollowUpLoading,
        isError: saveFollowUpError,
        isSucess: saveFollowUpSuccess
    } = useAsync(saveFollowUp)

    const {
        execute: executeUpdateFollowUp,
        isSucess: updateFollowUpSuccess
    } = useAsync(updateFollowUp)

    useEffect(() => {
        executeGetAssignToList()
    }, [executeGetAssignToList])

    // update form values on division change
    useEffect(() => {
        setStatus(getValueOrDefaultToEmpty(selectedClientDivision?.status))
        setAssignedTo(getValueOrDefaultToEmpty(selectedClientDivision?.assignedToID))
        setFollowupDate(getValueOrDefaultToEmpty(selectedClientDivision?.followUpDate))
    }, [selectedClientDivision])

    useEffect(() => {
        if (saveFollowUpSuccess || updateFollowUpSuccess) {
            fetchClient()
        }
    }, [fetchClient, updateFollowUpSuccess, saveFollowUpSuccess])

    useEffect(() => {
        // if client is loaded successfully, then reset the division
        // this is to handle the case where a division is created 
        // and the user wants to update it right after.  The bug is
        // caused by the client refresh but the selected division
        // does not get updated with the newly created division id
        // which then causes a post request when the user saves right
        // after the creation
        if (asyncStatus === STATUSES.SUCCESS) {
            const selectedDivision = client.divisions.find(d => d.divisionID === selectedDivisionId)
            if (!selectedDivision) {
                dispatch(setSelectedDivisionId(selectedDivisionId))
            } else {
                dispatch(setSelectedDivision(selectedDivision))
            }
        }
    }, [client, dispatch, selectedDivisionId, asyncStatus])

    const onStatusSubmit = (e) => {
        e.preventDefault()
        executeSaveFollowUp({
            status,
            followUpDate: followupDate ? followupDate : undefined,
            assignmentId: assignedTo,
            divisionId: selectedDivisionId,
            entryId: selectedClientDivision ? selectedClientDivision.id : undefined,
            clientId: client.id
        })
    }

    const deleteDivisionStatus = (e) => {
        e.preventDefault()
        if (!window.confirm('Are you sure you want to reset the status?')) {
            return
        }

        executeUpdateFollowUp({
            status: null,
            followUpDate: null,
            assignmentId: null,
            entryId: selectedClientDivision.id,
            clientId: client.id
        })

        setStatus('')
        setAssignedTo('')
        setFollowupDate('')
    }

    return (
        <div className="division-status">
            <form className="form-horizontal" onSubmit={onStatusSubmit}>
                <div className="form-group">
                    <label className="col-sm-2 control-label">{getLocale('Status')}</label>
                    <div className="col-sm-10">
                        <DivisionStatusSelector
                            onChange={(e) => {
                                e.preventDefault()
                                setStatus(e.target.value)
                            }}
                            value={status}
                            size="sm"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">{getLocale('AssignTo')}</label>
                    <div className="col-sm-10">
                        <DropDownSelector
                            size="sm"
                            value={assignedTo}
                            options={assignToList}
                            onChange={(e) => {
                                e.preventDefault()
                                setAssignedTo(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">{getLocale('FollowUpDate')}</label>
                    <div className="col-sm-10">
                        <DatePicker
                            name="followupDate"
                            defaultDate={followupDate || ''}
                            value={followupDate}
                            onChange={([date]) => {
                                setFollowupDate(date)
                            }}
                        />
                    </div>
                </div>
                <div className="text-right">
                    {saveFollowUpLoading && <span className="label label-default">{getLocale('Saving...')})</span>}
                    {saveFollowUpSuccess && <span className="label label-success">{getLocale('Saved')}</span>}
                    {saveFollowUpError && <span className="label label-danger">{getLocale('Error')}</span>}
                    {selectedClientDivision && selectedClientDivision.id && (
                        <button
                            type="button"
                            className="btn btn-sm btn-default"
                            onClick={deleteDivisionStatus}
                        >
                            {getLocale('Delete')}
                        </button>
                    )}
                    <SubmitButton
                        disabled={status === undefined || status === ''}
                        size="sm"
                        value={getLocale('Save')}
                    />
                </div>
            </form>
        </div>
    )
}

export default NotesStatusSection
