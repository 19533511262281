import React from 'react'
import { formatPhoneNumber } from '../../../../services/formatters'
import { DATABASE_TYPES } from '../../../../constants'
import TwoColumnTabPanel from './two-column-tab-panel'

const ClientContactTab = ({
    client
}) => {
    let workPhoneNumber = formatPhoneNumber(client.businessPhoneNumber)
    if (client.businessPhoneExtension && client.businessPhoneExtension !== 0) {
        workPhoneNumber += ` ext ${client.businessPhoneExtension}`
    }
    const contactItems = [{
        shouldDisplay: client.type === DATABASE_TYPES.B2C,
        label: 'client.companyName',
        value: client.companyName
    }, {
        shouldDisplay: true,
        label: 'client.employmentType',
        value: client.employementType
    }, {
        shouldDisplay: true,
        label: 'client.workPhoneNumber',
        value: workPhoneNumber
    }, {
        shouldDisplay: true,
        label: 'client.cellNumber',
        value: formatPhoneNumber(client.cellPhoneNumber)
    }, {
        shouldDisplay: true,
        label: 'client.homeNumber',
        value: formatPhoneNumber(client.homePhoneNumber)
    }, {
        shouldDisplay: true,
        label: 'client.faxNumber',
        value: formatPhoneNumber(client.faxNumber)
    }, {
        shouldDisplay: true,
        label: 'client.preferredEmail',
        value: client.primaryEmail
    }, {
        shouldDisplay: true,
        label: 'client.secondaryEmail',
        value: client.secondaryEmail
    }, {
        shouldDisplay: true,
        label: 'client.preferredContactMethod',
        value: client.preferredContactMethod
    }, {
        shouldDisplay: true,
        label: 'client.clientBestTimeToCall',
        value: client.bestTimeToCall
    }, {
        shouldDisplay: true,
        label: 'client.address1',
        value: [
            client.unitNumber ? '#' + client.unitNumber : '',
            client.streetNumber,
            client.streetName,
            client.city,
            client.country,
            client.postalCode
        ].join(' ')
    }]

    return <TwoColumnTabPanel items={contactItems} />
}

export default ClientContactTab