import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { useRouteMatch, Switch, Redirect, useLocation, Link, useParams, useHistory } from 'react-router-dom'
import PrivateRoute from '../../components/private-route';
import useLocale from '../../hooks/use-locale';
import { useAsync } from '../../hooks';
import Loading from '../../components/loading';
import { Panel, SubmitButton, Button, Row, Col } from '../../components/bootstrap';
import Table from '../../components/table';
import Pagination from '../../components/pagination';
import { ROLES_ARRAY, STATUSES, USER_STATUSES } from '../../constants';
import { updateUserDetail, 
    resetPassword, 
    userDetail, 
    getAssignToList, 
    searchUsers, 
    createUser, 
    saveFollowupEmail,
    deleteUser as apiDeleteUser
 } from '../../services/api';
import { isAsyncStatusSuccess, isLoading } from '../../services/helpers';
import FormRenderer from '../../components/form-renderer';

import './admin.css'
import Modal from '../../components/modal';
import { TimerPrompt } from '../../components/common';

const UserSearchResults = ({
    users,
    totalPages,
    currentPage,
    onPageClick,
    asyncStatus
}) => {
    const { getLocale } = useLocale()
    const history = useHistory()
    const { url } = useRouteMatch()
    const tableHeadings = [{
        label: getLocale('Name')
    }, {
        label: getLocale('Email'),
        textAlign: 'right'
    }]
    const rows = users.map(({ id, firstName, lastName, email }) => {
        return {
            props: {
                onClick: () => history.push(`${url}/${id}/edit`)
            },
            columns: [{
                children: `${firstName} ${lastName}`,
            }, {
                children: email,
                className: 'text-right'
            }]
        }
    })
    return (
        <Panel
            header={(
                <Row>
                    <Col md={6}>
                        <strong>{getLocale('Search')}</strong>
                    </Col>
                    <Col md={6} textAlign="right">
                        <Link to="/dashboard/admin/users/create" className="btn btn-default btn-sm">
                            {getLocale('CreateUser')}
                        </Link>
                    </Col>
                </Row>
            )}
        >
            {
                [null, STATUSES.LOADING].includes(asyncStatus)
                    ? <Loading isLoading />
                    : <Fragment>
                        <Table headings={tableHeadings} rows={rows} />
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            pageFn={onPageClick}
                        />
                    </Fragment>

            }
        </Panel>
    )
}

const SEARCH_FIELDS = [{
    label: 'firstName',
    key: 'firstName',
    type: 'text',
    valueType: 'string'
}, {
    label: 'lastName',
    key: 'lastName',
    type: 'text',
    valueType: 'string'
}, {
    label: 'email',
    key: 'email',
    type: 'text',
    valueType: 'string'
}]

const UserSearch = () => {
    const { getLocale } = useLocale()
    const history = useHistory()
    const location = useLocation()
    const [params, setParams] = useState()

    useEffect(() => {
        const qs = new URLSearchParams(location.search)
        const params = SEARCH_FIELDS.reduce((acc, field) => {
            acc[field.key] = qs.get(field.key) || ''
            return acc
        }, {})
        setParams(params)

    }, [location, setParams])

    const onSubmit = (e) => {
        e.preventDefault()
        const qs = new URLSearchParams(location.search)
        qs.delete('page')
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                qs.set(key, value)
            } else {
                qs.delete(key)
            }
        })
        history.push({ search: qs.toString() })
    }

    if (!params) {
        return null
    }

    return (
        <Panel header={<strong>{getLocale('Search')}</strong>}>
            <form onSubmit={onSubmit}>
                <FormRenderer
                    fields={SEARCH_FIELDS}
                    values={params}
                    onFieldChange={({ field, value }) => {
                        setParams({
                            ...params,
                            [field.key]: value
                        })
                    }}
                />
                <div className="form-group text-right">
                    <button className="btn btn-primary">{getLocale('Search')}</button>
                </div>
            </form>
        </Panel>
    )
}

const AdminUserSearch = () => {
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const page = query.has('page') ? parseInt(query.get('page')) : 0

    const {
        execute,
        data,
        asyncStatus
    } = useAsync(searchUsers)

    useEffect(() => {
        const qs = new URLSearchParams(location.search)
        const entries = Array.from(qs.entries())
        const params = entries.reduce((acc, [key, value]) => {
            acc[key] = value
            return acc
        }, {})
        const init = () => {
            execute({ params })
        }
        init()
    }, [location.search, execute])

    return (
        <div className="row">
            <div className="col-sm-12 col-md-3">
                <UserSearch />
            </div>
            <div className="col-sm-12 col-md-9">
                {
                    !data
                        ? <Loading isLoading />
                        : <UserSearchResults
                            asyncStatus={asyncStatus}
                            users={data.data}
                            currentPage={page}
                            totalPages={data.totalPages}
                        />
                }
            </div>
        </div>
    )
}

const USER_FIELDS = [{
    label: 'firstName',
    key: 'firstName',
    type: 'text',
    valueType: 'string',
}, {
    label: 'lastName',
    key: 'lastName',
    type: 'text',
    valueType: 'string',
}, {
    label: 'email',
    key: 'email',
    type: 'text',
    valueType: 'string',
}, {
    label: 'roles',
    key: 'roles',
    type: 'checkbox',
    valueType: 'number',
    options: ROLES_ARRAY.map(({ name, id }) => ({ label: name, value: id }))
}]

const AdminUserProfile = ({
    variant
}) => {
    const isEdit = useMemo(() => variant === 'edit', [variant])
    const { id } = useParams()
    const { getLocale } = useLocale()
    const {
        execute: executeFetchUser,
        data: user,
    } = useAsync(userDetail)
    const {
        execute: executeUpdate,
        asyncStatus: userUpdateAsyncStatus
    } = useAsync(isEdit ? updateUserDetail : createUser)
    const {
        execute: executePasswordUpdate,
        asyncStatus: resetPasswordAsyncStatus
    } = useAsync(resetPassword)
    const {
        execute: deleteUserExecute,
        asyncStatus: deleteUserAsyncStatus
    } = useAsync(apiDeleteUser)
    const [userFieldValues, setUserFieldValues] = useState()
    const [resetPasswordEmail, setResetPasswordEmail] = useState('')
    const history = useHistory()

    const resetPasswordFields = useMemo(() => {
        return [{
            label: 'ConfirmEmail',
            key: 'email',
            type: 'text',
            valueType: 'string'
        }]
    }, [])

    useEffect(() => {
        // do not fetch user in create mode
        if (!isEdit) {
            return
        }

        const init = () => {
            executeFetchUser(id)
        }
        init()
    }, [id, isEdit, executeFetchUser])


    useEffect(() => {
        if (!isEdit) {
            setUserFieldValues(USER_FIELDS.reduce((acc, field) => {
                if (field.type === 'checkbox') {
                    acc[field.key] = new Set()
                } else {
                    acc[field.key] = ''
                }
                return acc
            }, {}))
            return
        }

        if (!user) {
            return
        }

        setUserFieldValues({
            ...user,
            roles: new Set(user ? user.roles.map(r => r.roleID) : [])
        })
    }, [user, isEdit])

    useEffect(() => {
        if (isAsyncStatusSuccess(userUpdateAsyncStatus)) {
            history.push(`/dashboard/admin/users/${id}`)
        }
    }, [userUpdateAsyncStatus, history, id])

    useEffect(() => {
        if (isAsyncStatusSuccess(deleteUserAsyncStatus)) {
            history.push(`/dashboard/admin/users`)
        }
    }, [deleteUserAsyncStatus, history])

    const onUserValueFieldChange = ({ field, value }) => {
        setUserFieldValues({
            ...userFieldValues,
            [field.key]: value
        })
    }

    const onPasswordResetFieldChange = ({ field, value }) => {
        setResetPasswordEmail(value)
    }

    const onUserFormSubmit = (e) => {
        e.preventDefault()
        // do nothing if user does not confirm
        if (!window.confirm(getLocale('userUpdateSaveWarning'))) {
            return
        }

        const userValues = USER_FIELDS
            .reduce((acc, field) => {
                switch (field.type) {
                    case 'checkbox': {
                        acc[field.key] = Array.from(userFieldValues[field.key]).map(item => parseInt(item))
                        return acc
                    }
                    default: {
                        acc[field.key] = userFieldValues[field.key]
                        return acc
                    }
                }
            }, { ...user })
        executeUpdate(id, userValues)
    }

    const onPasswordResetSubmit = (e) => {
        e.preventDefault()
        executePasswordUpdate(resetPasswordEmail)
        setResetPasswordEmail('') // reset to default value
    }

    const deleteUser = () => {
        if (window.confirm(getLocale('deleteUserConfirmation'))) {
            deleteUserExecute(id)
        }
    }

    if (!userFieldValues) {
        return <Loading isLoading />
    }

    return (
        <div>
            <TimerPrompt message={getLocale('editPagePrompt')} />
            <div className="page-header">
                <h3>{getLocale(isEdit ? 'EditUser' : 'CreateUser')}</h3>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <Panel header={<strong>{getLocale('UserDetails')}</strong>}>
                        <form onSubmit={onUserFormSubmit}>
                            <FormRenderer
                                onFieldChange={onUserValueFieldChange}
                                fields={USER_FIELDS}
                                values={userFieldValues}
                            />
                            <SubmitButton value={getLocale('SaveUser')} />
                        </form>
                    </Panel>
                </div>

                {
                    isEdit && user && (
                        <div className="col-sm-12 col-md-6">
                            <Panel header={<strong>{getLocale('ResetPassword')}</strong>}>
                                {
                                    user.status === USER_STATUSES.pendingActivation.key && (
                                        <div className="form-group">
                                            <p>{getLocale('userPendingActivationMessage')}</p>
                                        </div>
                                    )
                                }
                                {
                                    user.status !== USER_STATUSES.pendingActivation.key && (
                                        <form onSubmit={onPasswordResetSubmit}>
                                            <FormRenderer
                                                onFieldChange={onPasswordResetFieldChange}
                                                fields={resetPasswordFields}
                                                values={{
                                                    email: resetPasswordEmail
                                                }}
                                            />
                                            {
                                                isLoading(resetPasswordAsyncStatus)
                                                    ? <Loading isLoading />
                                                    : <SubmitButton value={getLocale('ResetPassword')} />
                                            }
                                        </form>
                                    )
                                }
                            </Panel>
                            <Panel variant="danger" header={<strong>{getLocale('deleteUser')}</strong>}>
                                <button type="button" className="btn btn-danger" onClick={deleteUser}>
                                    {getLocale('deleteUser')}
                                </button>
                            </Panel>

                        </div>
                    )
                }
            </div>
        </div>
    )
}

const AdminFollowUpUserManagement = () => {
    const { getLocale } = useLocale()
    const { execute, data } = useAsync(getAssignToList)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const tableHeadings = useMemo(() => [
        { label: getLocale('Name') },
        { label: getLocale('Email') },
        { label: '' }
    ], [getLocale])

    const {
        execute: executeSaveFollowupEmail,
        isSucess: saveFollowupSuccess
    } = useAsync(saveFollowupEmail)

    useEffect(() => {
        const init = async () => { execute() }
        init()
    }, [execute])

    useEffect(() => {
       if (saveFollowupSuccess === true){
           execute()
       } 
    }, [saveFollowupSuccess, execute])
    
    const getTableRows = () => {
        return data.map((assignTo) => {
            const {
                name,
                email
            } = assignTo
            return {
                columns: [
                    { children: name },
                    { children: email },
                    {
                        children: (
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedRecord(assignTo)
                                }}
                                type="button"
                                className="btn btn-sm btn-link"
                            >
                                {getLocale('EditEmail')}
                            </button>
                        )
                    }]
            }
        })
    }

    return (
        <div>
            {
                !data
                    ? <Loading isLoading />
                    : <div>
                        <Table headings={tableHeadings} rows={getTableRows()} />
                        <Modal
                            title={getLocale('EditEmail')}
                            isOpen={selectedRecord !== null}
                            size="small"
                            onRequestClose={() => setSelectedRecord(null)}
                        >
                            {selectedRecord !== null && (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        executeSaveFollowupEmail(selectedRecord)
                                        setSelectedRecord(null)
                                    }}
                                >
                                    <FormRenderer
                                        fields={[{
                                            key: 'email',
                                            label: 'Email',
                                            value: selectedRecord.email,
                                            type: 'text'
                                        }]}
                                        onFieldChange={({ value, field }) => {
                                            setSelectedRecord({
                                                ...selectedRecord,
                                                [field.key]: value
                                            })
                                        }}
                                        values={selectedRecord}
                                    />
                                    <Button variant="danger" onClick={() => setSelectedRecord(null)}>
                                        {getLocale('Cancel')}
                                    </Button>
                                    <SubmitButton value={getLocale('Save')} />
                                </form>
                            )}
                        </Modal>
                    </div>
            }
        </div>
    )
}

const Admin = () => {
    const { getLocale } = useLocale()
    const { pathname } = useLocation()
    const { path } = useRouteMatch()
    const matchesUser = pathname.includes(`${path}/users`)
    const matchesFollowup = pathname.includes(`${path}/followup-users`)

    return (
        <div>
            <ul className="list-inline user-menu">
                <li>
                    {matchesUser && <strong>{getLocale('ManageUsers')}</strong>}
                    {!matchesUser && <Link to="/dashboard/admin/users">{getLocale('ManageUsers')}</Link>}
                </li>
                <li>
                    {matchesFollowup && <strong>{getLocale('ManageFollowUpUsers')}</strong>}
                    {!matchesFollowup && <Link to="/dashboard/admin/followup-users">{getLocale('ManageFollowUpUsers')}</Link>}
                </li>
            </ul>
            <div className="page-header">
                <h1>{getLocale('Administration')}</h1>
            </div>
            <Switch>
                <PrivateRoute path={`${path}/users/create`}>
                    <AdminUserProfile variant="create" />
                </PrivateRoute>
                <PrivateRoute path={`${path}/users/:id/:action`}>
                    <AdminUserProfile variant="edit" />
                </PrivateRoute>
                <PrivateRoute path={`${path}/users`}>
                    <AdminUserSearch />
                </PrivateRoute>
                <PrivateRoute path={`${path}/followup-users`}>
                    <AdminFollowUpUserManagement />
                </PrivateRoute>
                <PrivateRoute>
                    <Redirect to={`${path}/users`} />
                </PrivateRoute>
            </Switch>
        </div>

    )
}

export default Admin