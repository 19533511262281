const SET_SELECTED_CLIENT_ID = 'SET_SELECTED_CLIENT_ID'

// used for the partner toggle
export const setSelectedClientId = (clientId) => {
    return {
        type: SET_SELECTED_CLIENT_ID,
        data: {
            clientId
        }
    }
}

const initialState = {
    selectedClientId: null
}

const clientDetailPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_CLIENT_ID: {
            return {
                ...state,
                selectedClientId: action.data.clientId
            }
        }
        default: {
            return { ...state }
        }
    }
}

export const selectedClientIdSelector = state => state.clientDetailPage.selectedClientId

export default clientDetailPageReducer
