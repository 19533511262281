
import {
    DATABASE_TYPES_LIST,
    CALL_STATUSES,
    MARKETING_CONSENT_LIST,
    DATBASE_TYPE_REFERRED_BY_MARKETING_OUTLET,
    INDUSTRY_PROFESSION_LIST_MAP,
    REFERRED_BY_PERSON_LIST,
    SALUTATION_LIST,
    GENDER_LIST,
    SUFFIX_LIST,
    EMPLOYMENT_TYPE_LIST,
    PREFERRED_CONTACT_METHOD_LIST,
    TIMES_TO_CALL_LIST,
    COUNTRIES_LIST,
    PROVINCE_LIST,
    STATES_LIST,
    OCCUPANCY_STATUS_LIST,
    INDUSTRIES_LIST,
    ASSET_TYPES_LIST,
    LIABILITY_TYPES_LIST,
    PAYOFF_LIST,
    DATABASE_TYPES,
    MARITAL_STATUS_LIST,
} from '../../constants'
import { listWithBlank } from '../../services/helpers'

const isNotEmptyString = (value) => value !== ''
const isB2CDatabaseType = (value) => value.type === DATABASE_TYPES.B2C.toString()
const isNonB2CType = (values) => values.type !== DATABASE_TYPES.B2C.toString()
const parseNumber = (value) => {
    if (typeof value === 'number') {
        return value
    }
    const number = parseInt(value.replace(/[^0-9]/g, ''), 10)
    return isNaN(number) ? null : number
}
const parsePhoneNumber = (value) => {
    const cleanNumber = value.replace(/[^0-9]/g, '').slice(0, 10)
    const tel = [];

    if (!cleanNumber) return '';

    for (let i = 0; i < cleanNumber.length; i++) {
        if (i === 0) tel.push('(');
        if (i === 3) tel.push(') ');
        if (i === 6) tel.push(' - ');
        tel.push(cleanNumber[i]);
    }

    return tel.join('');
}

const parsePhoneNumberForPayload = (value) => {
    const phoneNumber = parseNumber(value);
    return phoneNumber === null ? 0 : phoneNumber;
}


const parsePhoneNumberFromPayload = (value) => {
    return value === 0 ? '' : parsePhoneNumber(value.toString())
}

const parseString = value => value.toString()
const parseDateFromPayload = value => value === '0001-01-01T00:00:00Z' ? undefined : new Date(value)

export const GENERAL_FORM_FIELDS = [{
    key: 'createdDate',
    label: 'EntryDate',
    type: 'date',
    required: true,
    shouldShow: true,
    disabled: true,
}, {
    key: 'type',
    label: 'DatabaseType',
    type: 'dropdown',
    required: true,
    shouldShow: true,
    options: listWithBlank(DATABASE_TYPES_LIST),
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
    isValid: isNotEmptyString
}, {
    key: 'industryProfession',
    label: 'IndustryProfession',
    type: 'dropdown',
    shouldShow: (values) => {
        return !!INDUSTRY_PROFESSION_LIST_MAP[values.type]
    },
    required: false,
    options: (values) => {
        const ipItems = INDUSTRY_PROFESSION_LIST_MAP[values.type]
        return ipItems || []
    },
    isValid: isNotEmptyString,
}, {
    key: 'industryProfessionOther',
    label: '',
    type: 'text',
    placeholder: 'PleaseSpecify',
    shouldShow: (values) => {
        return !!INDUSTRY_PROFESSION_LIST_MAP[values.type] && values.industryProfession === 'Other'
    },
    required: false,
}, {
    key: 'status',
    label: 'CallStatus',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    options: listWithBlank(CALL_STATUSES),
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
}, {
    key: 'marketinConsent',
    label: 'MarketingConsent',
    type: 'dropdown',
    shouldShow: true,
    required: true,
    options: listWithBlank(MARKETING_CONSENT_LIST),
    isValid: isNotEmptyString,
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
}, {
    key: 'referredByMarketingOutlet',
    label: 'ReferredByMarketingOutlet',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    options: (values) => {
        const referredByMarketingOutletItems = DATBASE_TYPE_REFERRED_BY_MARKETING_OUTLET[values.type]
        return referredByMarketingOutletItems ? listWithBlank(referredByMarketingOutletItems) : []
    },
    valueType: 'string'
}, {
    key: 'referredByMarketingOutletOther',
    label: '',
    type: 'text',
    shouldShow: (values) => values.referredByMarketingOutlet === 'Other',
    required: false,
    valueType: 'string',
    placeholder: 'PleaseSpecify'
}, {
    key: 'referredByPerson',
    label: 'PersonReferredBy',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    options: REFERRED_BY_PERSON_LIST,
    valueType: 'string'
}, {
    key: 'referredByPersonOther',
    placeholder: 'PleaseSpecify',
    label: '',
    type: 'text',
    shouldShow: values => values.referredByPerson === 'Other',
    required: false,
    valueType: 'string'
}, {
    key: 'comment',
    label: 'Comments',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}]

export const PERSONAL_INFO_FORM_FIELDS = [{
    key: 'salutation',
    label: 'Salutation',
    type: 'dropdown',
    required: false,
    shouldShow: true,
    valueType: 'string',
    options: SALUTATION_LIST
}, {
    key: 'sex',
    label: 'Gender',
    type: 'dropdown',
    required: true,
    shouldShow: true,
    options: GENDER_LIST,
    isValid: isNotEmptyString,
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
}, {
    key: 'firstName',
    label: 'FirstName',
    type: 'text',
    shouldShow: true,
    required: true,
    isValid: isNotEmptyString,
    valueType: 'string'
}, {
    key: 'middleInitital',
    label: 'Initial',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'lastName',
    label: 'LastName',
    type: 'text',
    shouldShow: true,
    required: true,
    isValid: isNotEmptyString,
    valueType: 'string'
}, {
    key: 'suffix',
    label: 'Suffix',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: SUFFIX_LIST
}, {
    key: 'alsoKnownAs',
    label: 'AlsoKnownAs',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'dob',
    label: 'Date of Birth',
    shouldShow: true,
    required: false,
    parseFromPayload: parseDateFromPayload
}, {
    key: 'maritalStatus',
    label: 'MaritalStatus',
    type: 'dropdown',
    shouldShow: isB2CDatabaseType,
    required: false,
    options: MARITAL_STATUS_LIST
}, {
    key: 'sin',
    label: 'SIN',
    shouldShow: isB2CDatabaseType,
    required: false,
    parse: (value) => {
        // do not parse if input is right format
        if (/\d{3}-\d{3}-\d{3}/g.test(value)) {
            return value
        }
        return value.replace(/[^0-9]/g, '').slice(0, 9)
    },
    format: (value) => {
        const formatted = []
        const clean = value.replace(/[^0-9]/g, '').slice(0, 9)
        for (let i = 0; i < clean.length; i++) {
            if ([3, 6].includes(i)) {
                formatted.push('-')
            }
            formatted.push(clean[i])
        }
        return formatted.join('')
    },
    parseForPayload: parseNumber
}, {
    key: 'driverLicenseNumber',
    type: 'text',
    label: 'Drivers License',
    shouldShow: isB2CDatabaseType,
    required: false
}, {
    key: 'otherIdentification',
    type: 'text',
    label: 'OtherIdentification',
    shouldShow: isB2CDatabaseType,
    required: false
}, {
    key: 'otherIdentificationOther',
    type: 'text',
    label: '',
    shouldShow: (values) => isB2CDatabaseType(values) && values.otherIdentification === 'Other',
    required: false,
    placeholder: 'PleaseSpecify'
}, {
    key: 'otherIdentificationNumber',
    type: 'text',
    label: 'OtherIdentificationNumber',
    shouldShow: isB2CDatabaseType,
    required: false
}, {
    key: 'numberOfDependants',
    type: 'text',
    label: 'NumberOfDependants',
    shouldShow: isB2CDatabaseType,
    required: false,
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
}, {
    key: 'childrenNames',
    type: 'text',
    label: 'ChildrenNames',
    shouldShow: isB2CDatabaseType,
    required: false
}, {
    key: 'spouseFirstName',
    type: 'text',
    label: 'SpouseFirstName',
    shouldShow: isB2CDatabaseType,
    required: false
}, {
    key: 'spouseLastName',
    type: 'text',
    label: 'SpouseLastName',
    shouldShow: isB2CDatabaseType,
    required: false
}]

export const CONTACT_INFORMATION_FIELDS = [{
    key: 'companyName',
    label: 'CompanyName',
    type: 'text',
    shouldShow: isNonB2CType,
    required: false,
    valueType: 'string'
}, {
    key: 'employmentType',
    label: 'EmploymentType',
    type: 'dropdown',
    shouldShow: isNonB2CType,
    required: false,
    valueType: 'string',
    options: EMPLOYMENT_TYPE_LIST
}, {
    key: 'employmentTypeOther',
    label: '',
    type: 'text',
    shouldShow: (values) => values.employmentType === 'Other' && isNonB2CType(values),
    required: false,
    valueType: 'string',
    placeholder: 'PleaseSpecify'
}, {
    key: 'businessPhoneNumber',
    label: 'BusinessNumber',
    type: 'text',
    shouldShow: true,
    required: false,
    parse: parsePhoneNumber,
    parseForPayload: parsePhoneNumberForPayload,
    parseFromPayload: parsePhoneNumberFromPayload,
    valueType: 'number'
}, {
    key: 'businessPhoneExtension',
    label: 'BusinessPhoneExtension',
    type: 'text',
    shouldShow: true,
    required: false,
    parseForPayload: parsePhoneNumberForPayload,
    parseFromPayload: value => value === 0 ? '' : value.toString(),
}, {
    key: 'homePhoneNumber',
    label: 'HomeNumber',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'number',
    parse: parsePhoneNumber,
    parseForPayload: parsePhoneNumberForPayload,
    parseFromPayload: parsePhoneNumberFromPayload,
}, {
    key: 'cellPhoneNumber',
    label: 'CellNumber',
    type: 'text',
    shouldShow: true,
    required: false,
    parse: parsePhoneNumber,
    parseForPayload: parsePhoneNumberForPayload,
    parseFromPayload: parsePhoneNumberFromPayload,
    valueType: 'number'
}, {
    key: 'faxNumber',
    label: 'FaxNumber',
    type: 'text',
    shouldShow: true,
    required: false,
    parse: parsePhoneNumber,
    parseForPayload: parsePhoneNumberForPayload,
    parseFromPayload: parsePhoneNumberFromPayload,
    valueType: 'number'
}, {
    key: 'primaryEmail',
    label: 'PrimaryEmail',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'secondaryEmail',
    label: 'SecondaryEmail',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'preferredContactMethod',
    label: 'PreferredContactMethod',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    options: PREFERRED_CONTACT_METHOD_LIST,
    valueType: 'string'
}, {
    key: 'preferredContactMethodOther',
    label: '',
    type: 'text',
    shouldShow: (values) => values.preferredContactMethod === 'Other',
    required: false,
    valueType: 'string',
    placeholder: 'PleaseSpecify'
}, {
    key: 'bestTimeToCall',
    label: 'BestTimeToCall',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    options: TIMES_TO_CALL_LIST,
    valueType: 'string'
}, {
    key: 'bestTimeToCallOther',
    label: '',
    type: 'text',
    shouldShow: (values) => values.bestTimeToCall === 'Other',
    required: false,
    valueType: 'string',
    placeholder: 'PleaseSpecify'
}]

export const MAILING_INFORMATION_FIELDS = [{
    key: 'streetNumber',
    label: 'StreetNumber',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'streetName',
    label: 'StreetName',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'unitNumber',
    label: 'Unit',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'city',
    label: 'City',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'country',
    label: 'Country',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: COUNTRIES_LIST
}, {
    key: 'countryOther',
    label: '',
    type: 'text',
    shouldShow: (values) => values.country === 'Other',
    required: false,
    valueType: 'string',
    placeholder: 'PleaseSpecify'
}, {
    key: 'province',
    label: 'ProvinceOrState',
    type: 'dropdown',
    shouldShow: (values) => values.country !== 'Other',
    required: false,
    valueType: 'string',
    options: (values) => {
        switch (values.country) {
            case 'Canada': {
                return PROVINCE_LIST
            }
            case 'United States': {
                return STATES_LIST
            }
            default: {
                return []
            }
        }
    }
}, {
    key: 'postalCode',
    label: 'PostalOrZipCode',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string',
}, {
    key: 'occupancy',
    label: 'OccupancyStatus',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: OCCUPANCY_STATUS_LIST
}, {
    key: 'occupancyOther',
    label: '',
    type: 'text',
    shouldShow: (values) => values.occupancy === 'Other',
    required: false,
    valueType: 'string',
    placeholder: 'PleaseSpecify'
}]

export const EMPLOYMENT_INFO_FIELDS = [{
    key: 'employerName',
    label: 'EmployerName',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'title',
    label: 'JobTitle',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'industry',
    label: 'IndustrySector',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: INDUSTRIES_LIST
}, {
    key: 'industryOther',
    label: '',
    type: 'text',
    shouldShow: values => values.industry === 'Other',
    required: false,
    valueType: 'string'
}, {
    key: 'employmentStartDate',
    label: 'WorkingHereSince',
    shouldShow: true,
    required: false,
    parseFromPayload: parseDateFromPayload
}, {
    key: 'employerAddressUnitNumber',
    label: 'Unit',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'employerAddressStreetNumber',
    label: 'StreetNumber',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'employerAddressStreetName',
    label: 'StreetName',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'employerAddressCity',
    label: 'City',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}, {
    key: 'employerAddressCountry',
    label: 'Country',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: COUNTRIES_LIST
}, {
    key: 'employerAddressCountryOther',
    label: '',
    type: 'text',
    shouldShow: values => values.employerAddressCountry === 'Other',
    required: false,
    valueType: 'string',
}, {
    key: 'employerAddressProvince',
    label: 'ProvinceOrState',
    type: 'dropdown',
    shouldShow: values => values.employerAddressCountry === 'Other',
    required: false,
    valueType: 'string',
    options: PROVINCE_LIST
}, {
    key: 'employerAddressPostalCode',
    label: 'PostalOrZipCode',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string'
}]

export const ASSETS = [{
    key: 'assets',
    valueType: 'array',
    shouldShow: isB2CDatabaseType,
    parseForPayload: value => {
        if (!Array.isArray(value)) {
            return []
        }

        return value.map((item) => {
            return {
                ...item,
                value: parseInt(item.value, 10)
            }
        })
    }
}]

export const LIABILITIES = [{
    key: 'liabilities',
    valueType: 'array',
    shouldShow: isB2CDatabaseType,
    parseForPayload: value => {
        if (!Array.isArray(value)) {
            return []
        }

        return value.map((item) => {
            return {
                ...item,
                liabilityLimit: parseInt(item.liabilityLimit, 10),
                balance: parseInt(item.liabilityLimit, 10)
            }
        })
    }
}]

export const ASSETS_FORM_FIELDS = [{
    key: 'type',
    label: 'Type',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: ASSET_TYPES_LIST
}, {
    key: 'description',
    label: 'Description',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string',
}, {
    key: 'value',
    label: 'Value',
    type: 'text',
    shouldShow: true,
    required: false,
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
}]

export const LIABILITIES_FORM_FIELDS = [{
    key: 'type',
    label: 'Type',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: LIABILITY_TYPES_LIST
}, {
    key: 'description',
    label: 'Description',
    type: 'text',
    shouldShow: true,
    required: false,
    valueType: 'string',
}, {
    key: 'liabilityLimit',
    label: 'Limit',
    type: 'text',
    shouldShow: true,
    required: false,
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
    valueType: 'number',
}, {
    key: 'balance',
    label: 'Balance',
    type: 'text',
    shouldShow: true,
    required: false,
    parseForPayload: parseNumber,
    parseFromPayload: parseString,
    valueType: 'number',
}, {
    key: 'maturityDate',
    label: 'MaturityDate',
    type: 'date',
    shouldShow: true,
    required: false,
}, {
    key: 'payOff',
    label: 'PayOff',
    type: 'dropdown',
    shouldShow: true,
    required: false,
    valueType: 'string',
    options: PAYOFF_LIST
}]