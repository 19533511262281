import React, { useRef, useEffect, useState, useCallback } from 'react'
import './file-drop.css'

const FileDrop = ({ children, onDrop }) => {
    const containerRef = useRef(null)
    const [showDropContainer, setShowDropContainer] = useState(false)

    const dropCallback = useCallback(onDrop, [onDrop])

    useEffect(() => {
        const containerEl = containerRef.current

        const isInElement = (mouseX, mouseY) => {
            var rect = containerEl.getBoundingClientRect()
            return mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom;
        }

        const handleDragOver = (e) => {
            e.preventDefault()
            if (showDropContainer) return
            setShowDropContainer(isInElement(e.clientX, e.clientY))
        }

        const handleDrop = (e) => {
            e.preventDefault();
            setShowDropContainer(false)
            if (isInElement(e.clientX, e.clientY)) {
                dropCallback(e.dataTransfer.files)
            }
            return false;
        }

        document.body.addEventListener('dragover', handleDragOver)
        document.body.addEventListener('drop', handleDrop)

        return () => {
            document.body.removeEventListener('dragover', handleDragOver)
            document.body.removeEventListener('drop', handleDrop)
        }
    }, [showDropContainer, dropCallback])

    return (
        <div ref={containerRef}>
            {showDropContainer && <div className="drop-container"></div>}
            {children}
        </div>
    )
}

export default FileDrop
