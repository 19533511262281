import React, { useContext } from 'react'
import { LocaleContext } from '../../locales'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import { removeAuthToken } from '../../services/local-storage'
import { useProfile } from '../../hooks'
import { PROFILE_ROLES } from '../../constants'

// nav item component needed to use `useRotueMatch`
const NavItem = ({ link, label }) => {
    return <li className={useRouteMatch(link) ? 'active' : ''}>
        <Link to={link}>
            {label}
        </Link>
    </li>
}

const Nav = () => {
    const { getLocale } = useContext(LocaleContext)
    const { url } = useRouteMatch()
    const { replace } = useHistory()
    const {profile} = useProfile()

    // navigation items
    const routes = [{
        link: `${url}/search`,
        label: getLocale('nav.searchDatabase'),
        hasAccess: true
    }, {
        link: `${url}/admin`,
        label: getLocale('nav.admin'),
        hasAccess: profile.role === PROFILE_ROLES.ADMIN
    }, {
        link: `${url}/reports`,
        label: getLocale('nav.reports'),
        hasAccess: true
    }]

    // logout function
    const logout = (e) => {
        e.preventDefault()

        // todo: check if client is on the notes page and show a warning if it is

        // confirm logout
        if (!window.confirm(getLocale('nav.logoutConfirmation'))) {
            // do nothing if user does not confirm
            return
        }

        // otherwise remove auth token from local storage
        removeAuthToken()

        // redirect to login
        replace('/login')
    }

    return (
        <nav className="navbar navbar-inverse navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <Link className="navbar-brand" to="/dashboard/search">
                        {getLocale('general.companyName')}
                    </Link>
                </div>
                <ul className="nav navbar-nav">
                    {
                        routes.map(({ link, label, hasAccess }) => {
                            if (!hasAccess) {
                                return null
                            }
                            return <NavItem key={label} link={link} label={label} />
                        })
                    }
                </ul>
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <a href="" onClick={logout} data-test-id="logout">
                            {getLocale('nav.logout')}
                        </a>
                    </li>
                </ul>
            </div>
        </nav >

    )
}

export default Nav